import { useContext } from 'react';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Layout from '_components/ui/Layout';
import Presets from '_components/ui/Presets';
import ForgotPassword from '_components/user/ForgotPassword';
import Login from '_components/user/Login';
import InviteCode from '_components/user/Register/InviteCode';
import InviteCodeApplied from '_components/user/Register/InviteCode/InviteCodeApplied';
import InviteCodeSkip from '_components/user/Register/InviteCode/InviteCodeSkip';
import BasicInformation from '_components/user/Register/Steps/BasicInformation';
import ChooseSubscription from '_components/user/Register/Steps/ChooseSubscription';
import EnterPayment from '_components/user/Register/Steps/EnterPayment';
import SuccessScreen from '_components/user/Register/Steps/SuccessScreen';
import Welcome from '_components/user/Register/Steps/Welcome';
import ResetPassword from '_components/user/ResetPassword';
import PropTypes from 'prop-types';
import { ProfileContext } from 'store/ProfileContext';

import Final from '../clients/ProfileFeature/Final';
import LifeStyles from '../clients/ProfileFeature/LifeStyles';
import Prioritise from '../clients/ProfileFeature/Prioritise';
import ProfileCauses from '../clients/ProfileFeature/ProfileCauses';
import States from '../clients/ProfileFeature/States';
import ClientAddInvestingProfileStart from '../clients/investing-profile/Start.tsx';
import ClientAddInvestingProfileStep1 from '../clients/investing-profile/Step1.tsx';
import ClientAddInvestingProfileStep2 from '../clients/investing-profile/Step2.tsx';
import ClientAddInvestingProfileStep3 from '../clients/investing-profile/Step3.tsx';
import ClientAddInvestingProfileStep4 from '../clients/investing-profile/Step4.tsx';
import ClientAddInvestingProfileStep5 from '../clients/investing-profile/Step5.tsx';
import ClientAddInvestingProfileStep6 from '../clients/investing-profile/Step6.tsx';
import ClientAddInvestingProfileStep7 from '../clients/investing-profile/Step7.tsx';
import ClientWelcomeInvestingProfileStart from '../clients/investing-profile/Welcome.jsx';
import { LoadingPage } from '../commons';
import RegisterPage from '../user/Register/RegisterPage';

import Routes from './Routes';
import Authorize from '_components/user/Authorize';
import WelcomeCompliiScreen from '_components/user/Register/Steps/Welcome/CompliiView';

function getProps(routerProps, component, sidebarSelect = null, overrides = {}) {
  return {
    router: routerProps,
    header: Presets.main,
    headerSelect: 'clients',
    sidebar: Presets.profile(routerProps),
    component,
    ...overrides,
  };
}

const Main = (props, { match: path }) => {
  const profileContext = useContext(ProfileContext);

  if (profileContext.isLoading) {
    return <LoadingPage />;
  }
  if (!profileContext.isLoggedIn || !profileContext.hasSubscription) {
    return (
      <Router>
        <Switch>
          <Route
            exact
            path="/clients/:id/investing-profile/start"
            render={(props) => {
              return (
                <Layout
                  {...getProps(props, ClientWelcomeInvestingProfileStart, null, {
                    sidebar: null,
                    onLoad: null,
                  })}
                />
              );
            }}
          />
          <Route
            path="/clients/:id/investing-profile/step1"
            render={(props) => (
              <Layout
                {...getProps(props, ClientAddInvestingProfileStep1, null, {
                  sidebar: null,
                  onLoad: null,
                })}
              />
            )}
          />
          <Route
            path="/clients/:id/investing-profile/step2"
            render={(props) => (
              <Layout
                {...getProps(props, ClientAddInvestingProfileStep2, null, {
                  sidebar: null,
                  onLoad: null,
                })}
              />
            )}
          />
          <Route
            path="/clients/:id/investing-profile/step3"
            render={(props) => (
              <Layout
                {...getProps(props, ClientAddInvestingProfileStep3, null, {
                  sidebar: null,
                  onLoad: null,
                })}
              />
            )}
          />
          <Route
            path="/clients/:id/investing-profile/step4"
            render={(props) => (
              <Layout
                {...getProps(props, ClientAddInvestingProfileStep4, null, {
                  sidebar: null,
                  onLoad: null,
                })}
              />
            )}
          />
          <Route
            path="/clients/:id/investing-profile/step5"
            render={(props) => (
              <Layout
                {...getProps(props, ClientAddInvestingProfileStep5, null, {
                  sidebar: null,
                  onLoad: null,
                })}
              />
            )}
          />
          <Route
            path="/clients/:id/investing-profile/step6"
            render={(props) => (
              <Layout
                {...getProps(props, ClientAddInvestingProfileStep6, null, {
                  sidebar: null,
                  onLoad: null,
                })}
              />
            )}
          />
          <Route
            path="/clients/:id/investing-profile/step7"
            render={(props) => (
              <Layout
                {...getProps(props, ClientAddInvestingProfileStep7, null, {
                  sidebar: null,
                  onLoad: null,
                })}
              />
            )}
          />
          <Route
            path="/forgotpassword"
            render={() => <Layout component={ForgotPassword} header={Presets.signUp} />}
          />
          <Route
            path="/resetPassword"
            render={() => <Layout component={ResetPassword} header={Presets.signUp} />}
          />
          {/* <Route
            path="/register/inviteCodeApplied"
            render={() => <Layout component={InviteCodeApplied} header={Presets.signUp} />}
          />
          <Route
            path="/register/inviteCodeSkip"
            render={() => <Layout component={InviteCodeSkip} header={Presets.signUp} />}
          />
          <Route
            path="/register/inviteCode"
            render={() => <Layout component={InviteCode} header={Presets.signUp} />}
          /> */}
          <Route
            path="/account/chooseSubscription"
            render={() => <Layout component={ChooseSubscription} header={Presets.signUp} />}
          />
          <Route
            path="/account/payment"
            render={() => <Layout component={EnterPayment} header={Presets.signUp} />}
          />
          <Route
            path="/register/basicInformation"
            component={BasicInformation}
            header={Presets.signUp}
          />

          <Route path="/register" component={RegisterPage} />
          <Route
            path="/welcome"
            render={() => <Layout component={SuccessScreen} header={Presets.signUp} />}
          />
          {/* Version 2 Question Routing */}
          <Route
            exact
            path="/clients/:id/investing-profile/welcome"
            render={(props) => {
              return (
                <Layout
                  {...getProps(props, ClientAddInvestingProfileStart, null, {
                    sidebar: null,
                    onLoad: null,
                  })}
                />
              );
            }}
          />
          <Route
            path={`/clients/:id/investing-profile/lifestyles`}
            render={(props) => (
              <Layout
                {...getProps(props, LifeStyles, null, {
                  sidebar: null,
                  onLoad: null,
                })}
              />
            )}
          />
          <Route
            path={`/clients/:id/investing-profile/causes`}
            render={(props) => (
              <Layout
                {...getProps(props, ProfileCauses, null, {
                  sidebar: null,
                  onLoad: null,
                })}
              />
            )}
          />
          <Route
            path={`/clients/:id/investing-profile/prioritise`}
            render={(props) => (
              <Layout
                {...getProps(props, Prioritise, null, {
                  sidebar: null,
                  onLoad: null,
                })}
              />
            )}
          />
          <Route
            path={`/clients/:id/investing-profile/states`}
            render={(props) => (
              <Layout
                {...getProps(props, States, null, {
                  sidebar: null,
                  onLoad: null,
                })}
              />
            )}
          />
          <Route
            path={`/clients/:id/investing-profile/final`}
            render={(props) => (
              <Layout
                {...getProps(props, Final, null, {
                  sidebar: null,
                  onLoad: null,
                })}
              />
            )}
          />
          <Route path={`/authorize`} component={Authorize} />
          {/* <Route
            path="/complii"
            component={WelcomeCompliiScreen}
          /> */}
          <Route path="*" component={Login} />
        </Switch>
      </Router>
    );
  }

  // Welcome Screen
  if (profileContext.showWelcomeScreen) {
    return (
      <Router>
        <Switch>
          <Route
            path="/welcome"
            render={() => <Layout component={SuccessScreen} header={Presets.signUp} />}
          />
          <Route
            path="/"
            render={() => <Layout component={SuccessScreen} header={Presets.signUp} />}
          />

        </Switch>
      </Router>
    );
  }

  return <Routes />;
  // if (profile && !isNil(profile.coreId)) {
  //   // if (!isAccountVerified) {
  //   //   return <RouterWithoutVerification />;
  //   // }
  //   if (!profile.isSubscribedAdviser) {
  //     return <RouterWithoutSubscription />;
  //   }
  // }
  // return <RouterWithoutSession />;
};

Main.propTypes = {
  profile: PropTypes.shape({}),
};

Main.defaultProps = {
  profile: null,
  history: {},
};

export default Main;
