import React from 'react';

import { Container, Row, Col, Button } from 'reactstrap';

const DeleteClientModal = ({ onDismiss, onSubmit, params }) => (
  <Container>
    <Row className="mb-20">
      <strong>
        <h5></h5>
      </strong>
    </Row>
    <Row className="mb-30">Are you sure you want to delete this client?</Row>
    <Row className="mb-30">
      <Col sm={6}>
        <Button className="btn-secondary" style={{ fontSize: '16px' }} onClick={() => onDismiss()}>
          Cancel
        </Button>
      </Col>
      <Col sm={6}>
        <Button
          className="btn-main-shadowless btn-danger"
          style={{ fontSize: '16px' }}
          onClick={() => onSubmit()}
        >
          Yes
        </Button>
      </Col>
    </Row>
  </Container>
);

export default DeleteClientModal;
