import React from 'react';

import './StepsBar.scss';

const StepsBar = ({ step, location, ...props }) => (
  <div className={'step-progress ' + step + ' ' + location} style={{ maxWidth: '340px' }}>
    <div className="progress-track">
      <div className="complete-bar"></div>
    </div>
    <div className="content-steps">
      <div id="step1" className="progress-step">
        <div className="dot">
          <div className="inner-dot"></div>
          <svg viewBox="0 0 342.357 342.357">
            <g>
              <polygon
                points="290.04,33.286 118.861,204.427 52.32,137.907 0,190.226 118.862,309.071 342.357,85.606 "
                data-original="#000000"
                className="active-path"
                fill="#000000"
              />
            </g>{' '}
          </svg>
        </div>
        <span className="step-title client">Support</span>
        <span className="step-title register">You</span>
      </div>
      <div id="step2" className="progress-step">
        <div className="dot">
          <div className="inner-dot"></div>
          <svg viewBox="0 0 342.357 342.357">
            <g>
              <polygon
                points="290.04,33.286 118.861,204.427 52.32,137.907 0,190.226 118.862,309.071 342.357,85.606 "
                data-original="#000000"
                className="active-path"
                fill="#000000"
              />
            </g>{' '}
          </svg>
        </div>
        <span className="step-title client">Avoid</span>
        <span className="step-title register">Company</span>
      </div>
      <div id="step3" className="progress-step">
        <div className="dot">
          <div className="inner-dot"></div>
          <svg viewBox="0 0 342.357 342.357">
            <g>
              <polygon
                points="290.04,33.286 118.861,204.427 52.32,137.907 0,190.226 118.862,309.071 342.357,85.606 "
                data-original="#000000"
                className="active-path"
                fill="#000000"
              />
            </g>{' '}
          </svg>
        </div>
        <span className="step-title client">Assess</span>
        <span className="step-title register">Clients</span>
      </div>
    </div>
  </div>
);

export default StepsBar;
