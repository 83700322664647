import api, { apiSetup } from '../config/api';

import { getSessionToken } from './LocalStorageService';

export function postXplanCode(code) {
  const requestBody = {
    code: code,
  };
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('v2/integrations/xplan', requestBody)
      .then((res) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'network error' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

// Setting API
export function getConnectedStatus() {
  const getConnectedStatusRequest = (resolve, reject) => {
    apiSetup();
    api
      .get('/v2/settings')
      .then((res) => {
        if (res.ok && res.data) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(getConnectedStatusRequest);
}

export function postCustomerUrl(requestBody) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('v2/settings', requestBody)
      .then((res) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'network error' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function deleteConnection() {
  const deleteConnectionRequest = (resolve, reject) => {
    apiSetup();
    api
      .delete('/v2/integrations/xplan')
      .then((res) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(deleteConnectionRequest);
}
