import React, { PureComponent } from 'react';

import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, Form, FormGroup, Row } from 'reactstrap';

import arrowLogo from '../../../_assets/img/icons/nav/Line 2@2x.png';
import iressLogo from '../../../_assets/img/icons/nav/iress-xplan-logo.png';
import { addClients } from '../../../service/ClientsService';
import { ButtonLoading, TextField, EmailField } from '../../commons';

import './add.scss';

class ClientAdd extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      nextRedirect: false,
      closeToolTip: false,
      client: {
        clientFirstName: '',
        clientLastName: '',
        clientEmailAddress: '',
      },
      loading: false,
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState(() => ({ loading: true }));
    addClients(this.state.client)
      .then((response) => {
        this.setState({ nextRedirect: response.adviserClient.clientCoreId });
      })
      .catch((err) => {
        this.setState({ showMessage: true, message: err.message });
        toast.error(err.message);
      })
      .finally(() => this.setState(() => ({ loading: false })));
  }

  handleChange({ target: { id, value } }) {
    this.setState((state) => ({ client: { ...state.client, [id]: value } }));
  }

  handleClick = () => {
    localStorage.setItem('closeToolTip', true);
    this.setState({ closeToolTip: true });
  };

  render() {
    const { loading, nextRedirect } = this.state;
    if (nextRedirect) {
      return <Redirect push to={{ pathname: `/clients` }} />;
    }
    const { client } = this.state;
    return (
      <div className="slim-content">
        <Container>
          {!localStorage.getItem('closeToolTip') &&
            localStorage.getItem('isXplanConnected') === false && (
              <>
                <div className="tooltip-container">
                  <button className="tooltip-button" onClick={this.handleClick}>
                    x
                  </button>
                  <p className="tooltip-text">Connect to import clients and portfolios</p>
                  <img src={iressLogo} width={70} />
                </div>
                <img src={arrowLogo} className="tooltip-arrow" width={70} />
              </>
            )}
          <div className="wizard-top-title mb-40">
            <h2 className="mt-40 mb-20">Add a new client</h2>
            <span className="mb-20">Enter client details to create an account</span>
          </div>
          <Form onSubmit={(e) => this.handleSubmit(e)}>
            <FormGroup>
              <TextField
                control="clientFirstName"
                onChange={(e) => this.handleChange(e)}
                label="Client first name"
                value={client.clientFirstName}
                required
              />
            </FormGroup>
            <FormGroup className="mb-30">
              <TextField
                control="clientLastName"
                onChange={(e) => this.handleChange(e)}
                label="Client last name"
                value={client.clientLastName}
                required
              />
            </FormGroup>
            <FormGroup className="mb-30">
              <EmailField
                control="clientEmailAddress"
                onChange={(e) => this.handleChange(e)}
                label="Client email"
                value={client.clientEmailAddress}
                required
              />
            </FormGroup>
            <div style={{ color: '#727E81', fontStyle: 'italic' }} className="mb-40">
              We won't ever contact your client, we use this only for security reasons.
            </div>
            <Row className="justify-content-center mt-40 mb-40">
              <ButtonLoading className="btn-main-shadowless" loading={loading} label="Add client" />
            </Row>
          </Form>
        </Container>
      </div>
    );
  }
}

export default ClientAdd;
