import { every } from 'lodash';

import ValidatorService from '../service/validator';

export default class User {
  _id = null;
  firstName = '';
  lastName = '';
  email = '';
  password = '';

  constructor(obj) {
    Object.assign(this, obj);
  }

  isValid() {
    const textFields = every(['firstName', 'lastName'], (field) =>
      ValidatorService.validateInputText(this[field]),
    );
    return textFields && ValidatorService.validateEmail(this.email);
  }
}
