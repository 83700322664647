import { isEmpty } from 'lodash';
import React, { useState } from 'react';

import DateUtilsService from '../../../../../service/DateUtils';

interface MatchingInstrument {
  name: string;
  preferences: [string];
}

interface MatchingInstrumentsProps {
  matchingInstruments: [MatchingInstrument];
  clientFirstName: string;
  portfolioName: string;
  portfolioUploadDate: string;
}

export default function MatchingInstrumentsV2(props: MatchingInstrumentsProps) {
  const { matchingInstruments, clientFirstName, portfolioName, portfolioUploadDate } = props;
  const [showFullList, setShowFullList] = useState(false);

  const getPreferences = (item: MatchingInstrument) => {
    const preferencesString = item.preferences.map((x: any) => x);
    return preferencesString.join(' and ');
  };

  const showFullOrCondensedList = () => {
    if (isEmpty(matchingInstruments)) {
      return null;
    }
    const liArray = [];

    if (matchingInstruments.length > 3 && !showFullList) {
      for (let i = 0; i < 3; i++) {
        liArray.push(
          <li key={i} className="margin-bottom-10">
            <b>{matchingInstruments[i].name}</b>
            <p className="font-14">{`because of ${getPreferences(matchingInstruments[i])}`}</p>
          </li>,
        );
      }
    } else {
      for (let i = 0; i < matchingInstruments.length; i++) {
        liArray.push(
          <li key={i} className="margin-bottom-10">
            <b>{matchingInstruments[i].name}</b>
            <p className="font-14">{`because of ${getPreferences(matchingInstruments[i])}`}</p>
          </li>,
        );
      }
    }
    return liArray;
  };

  const renderShowHideLink = () => {
    if (matchingInstruments.length > 3) {
      return (
        <span
          className="cursor-hand text-link-color"
          onClick={() => setShowFullList((prevState) => !prevState)}
        >
          {showFullList ? 'Condense List' : 'Show full list'}
        </span>
      );
    }
  };

  if (isEmpty(props.matchingInstruments)) {
    return (
      <>
        <p>
          {`Based on ${clientFirstName}'s sustainability profile, ${
            matchingInstruments.length
          } matches were identified in ${portfolioName}, uploaded on ${DateUtilsService.longFormatDate(
            portfolioUploadDate,
          )}`}
        </p>
        <div className="dasshed-box">
          <b
            className="mb-10"
            style={{ display: 'block' }}
          >{`In total ${matchingInstruments.length} matches were identified`}</b>
        </div>
        <br />
      </>
    );
  }
  return (
    <>
      <p>
        {`Based on ${clientFirstName}'s sustainability profile, ${
          matchingInstruments.length
        } matches were identified in ${portfolioName}, uploaded on ${DateUtilsService.longFormatDate(
          portfolioUploadDate,
        )}`}
      </p>
      <div className="dasshed-box">
        <ul>{showFullOrCondensedList()}</ul>
        {renderShowHideLink()}
      </div>
      <br />
    </>
  );
}
