import { isEmpty } from 'lodash';
import React, { PureComponent } from 'react';

import Moment from 'react-moment';
import { toast } from 'react-toastify';
import { Container, Row, Col, ListGroup, ListGroupItem, Button } from 'reactstrap';

import {
  duplicateAdviceOutput,
  getAllOutputs,
  getLatestOutput,
} from '../../../../service/AdvisorOutputService';
import { getClient } from '../../../../service/ClientsService';
import { getActiveProfileByClientId } from '../../../../service/ProfilingService';
import { SectionTitle, LoadingPage, StartQuestionnaireNow, ButtonLoading } from '../../../commons';

import './advice.scss';

export default class Outputs extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      id: props.router.match.params.id,
      path: `/clients/${props.router.match.params.id}/advice/outputs/add`,
      client: {},
      outputs: {},
      latestOutput: {},
      loadingPage: false,
      showStartQuestionnaire: false,
    };
  }

  async componentDidMount() {
    this.setState(() => ({ loadingPage: true }));

    const { id } = this.props.match.params;
    await getClient({ ClientCoreId: id }).then((res) =>
      this.setState(() => ({ client: res.adviserClient })),
    );

    await getActiveProfileByClientId(id)
      .then((response) => {
        // console.log(response);
        if (response.profileExists) {
          const params = { ClientCoreId: id };
          getAllOutputs(params)
            .then((response) => {
              // console.log(response)
              this.setState((prevState) => ({
                ...prevState,
                outputs: response.adviceOutputs,
                isV2ProfileLatest: response.isV2ProfileLatest,
              }));
              if (response.isV2ProfileLatest) {
                this.setState((prevState) => ({
                  ...prevState,
                  path: `/clients/${id}/advice/outputs/addV2`,
                }));
              }
            })
            .catch((err) => {
              toast.error(err.message);
            })
            .finally(() => this.setState(() => ({ loadingPage: false })));

          getLatestOutput(params)
            .then((latestOutput) => {
              this.setState(() => ({ latestOutput: latestOutput.adviceOutput }));
            })
            .catch((err) => {
              toast.error(err.message);
            });
        } else {
          this.setState(() => ({ showStartQuestionnaire: true, loadingPage: false }));
        }
      })
      .catch((err) => {
        toast.error(err.message);
        this.setState({ loadingPage: false });
      });
  }

  copyOutputAndNavigate(output) {
    if (output != null && output.adviceOutputId != null) {
      let params = {
        adviceOutputId: output.adviceOutputId,
      };
      duplicateAdviceOutput(params)
        .then((response) => {
          console.log(response);
          if (response.isSuccess) {
            let id = response.adviceOutputId;
            this.props.history.push(
              `/clients/${this.props.match.params.id}/advice/outputs/edit/${id}`,
            );
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  }

  render() {
    const { id, path, client, outputs, latestOutput, loadingPage, showStartQuestionnaire } =
      this.state;
    return (
      <div className="slim-content">
        <Container>
          {loadingPage && (
            <div>
              <LoadingPage />
              <br />
            </div>
          )}
          {!loadingPage && !showStartQuestionnaire && !isEmpty(outputs) && (
            <div style={{ marginTop: 45 }}>
              <SectionTitle title="Advice" subTitle="Output" results={this.state.outputs.length} />
              <Row className="mt-40">
                <Col>
                  <ListGroup className="client-list">
                    {outputs.map((output, idx) => (
                      <ListGroupItem key={idx} style={{ display: 'flex' }}>
                        <span style={{ flexGrow: 1 }}>
                          Advice Output created{' '}
                          <Moment format="DD/MM/YYYY" date={output.createdAt} />
                        </span>
                        {output.status === latestOutput.adviceOutputId && output.status === 3 && (
                          <button
                            onClick={() => {
                              this.copyOutputAndNavigate(output);
                            }}
                            style={{ margin: '0 7px 2.5px 7px' }}
                            className="link-button"
                          >
                            Copy
                          </button>
                        )}
                        {output.status === 2 ? (
                          <Button
                            color="link"
                            style={{ margin: '0 7px' }}
                            onClick={() =>
                              output.isV2Profile
                                ? this.props.history.push(
                                    `/clients/${this.props.match.params.id}/advice/outputs/editV2/${output.adviceOutputId}?v=all`,
                                  )
                                : this.props.history.push(
                                    `/clients/${this.props.match.params.id}/advice/outputs/edit/${output.adviceOutputId}?v=all`,
                                  )
                            }
                            className="start-link"
                          >
                            Edit
                          </Button>
                        ) : (
                          <Button
                            color="link"
                            style={{ margin: '0 7px' }}
                            onClick={() =>
                              output.isV2Profile
                                ? this.props.history.push(
                                    `/clients/${this.props.match.params.id}/advice/outputs/viewV2/${output.adviceOutputId}`,
                                  )
                                : this.props.history.push(
                                    `/clients/${this.props.match.params.id}/advice/outputs/view/${output.adviceOutputId}`,
                                  )
                            }
                            className="start-link"
                          >
                            View
                          </Button>
                        )}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Col>
              </Row>
              <Row className="mt-40 mb-40">
                <Col sm={12} className="text-center">
                  <ButtonLoading
                    className="btn-main-shadowless"
                    style={{ margin: 'auto' }}
                    label="Create a new output"
                    onClick={() => this.props.history.push(path)}
                  />
                </Col>
              </Row>
            </div>
          )}
          {!loadingPage && !showStartQuestionnaire && isEmpty(outputs) && (
            <div style={{ marginTop: 45 }}>
              <SectionTitle title="Advice Outputs" results={this.state.outputs.length} />
              <br />
              <br />
              <Row>
                <Col>
                  <span>
                    Create an advice output for {client.clientFirstName} based on the results from
                    the values profile questionnaire.
                  </span>
                </Col>
              </Row>
              <Row className="mt-40 mb-40">
                <Col sm={12} className="text-center">
                  <ButtonLoading
                    className="btn-main-shadowless"
                    style={{ margin: 'auto' }}
                    label="Create a new output"
                    onClick={() => this.props.history.push(path)}
                  />
                </Col>
              </Row>
            </div>
          )}
          {!loadingPage && showStartQuestionnaire && (
            <StartQuestionnaireNow
              clientId={id}
              startInvestingProfile={() =>
                this.props.history.push(
                  `/clients/${this.props.match.params.id}/investing-profile/start`,
                )
              }
            />
          )}
        </Container>
      </div>
    );
  }
}
