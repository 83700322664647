import React, { PureComponent } from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { formatText } from '../../../../../service/FormatTextModal';

export default class TopicModal extends PureComponent {
  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader className="d-block text-center">{this.props.topic.commentTitle}</ModalHeader>
        <ModalBody>
          <span>{formatText(this.props.topic.commentBody)}.</span>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
