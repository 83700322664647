import React from 'react';

import { Col, Row, CardBody, Container, Table } from 'reactstrap';

const exampleCard = () => (
  <Container className="example-panel">
    <Row>
      <p className="panel-title">CSV should include the following details:</p>
    </Row>
    <Table bordered responsive>
      <tbody>
        <tr className="text-left">
          <th>Client First Name</th>
          <th>Client Last Name</th>
          <th>Client Email</th>
        </tr>
        <tr className="text-left">
          <td>David</td>
          <td>Smith</td>
          <td>Dave@okoadviser.com</td>
        </tr>
        <tr className="text-left">
          <td>Jennifer</td>
          <td>Suggate</td>
          <td>Jenny@yahoo.com</td>
        </tr>
        <tr className="text-left">
          <td>Alice</td>
          <td>Doe</td>
          <td>Aliceder@gmail.com</td>
        </tr>
        <tr className="text-left">
          <td>Stephen</td>
          <td>Quilter</td>
          <td>Stephen_quilter@hotmail.com</td>
        </tr>
      </tbody>
    </Table>
  </Container>
);

export default exampleCard;
