const File = {
  GENERAL_ERROR: 'generalError',
  INVALID_FILE: 'invalidFile',
  FAILED_PARSE: 'failedParse',
  INVALID_COLUMN: 'invalidColumns',
  WRONG_FORMAT: 'wrongFormat',
  EMPTY_FILE: 'emptyFile',
  HIGH_FILE: 'highFile',
};

export default File;
