import { map, isEmpty, head, size, some, drop, forEach, values } from 'lodash';

import Papa from 'papaparse';

import { fileEnums } from '../_constants';

import ValidatorService from './validator';

export default class FileService {
  static async parseAplCsv(file, error) {
    return await new Promise((resolve, reject) => {
      Papa.parse(file, {
        skipEmptyLines: true,
        delimitersToGuess: [',', '\t', ';'],
        encoding: 'UTF-8',
        complete: (results) => {
          const errorParsingFile = !isEmpty(results.errors);
          if (errorParsingFile) {
            return reject(new Error(fileEnums.FAILED_PARSE));
          }
          const validColumnsCount =
            !isEmpty(head(results.data)) &&
            (head(results.data).length <= 8 || head(results.data).length >= 6);
          if (!validColumnsCount) {
            return reject(new Error(fileEnums.INVALID_COLUMN));
          }
          const widthRows = map(results.data, (datum) => datum.length);
          if (!isEmpty(widthRows) && some(widthRows, (widthRow) => widthRow < 2)) {
            return reject(new Error(fileEnums.INVALID_FILE));
          }
          const secondCSVLine = results.data && results.data[1];
          if (isEmpty(secondCSVLine)) {
            return reject(new Error(fileEnums.EMPTY_FILE));
          }
          // const sizeFile = size(results.data);
          // if (sizeFile > 50) {
          //   return reject(new Error(fileEnums.HIGH_FILE));
          // }
          const aplItems = map(drop(results.data), (item) => ({
            InstrumentName: item[0],
            Exchange: item[1],
            Ticker: item[2] || '',
            APIRCode: item[3] || '',
            ISIN: item[4] || '',
            CitiCode: item[5] || '',
          }));
          return resolve({ approvedProductListItems: aplItems, error: error || null });
        },
      });
    }).catch((error) => ({ error: error.message }));
  }

  static async parsePortfolioCsv(file, error) {
    return await new Promise((resolve, reject) => {
      Papa.parse(file, {
        skipEmptyLines: true,
        delimitersToGuess: [',', '\t', ';'],
        encoding: 'UTF-8',
        complete: (results) => {
          const errorParsingFile = !isEmpty(results.errors);
          if (errorParsingFile) {
            return reject(new Error(fileEnums.FAILED_PARSE));
          }
          const validColumnsCount =
            !isEmpty(head(results.data)) &&
            (head(results.data).length <= 8 || head(results.data).length >= 6);
          if (!validColumnsCount) {
            return reject(new Error(fileEnums.INVALID_COLUMN));
          }
          const widthRows = map(results.data, (datum) => datum.length);
          if (!isEmpty(widthRows) && some(widthRows, (widthRow) => widthRow < 2)) {
            return reject(new Error(fileEnums.INVALID_FILE));
          }
          const secondCSVLine = results.data && results.data[1];
          if (isEmpty(secondCSVLine)) {
            return reject(new Error(fileEnums.EMPTY_FILE));
          }
          // const sizeFile = size(results.data);
          // if (sizeFile > 50) {
          //   return reject(new Error(fileEnums.HIGH_FILE));
          // }
          const AdviserClientPortfolioItems = map(drop(results.data), (item) => ({
            InstrumentName: item[0],
            Exchange: item[1],
            Ticker: item[2] || '',
            APIRCode: item[3] || '',
            ISIN: item[4] || '',
            CitiCode: item[5] || '',
            Percentage: item[6] || '',
          }));
          return resolve({ AdviserClientPortfolioItems, error: error || null });
        },
      });
    }).catch((error) => ({ error: error.message }));
  }

  static async parseClientCsv(file) {
    return await new Promise((resolve, reject) => {
      Papa.parse(file, {
        skipEmptyLines: true,
        delimitersToGuess: [',', '\t', ';'],
        encoding: 'UTF-8',
        complete: (results) => {
          const errorParsingFile = !isEmpty(results.errors);
          if (errorParsingFile) {
            return reject(new Error(fileEnums.FAILED_PARSE));
          }
          const validColumnsCount = !isEmpty(head(results.data)) && head(results.data).length === 3;
          if (!validColumnsCount) {
            return reject(new Error(fileEnums.INVALID_COLUMN));
          }
          const widthRows = map(results.data, (datum) => datum.length);
          if (!isEmpty(widthRows) && some(widthRows, (widthRow) => widthRow !== 3)) {
            return reject(new Error(fileEnums.INVALID_FILE));
          }
          const secondCSVLine = results.data && results.data[1];
          if (isEmpty(secondCSVLine)) {
            return reject(new Error(fileEnums.EMPTY_FILE));
          }
          const sizeFile = size(results.data);
          if (sizeFile > 50) {
            return reject(new Error(fileEnums.HIGH_FILE));
          }
          const clients = map(drop(results.data), (item) => ({
            ClientFirstName: item[0],
            ClientLastName: item[1],
            ClientEmailAddress: item[2],
          }));
          return resolve(clients);
        },
      });
    }).catch((error) => ({ error: error.message }));
  }

  static async validateClientsUpload(clients) {
    const invalidClients = [];
    forEach(clients, (client, index) => {
      const errors = {
        ClientFirstName: !ValidatorService.validateInputTextAndRange(client.ClientFirstName),
        ClientLastName: !ValidatorService.validateInputTextAndRange(client.ClientLastName),
        ClientEmailAddress: !ValidatorService.validateEmail(client.ClientEmailAddress),
        rowNumber: index + 1,
      };
      const hasError = some(values(errors), (error) => error === true);
      if (hasError) {
        invalidClients.push(index + 1);
      }
    });
    return invalidClients;
  }
}
