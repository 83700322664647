import { IActionUpdateForOutcomeRequest } from '../interfaces/requests/IActionUpdateForOutcomeRequest';
// import { IClientRequest } from '../interfaces/requests/IClientRequest';
import { ICompleteAdviceOutputRequest } from '../interfaces/requests/ICompleteAdviceOutputRequest';
import { IOutputRequest } from '../interfaces/requests/IOutputRequest';
import { IDuplicateOutput } from '../interfaces/requests/insights/IDuplicateOutput';
import {
  IAdviceOutput,
  IAdviceOutputs,
} from '../interfaces/responses/adviserOutputs/IAdviceOutput';
import { IAdviceOutputAction } from '../interfaces/responses/adviserOutputs/IAdviceOutputAction';
import { IAdviceOutputConflict } from '../interfaces/responses/adviserOutputs/IAdviceOutputConflict';
import { IAdviceOutputSupported } from '../interfaces/responses/adviserOutputs/IAdviceOutputSupported';
import { ICompleteAdviceOutputDto } from '../interfaces/responses/adviserOutputs/ICompleteAdviceOutputDto';
import { IOutputDto } from '../interfaces/responses/adviserOutputs/IOutputDto';
import { IOutputMeetingNote } from '../interfaces/responses/adviserOutputs/IOutputMeetingNote';

import { ApiEndpoints } from './ApiEndpoints';
import { BaseService } from './BaseService';

export abstract class AdviserOutputService extends BaseService {
  static getInstance() {
    return this;
  }

  public static getAllOutputs(params: { ClientCoreId: string }) {
    const request = this.callPost<IAdviceOutputs>(ApiEndpoints.GetAllOutputs, params);

    return request;
  }

  public static getAdviceOutputV2(id: string) {
    const request = this.callGet<IOutputDto>(ApiEndpoints.AdviceOutput(id));
    return request;
  }

  public static getActionsByOutputId(params: IOutputRequest) {
    return this.callPost<IAdviceOutputAction[]>(ApiEndpoints.GetAdviserOutputActions, params);
  }

  public static getOutputById(params: IOutputRequest) {
    const response = this.callPost<IAdviceOutputs>(ApiEndpoints.GetOutputById, params);
    return response;
  }

  public static getMeetingNotes(params: IOutputRequest) {
    return this.callPost<IOutputMeetingNote>(ApiEndpoints.GetMeetingNotes, params);
  }

  public static getSupportedByOutputId(params: IOutputRequest) {
    return this.callPost<IAdviceOutputSupported[]>(ApiEndpoints.GetSupported, params);
  }

  public static getConflictsByOutputId(params: IOutputRequest) {
    return this.callPost<IAdviceOutputConflict[]>(ApiEndpoints.GetAdviceConflicts, params);
  }

  public static duplicateAdviceOutput(params: IOutputRequest) {
    return this.callPost<IDuplicateOutput>(ApiEndpoints.DuplicateAdviceOutput, params);
  }

  public static completeAdviceOutput(params: ICompleteAdviceOutputRequest) {
    return this.callPost<ICompleteAdviceOutputDto>(ApiEndpoints.CompleteAdviceOutput, params);
  }

  public static getLatestOutput(clientId: string) {
    return this.callGet<IAdviceOutput>(ApiEndpoints.GetLatestOutput(clientId));
  }

  public static getActions(params: IOutputRequest) {
    return this.callPost<IAdviceOutputAction[]>(ApiEndpoints.GetActions, params);
  }

  public static createOrUpdateActionForCompanyCard(params: {
    adviceOutputId: string;
    clientCoreId: string;
    adviceOutputActionId: string;
    InstrumentId: string;
    actionStatus: number;
  }) {
    const request = this.callPost<IAdviceOutputAction[]>(
      ApiEndpoints.CreateOrUpdateActionForCompanyCard,
      params,
    );
    return request;
  }

  public static markActionForOutcomes(params: IActionUpdateForOutcomeRequest) {
    return this.callPost<IAdviceOutputAction>(ApiEndpoints.CreateOrUpdateActionForOutcome, params);
  }
}
