import { isEmpty } from 'lodash';
import { useState } from 'react';

import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Row } from 'reactstrap';
import { Col } from 'reactstrap';
import { ListGroup } from 'reactstrap';
import { ListGroupItem } from 'reactstrap';
import { Button } from 'reactstrap';

import InfoModal from '_components/clients/ClientDetails/Profile/Values/InfoModal';
import TopicModal from '_components/clients/ClientDetails/Profile/Values/TopicModal';
import ButtonModal from '_components/commons/ButtonModal';
import LoadingPage from '_components/commons/LoadingPage';
import SectionTitle from '_components/commons/SectionTitle';
import StartQuestionnaireNow from '_components/commons/StartQuestionnaireNow';
import { IProfileQuestion } from 'interfaces/responses/profile/IProfileQuestion';
import { IProfileRunDetails } from 'interfaces/responses/profiling/IProfileDto';
import { Client } from 'service/ClientsService';

const Values = ({
  lifestyleMatches,
  lifestyleConflicts,
  lifestyleNone,
  matches,
  conflicts,
  none,
  avoidCountries,
  loading,
  profileId,
  profileDateCreated,
  isVersion1Profile,
  topics,
  clientAdviser,
}: {
  lifestyleMatches: IProfileQuestion[];
  lifestyleConflicts: IProfileQuestion[];
  lifestyleNone: IProfileQuestion[];
  matches: IProfileQuestion[];
  conflicts: IProfileQuestion[];
  none: IProfileQuestion[];
  avoidCountries: IProfileQuestion[];
  loading: boolean;
  profileId: string;
  profileDateCreated: string;
  isVersion1Profile: boolean;
  topics: IProfileRunDetails[];
  clientAdviser: Client;
}) => {
  console.log(profileId, 'profileId');
  const { id } = useParams();
  const history = useHistory();
  const [topic] = useState<IProfileRunDetails>();
  const [isTopicModalOpen, setIsTopicModalOpen] = useState(false);
  const getSupportedTopics = () => {
    return topics.filter((topic) => topic.questionType === 'Preferences');
  };
  const getOtherTopics = () => {
    return topics.filter((topic) => topic.questionType === 'Exclusions');
  };
  const goToQuestionnaire = () => {
    const actualPath = `/clients/${id}/advice/outcomes`;
    history.push(`/clients/${id}/investing-profile/start`, actualPath);
  };
  const toggleTopicModal = () => {
    setIsTopicModalOpen(!isTopicModalOpen);
  };
  if (isVersion1Profile) {
    console.log('isVersion1Profile');
    return (
      <div className="slim-content">
        <Container>
          {loading && <LoadingPage />}
          {!loading && !isEmpty(topics) && (
            <Container>
              <div style={{ marginTop: 45 }}>
                <SectionTitle title="Sustainability Profile" />
              </div>
              <Row>
                <br />
                <Col sm={12} style={{ marginTop: 40, marginBottom: 20, marginLeft: 0 }}>
                  <span style={{ fontSize: '20px', lineHeight: '26px' }}>
                    {clientAdviser && clientAdviser.clientFirstName} supports:
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row className="justify-content-center">
                    <ListGroup className="w-100">
                      {getSupportedTopics().map((topic, key) => (
                        <Row key={key}>
                          <Col sm={12}>
                            <ListGroupItem style={{ marginBottom: '10px', height: '100px' }}>
                              <div
                                className="list-item-layout w-100"
                                style={{ paddingBottom: '10px', paddingTop: '10px' }}
                              >
                                <div className="list-item-img d-flex justify-content-center align-items-center p-1">
                                  <img src={topic.iconUrl} alt="" width="70" />
                                </div>
                                <div className="list-item-description d-flex justify-content-start align-items-center flex-grow-1 p-1">
                                  <span>{topic.commentTitle}</span>
                                </div>
                                <div
                                  className="list-item-info d-flex justify-content-center align-items-start p-1"
                                  style={{ marginTop: '-45px', marginRight: '-15px' }}
                                >
                                  <ButtonModal
                                    component={InfoModal}
                                    className="link hide-button"
                                    params={
                                      {
                                        questionType: topic.questionType,
                                        iconUrl: topic.iconUrl,
                                        commentTitle: topic.commentTitle,
                                        commentBody: topic.commentBody,
                                      } as Record<string, unknown>
                                    }
                                  />
                                  <img
                                    src="https://cdn.okoadviser.com/portal/icons/global/i_icon@3x.png"
                                    alt="Info"
                                    width="22"
                                  />
                                </div>
                              </div>
                            </ListGroupItem>
                          </Col>
                        </Row>
                      ))}
                    </ListGroup>
                  </Row>
                </Col>
              </Row>
              <Row>
                <br />
                <Col sm={12} style={{ marginTop: 40, marginBottom: 20, marginLeft: 0 }}>
                  <span style={{ fontSize: '20px', lineHeight: '26px' }}>
                    {clientAdviser && clientAdviser.clientFirstName} doesn&apos;t support:
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row className="justify-content-center">
                    <ListGroup className="w-100">
                      {getOtherTopics().map((topic, key) => (
                        <Row key={key}>
                          <Col sm={12}>
                            <ListGroupItem style={{ marginBottom: '10px', height: '100px' }}>
                              <div
                                className="list-item-layout w-100"
                                style={{ paddingBottom: '10px', paddingTop: '10px' }}
                              >
                                <div className="list-item-img d-flex justify-content-center align-items-center p-1">
                                  <img src={topic.iconUrl} alt="" width="70" />
                                </div>
                                <div className="list-item-description d-flex justify-content-start align-items-center flex-grow-1 p-1">
                                  <span>{topic.commentTitle}</span>
                                </div>
                                <div
                                  className="list-item-info d-flex justify-content-center align-items-start p-1"
                                  style={{ marginTop: '-45px', marginRight: '-15px' }}
                                >
                                  <ButtonModal
                                    component={InfoModal}
                                    className="link hide-button"
                                    params={
                                      {
                                        questionType: topic.questionType,
                                        iconUrl: topic.iconUrl,
                                        commentTitle: topic.commentTitle,
                                        commentBody: topic.commentBody,
                                      } as Record<string, unknown>
                                    }
                                  />
                                  <img
                                    src="https://cdn.okoadviser.com/portal/icons/global/i_icon@3x.png"
                                    alt="Info"
                                    width="22"
                                  />
                                </div>
                              </div>
                            </ListGroupItem>
                          </Col>
                        </Row>
                      ))}
                    </ListGroup>
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginTop: 50 }}>
                <Col style={{ textAlign: 'center' }}>
                  <Button
                    onClick={() => goToQuestionnaire()}
                    className="browser-button small-btn"
                    style={{ width: '170px', height: '38px' }}
                  >
                    Redo values profile
                  </Button>
                </Col>
              </Row>
              <br />
              <br />
            </Container>
          )}
          {topic && (
            <TopicModal isOpen={isTopicModalOpen} toggle={toggleTopicModal} topic={topic} />
          )}
          {!loading && isEmpty(topics) && (
            <StartQuestionnaireNow
              startInvestingProfile={() => history.push(`/clients/${id}/investing-profile/start`)}
            />
          )}
        </Container>
      </div>
    );
  }
  return (
    <>
      {loading && <LoadingPage />}
      {!loading && (profileId !== '' || !profileId) && (
        <Container style={{ maxWidth: '1200px', padding: '0' }}>
          <div>
            <h3 className="value-title">Sustainability Profile</h3>
            <p className="value-subtitle">
              Here are the selections made by {clientAdviser && clientAdviser.clientFirstName}
              {''} when their profile was last completed on {profileDateCreated}. <br />
              - Habits and lifestyle are ordered by positive selection: ‘Thats me’, negative
              selection: ‘Thats not me’ and ‘Neutral’. <br />
              - Responsible Investing causes are shown in the order of priority as defined by the
              client when they completed this profile. <br />
              - Only those items selected are shown in this list.
              <br />- Click Redo Profile to update these selections.
            </p>
          </div>
          <Row className="mt-50 category-wrapper dasshed-box">
            <p className="category-title">Habits and Lifestyle</p>
            <Row>
              <Col>
                <p className="category-subtitle">That&apos;s me</p>
                {lifestyleMatches &&
                  lifestyleMatches.map((item, key) => (
                    <div style={{ backgroundColor: 'white' }} className="card-wrapper" key={key}>
                      <img src={item.imageFileName} width="60" />
                      <p className="card-text">{item.question}</p>
                    </div>
                  ))}
              </Col>
              <Col>
                <p
                  className={`${
                    lifestyleConflicts?.length === 0 ? 'disable' : 'category-subtitle'
                  }`}
                >
                  That&apos;s not me
                </p>
                {lifestyleConflicts &&
                  lifestyleConflicts.map((item, key) => (
                    <div style={{ backgroundColor: 'white' }} className="card-wrapper" key={key}>
                      <img src={item.imageFileName} width="60" />
                      <p className="card-text">{item.question}</p>
                    </div>
                  ))}
              </Col>
              <Col>
                <p className={`${lifestyleNone?.length === 0 ? 'disable' : 'category-subtitle'}`}>
                  Neutral
                </p>
                {lifestyleNone &&
                  lifestyleNone.map((item, key) => (
                    <div style={{ backgroundColor: 'white' }} className="card-wrapper" key={key}>
                      <img src={item.imageFileName} width="60" />
                      <p className="card-text">{item.question}</p>
                    </div>
                  ))}
              </Col>
            </Row>
          </Row>
          <Row className="mt-40 category-wrapper dasshed-box">
            <p className="category-title">Responsible Investing Causes</p>
            <Row>
              <Col>
                <p className="category-subtitle">Support</p>
                {matches &&
                  matches.map((item, key) => (
                    <div style={{ backgroundColor: 'white' }} className="card-wrapper" key={key}>
                      <img src={item.imageFileName} width="60" />
                      <p className="card-text">{item.question}</p>
                    </div>
                  ))}
              </Col>
              <Col>
                <p className="category-subtitle">Avoid</p>
                {conflicts &&
                  conflicts.map((item, key) => (
                    <div style={{ backgroundColor: 'white' }} className="card-wrapper" key={key}>
                      <img src={item.imageFileName} width="60" />
                      <p className="card-text">{item.question}</p>
                    </div>
                  ))}
              </Col>
              <Col>
                <p className={`${none?.length === 0 ? 'disable' : 'category-subtitle'}`}>Neutral</p>
                {none &&
                  none.map((item, key) => (
                    <div style={{ backgroundColor: 'white' }} className="card-wrapper" key={key}>
                      <img src={item.imageFileName} width="60" />
                      <p className="card-text">{item.question}</p>
                    </div>
                  ))}
              </Col>
            </Row>
          </Row>
          <Row className="mt-40 category-wrapper dasshed-box">
            <p className="category-title">Sovereign States</p>
            <Col xs={4}>
              {avoidCountries?.length ? (
                <p className="category-subtitle">Avoid</p>
              ) : (
                <p style={{ fontSize: '17px' }} className="category-subtitle">
                  No selections were made
                </p>
              )}
              {avoidCountries &&
                avoidCountries.map((item, key) => (
                  <div className="card-wrapper" key={key} style={{ padding: '15px' }}>
                    <img src={item.imageFileName} className="icon-style" width="50" />
                    <p className="card-state-text">{item.question}</p>
                  </div>
                ))}
            </Col>
          </Row>
        </Container>
      )}
      {!loading && !(profileId !== '' || !profileId) && (
        <StartQuestionnaireNow
          startInvestingProfile={() => history.push(`/clients/${id}/investing-profile/start`)}
        />
      )}
    </>
  );
};
export default Values;
