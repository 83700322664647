import React, { PureComponent, Fragment, useContext, useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import { Container, Row, Col, Button } from 'reactstrap';

import Auth from 'service/AuthService';
import { ProfileContext } from 'store/ProfileContext';

import termsDoc from '../../_assets/documents/Oko Adviser Terms of Service.pdf';
import DateUtilsService from '../../service/DateUtils';
import { getCurrentSubscription } from '../../service/PaymentsService';
import { cancelStripeSubcriptions } from '../../service/SubscriptionAccountService';
import { ButtonModal, LoadingPage, PageHeader } from '../commons';

import GetInTouch from './GetInTouch';
import SubscriptionModal from './SubscriptionModal';

const SubscriptionShow = (props) => {
  const profileCtx = useContext(ProfileContext);
  const [type, setType] = useState('Annual');
  const [expiry, setExpiry] = useState('12 May 2022');
  const [subscriptionCancelled, setSubscriptionCancelled] = useState(false);
  const [cancelSubscription, setCancelSubscription] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCurrentSubscription()
      .then((response) => {
        if (response.canceledAt) {
          setSubscriptionCancelled(true);
        }
        setCurrentSubscription(response);
      })
      .catch((err) => {
        // this.setState({ showMessage: true, message: err.message, loading: false });
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleCancelSubscription = () => {
    setLoading(true);
    cancelStripeSubcriptions()
      .then((response) => {
        setCancelSubscription(true);
        setCurrentSubscription(response);
      })
      .catch((err) => {
        // this.setState({ showMessage: true, message: err.message, loading: false });
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // handleSubscriptionCancelled() {
  //     this.setState(() => ({cancelSubscription: true, subscriptionCancelled: true}));
  // }

  const handleLogout = () => {
    profileCtx.logOut();
  };

  return (
    <div className="slim-content">
      <Container>
        <PageHeader
          title="Subscription details"
          rightButton={
            <Button color="link" onClick={() => handleLogout()}>
              Sign out
            </Button>
          }
        />
        {loading && <LoadingPage />}
        {!loading && (
          <Fragment>
            <Row className="mb-20">
              <Col sm={6}>Subscription type</Col>
              <Col sm={6}>{type}</Col>
            </Row>
            <Row className="mb-40">
              <Col sm={6}>Expiry date</Col>
              <Col sm={6}>
                {subscriptionCancelled ? (
                  DateUtilsService.timestampToDate(currentSubscription.currentPeriodEnd)
                ) : (
                  <p>Active Subscription</p>
                )}
              </Col>
            </Row>
            <Row className="mb-20">
              <Col sm={12}>
                <Button color="link" href={termsDoc} target="_blank">
                  Terms and conditions
                </Button>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col sm={12}>
                <GetInTouch message={`Are you an enterprise. `} />
              </Col>
            </Row>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Row className="mt-5">
              <Col sm={12} className="d-flex justify-content-center font-weight-bolder">
                {!subscriptionCancelled && <GetInTouch />}
              </Col>
            </Row>
          </Fragment>
        )}
      </Container>
    </div>
  );
};

export default SubscriptionShow;
