import React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import InfoModal from '_components/clients/investing-profile/InfoModal';
import { ButtonModal } from '_components/commons';
import PropTypes from 'prop-types';

import '../styles.scss';

export function Item(props) {
  const { item } = props;

  const style = {
    padding: '15px 27px 15px 15px',
    // paddingRight: '35px',
    // padding: '10px 35px 10px 5px',
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 12px 0',
    borderRadius: '10px',
    cursor: 'move',
    boxShadow: '0 0 5px rgba(0,0,0,0.1)',
    background: 'white',
    zIndex: '1000',
    position: 'relative',
  };

  const infoStyle = {
    position: 'absolute',
    top: '5px',
    right: '10px',
  };
  const buttonStyle = {
    display: 'block',
    width: '50px',
    height: '50px',
  };

  return (
    <div style={style}>
      <img src={item.iconUrl} width="70" />
      <p style={{ color: '#2e3074', fontSize: '15px', margin: '0 0 0 2px' }}>{item.questionDesc}</p>
      <div style={infoStyle}>
        <ButtonModal
          component={InfoModal}
          className="link hide-button"
          name=""
          params={{ item }}
          onSubmit={undefined}
          header={undefined}
          showImage={undefined}
          handleClick={undefined}
          style={buttonStyle}
        />
        <img
          src="https://cdn.okoadviser.com/portal/icons/global/i_icon@3x.png"
          alt="Info"
          width="22"
        />
      </div>
    </div>
  );
}

export default function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Item id={props.id} item={props.item} />
    </div>
  );
}

Item.propTypes = {
  item: PropTypes.shape({
    answer: PropTypes.string,
    behaviourDetail: PropTypes.string,
    category: PropTypes.string,
    iconUrl: PropTypes.string,
    id: PropTypes.string,
    imageFileName: PropTypes.string,
    isForConflict: PropTypes.bool,
    isForMatch: PropTypes.bool,
    question: PropTypes.string,
    questionDesc: PropTypes.string,
  }),
};

SortableItem.propTypes = {
  id: PropTypes.string,
  item: PropTypes.shape({
    answer: PropTypes.string,
    behaviourDetail: PropTypes.string,
    category: PropTypes.string,
    iconUrl: PropTypes.string,
    id: PropTypes.string,
    imageFileName: PropTypes.string,
    isForConflict: PropTypes.bool,
    isForMatch: PropTypes.bool,
    question: PropTypes.string,
    questionDesc: PropTypes.string,
  }),
};
