/* eslint-disable @typescript-eslint/no-non-null-assertion */

/* eslint-disable react/jsx-key */

/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Card } from 'reactstrap';
import { Button } from 'reactstrap';
import { Container, Row } from 'reactstrap';

import Grade from '_components/commons/Grade';
import StatusPill from '_components/commons/StatusPill';
import { IInstrument, IInvolvement } from 'interfaces/responses/compare/ICompareDto';
import { IGrade } from 'interfaces/responses/instrument/IGrade';
import { IScoresDto } from 'interfaces/responses/instrument/IScoresDto';
import { GradeInterface } from 'model/Instrument';
import { deleteCompareInstrument } from 'service/CompareServices';

import unsureIcon from '../../../_assets/img/icons/coDetails/Group 2@4x.png';
import conflictsIcon from '../../../_assets/img/icons/coDetails/conflict/focus@2x.png';
import checkIcon from '../../../_assets/img/icons/profile/Complete@3x.png';
import tickIcon from '../../../_assets/img/results/tick@3x.png';

type InstrumentCompareProps = {
  router?: any;
  id: string;
  instruments?: IInstrument[];
  instrumentType?: string;
  isInApproveProductlist?: string;
  logo?: string;
  matchToValuesStatus?: number;
  numberOfLinkedProfileExclusions?: number;
  numberOfLinkedProfilePreferences?: number;
  profileMatchResult?: string;
  communityScore?: number;
  employeeScore?: number;
  ethicsScore?: number;
  planetScore?: number;
  sector?: string;
  instrumentData: IInstrument[];
  involvements: IInvolvement[];
  instrumentId?: string;
  portfolioIndex?: number | undefined;
  grade?: GradeInterface;
  hasViewDetails?: boolean;
};

type InstrumentInterface = {
  instrumentId: string;
  instrumentType: string;
  involvementIncluded: [true];
  isInApprovedProductList: boolean;
  logo: string;
  matchToValuesStatus: number;
  numberOfLinkedProfileExclusions: number;
  numberOfLinkedProfilePreferences: number;
  scores: IScoresDto;
  sector: string;
  grade: IGrade;
};

const InstrumentCompare = (props: InstrumentCompareProps) => {
  const { instrumentData, involvements, hasViewDetails = true } = props;

  const [removeInstrumentId, setRemoveInstrumentId] = useState('');
  const [instruments, setInstruments] = useState<InstrumentInterface[]>(instrumentData);

  const history = useHistory();
  const { id } = useParams();

  const getStatus = (involvement: number) => {
    if (!involvement) return '-';
    else if (involvement === 1) return <img src={checkIcon} width="20px" height="20px" />;
    else if (involvement === 2) return <img src={conflictsIcon} width="20px" height="20px" />;
    else if (involvement === 3) return <img src={unsureIcon} width="20px" height="20px" />;
  };

  const handleClick = (instrumentId: string) => {
    deleteCompareInstrument(instrumentId, id)
      .then(() => {
        console.log('delete successful');
      })
      .catch((err) => {
        console.log(err.message);
      });

    const selectedItem = instruments.find(
      (i: InstrumentInterface) => i.instrumentId === instrumentId,
    );

    if (selectedItem !== null) {
      const portfolioIndex = instruments.indexOf(selectedItem as InstrumentInterface);
      instruments.splice(portfolioIndex, 1);
      console.log(instruments);
      setInstruments(instruments);
      setRemoveInstrumentId('');
    }
  };

  const confirmationModal = (instrumentId: string) => {
    return (
      <div className="overlay">
        <Container
          className="showup-modal"
          style={{
            width: '225px',
            height: '160px',
            marginTop: '300px',
            padding: '0',
            position: 'relative',
          }}
        >
          <Row className="text-center justify-content-center remove-box-top">
            <p style={{ color: '#fff', fontSize: '15px' }}>Remove Comparison</p>
          </Row>
          <Row>
            <p className="text-center remove-box-text">{`Are you sure you want to remove this comparison?`}</p>
          </Row>
          <Row className="justify-content-center mt-10">
            <p className="remove-box-cancel" onClick={() => setRemoveInstrumentId('')}>
              Cancel
            </p>
            <div className="remove-box-button" onClick={() => handleClick(instrumentId)}>
              {`Remove`}
            </div>
          </Row>
        </Container>
      </div>
    );
  };

  if (instrumentData && involvements) {
    return (
      <>
        {instrumentData.map((item: any) => {
          const instrumentId = item.instrumentId;
          return (
            <Fragment key={instrumentId}>
              <div className="description">
                <Card className="overview text-center">
                  <div className="overview-title">
                    <div className="highlight title-logo">
                      {item.isInApprovedProductList && <span className="Apl-tag">APL</span>}
                      <img src={item.logo} width="74px" height="74px" alt="title-logo" />
                    </div>
                    <h4 className="title-text">{item.name}</h4>
                    <p className="remove-text" onClick={() => setRemoveInstrumentId(instrumentId)}>
                      Remove
                    </p>
                  </div>
                  <div className="highlight">
                    <div className="profile-item heading" style={{ height: '60px' }} />
                    <div className="profile-item">
                      <StatusPill matchToValuesStatus={item.matchToValuesStatus} />
                    </div>

                    <div className="profile-item">
                      <span>
                        {!item?.numberOfLinkedProfilePreferences
                          ? '-'
                          : item.numberOfLinkedProfilePreferences}
                      </span>
                    </div>

                    <div className="profile-item">
                      <span>
                        {!item?.numberOfLinkedProfileExclusions
                          ? '-'
                          : item.numberOfLinkedProfileExclusions}
                      </span>
                    </div>

                    <div className="profile-item ">
                      <span className="sector-text">{!item?.sector ? '-' : item.sector}</span>
                    </div>

                    <div className="profile-item">
                      <span
                        className={
                          item.instrumentType.length === 3 ? 'uppercase' : 'instrument-type'
                        }
                      >
                        {!item?.instrumentType ? '-' : item.instrumentType}
                      </span>
                    </div>
                  </div>

                  <div className="profile-item heading">
                    <h5></h5>
                  </div>

                  <div className="profile-item">
                    <span>
                      <Grade className="grade-compare" grade={item.grade} />
                    </span>
                  </div>

                  <div className="profile-item">
                    <span>
                      {item?.scores?.planetScore ? Math.round(item?.scores?.planetScore) : '-'}%
                    </span>
                  </div>

                  <div className="profile-item">
                    <span>
                      {item?.scores?.ethicsScore ? Math.round(item?.scores?.ethicsScore) : '-'}%
                    </span>
                  </div>

                  <div className="profile-item">
                    <span>
                      {item?.scores?.communityScore
                        ? Math.round(item?.scores?.communityScore)
                        : '-'}
                      %
                    </span>
                  </div>

                  <div className="profile-item">
                    <span>
                      {item?.scores?.employeesScore
                        ? Math.round(item?.scores?.employeesScore)
                        : '-'}
                      %
                    </span>
                  </div>

                  <div className="profile-item heading">
                    <h5></h5>
                  </div>

                  {involvements?.map((involvement: { involvementType: any }, index: number) => {
                    <div className="profile-item" key={index}>
                      <span>{getStatus(involvement?.involvementType)}</span>
                    </div>;
                  })}

                  {/* Involvements Included */}
                  {item?.involvementIncluded.map((isTrue: any, index: number) => (
                    // eslint-disable-next-line react/jsx-key
                    <div className="profile-item" key={index} style={{ height: '45px' }}>
                      {!isTrue ? <span>{'-'}</span> : <img src={tickIcon} width={20} />}
                    </div>
                  ))}
                </Card>
                {hasViewDetails && (
                  <Button
                    id="btn-view-detail"
                    className="mt-2"
                    onClick={() => {
                      history.push(`/clients/${props.id}/instruments/${item.instrumentId}/results`);
                    }}
                  >
                    View Details
                  </Button>
                )}
              </div>
              {removeInstrumentId === instrumentId && confirmationModal(removeInstrumentId)}
            </Fragment>
          );
        })}
      </>
    );
  }
  return <></>;
};

export default InstrumentCompare;
