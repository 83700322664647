import { Fragment, useState, useContext, useEffect } from 'react';

import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Container, Row, Col } from 'reactstrap';

// import { AnyARecord } from 'dns';
// import Auth from 'service/AuthService';
import { ProfileContext } from 'store/ProfileContext';

import { getAdviser } from '../../service/AdvisorService';
import { LoadingPage, PageHeader } from '../commons';

import './account.scss';

interface AdvisorInterface {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;

  companyPostcode: string;
  companyState: string;
  companyStreetAddress: string;
  companySuburb: string;
  supportNotificationEmail: string;
}

const DetailsShow = () => {
  const profileCtx = useContext(ProfileContext);

  const [advisor, setAdvisor] = useState<AdvisorInterface>({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    companyPostcode: '',
    companyState: '',
    companyStreetAddress: '',
    companySuburb: '',
    supportNotificationEmail: '',
  });

  const [editDetails, setEditDetails] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getAdviser()
      .then((response) => {
        setAdvisor(response);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  }, []);

  const handleEditDetails = () => {
    setEditDetails(true);
  };

  const handleLogout = () => {
    profileCtx.logOut();
  };

  if (editDetails) {
    return <Redirect push to={{ pathname: '/account/accountedit' }} />;
  }

  return (
    <div className="slim-content">
      <Container>
        <PageHeader
          title="Your details"
          className="mb-10"
          rightButton={
            <Button color="link" onClick={() => handleLogout()} className="mb-05">
              Sign out
            </Button>
          }
        />
        {loading && <LoadingPage />}
        {!loading && (
          <Fragment>
            <Row className="mb-30">
              <Col sm={4}>First name</Col>
              <Col sm={6}>{advisor.firstName}</Col>
            </Row>
            <Row className="mb-30">
              <Col sm={4}>Last name</Col>
              <Col sm={6}>{advisor.lastName}</Col>
            </Row>
            <Row className="mb-30">
              <Col sm={4}>Email</Col>
              <Col sm={6}>{advisor.email}</Col>
            </Row>
            <Row className="mb-30">
              <Col sm={4}>Company name</Col>
              <Col sm={6}>{advisor.companyName}</Col>
            </Row>
            <Row className="mb-30">
              <Col sm={4}>Company address</Col>
              <Col sm={6}>{advisor.companyStreetAddress}</Col>
            </Row>
            <Row className="mb-30">
              <Col sm={4}>Company suburb</Col>
              <Col sm={6}>{advisor.companySuburb}</Col>
            </Row>
            <Row className="mb-30">
              <Col sm={4}>Company State</Col>
              <Col sm={6}>{advisor.companyState}</Col>
            </Row>
            <Row className="mb-30">
              <Col sm={4}>Company Postcode</Col>
              <Col sm={6}>{advisor.companyPostcode}</Col>
            </Row>
            {/* <Row className="mb-30">
              <Col sm={4}>Financial licence number</Col>
              <Col sm={6}>{advisor.afslNo}</Col>
            </Row>
            <Row className="mb-30">
              <Col sm={4}>Financial licence sub number</Col>
              <Col sm={6}>{advisor.carNo}</Col>
            </Row> */}
            <Row className="mb-30">
              <Col sm={4}>Support email</Col>
              <Col sm={6}>{advisor.supportNotificationEmail}</Col>
            </Row>
            {/* <Row className="mb-40 support-email-row">
              <Col sm={4} className="support-email">
                Support email
              </Col>
              <Col sm={6}>
                <TextField
                  className="mb-0 support-email-input"
                  onChange={changeSupportEmail}
                  value={requestBody.supportNotificationEmail}
                  validateInput={(email: string) => ValidatorService.validateEmail(email)}
                  required
                  placeholder={supportEmail}
                />
              </Col>
              <Col sm={2} className="support-email-button">
                <Button
                  color="link"
                  onClick={enabled ? handleSupportEmailSave : () => setEnabled(true)}
                  style={{ fontSize: '16px' }}
                >
                  {enabled ? 'Save' : 'Edit'}
                </Button>
              </Col>
            </Row> */}
            <Row className="justify-content-center">
              <Col sm={12} className="d-flex justify-content-center">
                <Button className="btn-main-shadowless" onClick={() => handleEditDetails()}>
                  Edit details
                </Button>
              </Col>
            </Row>
          </Fragment>
        )}
      </Container>
    </div>
  );
};

export default DetailsShow;
