/* eslint-disable @typescript-eslint/no-empty-function */
import { size } from 'lodash';
import { useContext } from 'react';

import { Row, Col } from 'reactstrap';

import { FilterContext } from 'store/FiltersContext';

import ButtonModal from '../../../commons/ButtonModal';
import FiltersModal from '../../../commons/FiltersModal';
import SortByModal from '../../../commons/SortByModal';

import './instrument.scss';

const FilterAndSortBy = (props: any) => {
  const filtersContext = useContext(FilterContext);
  const filters = filtersContext.filters;
  const sortBy = filtersContext.selectedSortBy;
  const totalSelectedFilters = filtersContext.selectedFilters.length;
  const { isColorIcon, isSearchModal } = props;

  return (
    <Row className="justify-content-center margin-v-10">
      <Col className="d-flex justify-content-end">
        {totalSelectedFilters && isColorIcon === false ? (
          <span className="filter-tag">{totalSelectedFilters}</span>
        ) : null}
        {!isColorIcon && <span className="filter-and-sort"> Filters </span>}
        <ButtonModal
          header={'All Filters'}
          onSubmit={() => {}}
          name={`Filters: ${size(filters)} applied`}
          component={FiltersModal}
          className=""
          showImage={true}
          params={{ filters }}
          handleClick={() => {}}
          isColorIcon={isColorIcon}
          totalSelectedFilters={totalSelectedFilters}
          isSearchModal={isSearchModal}
        />
        {isColorIcon ? <span className="filter-and-sort mr-10">Filters </span> : null}
        {isColorIcon && totalSelectedFilters ? (
          <span className="filter-tag" style={{ left: '8.1em', top: '-1em' }}>
            {totalSelectedFilters}
          </span>
        ) : null}
      </Col>
      {!window.location.pathname.endsWith('/search') && (
        <Col className="d-flex justify-content-start">
          <span className="filter-and-sort"> Sort </span>
          <ButtonModal
            header="Sort by:"
            className=""
            handleClick={() => {}}
            onSubmit={() => {}}
            name={sortBy ? `Sort by: ${sortBy}` : 'Sort by % match'}
            component={SortByModal}
            showImage={true}
            params={{ sortBy }}
            isColorIcon={isColorIcon}
            totalSelectedFilters={totalSelectedFilters}
            isSearchModal={isSearchModal}
          />
        </Col>
      )}
    </Row>
  );
};

export default FilterAndSortBy;
