import api, { apiSetup } from '../config/api';

import { getSessionToken } from './LocalStorageService';

export function getApl() {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .get('/v2/GetApprovedProductList')
      .then((res) => {
        if (res.ok) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function addClientApl(apl) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/v2/AddApprovedProductList', apl)
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 400 && res.data.isSuccess) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getAplDetails() {
  const getAplDetailsRequest = (resolve, reject) => {
    apiSetup();
    api
      .get('/v2/GetApprovedProductList')
      .then((res) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(getAplDetailsRequest);
}

export function deleteApl(aplId) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .delete(`/v2/DeleteApprovedProductList/${aplId}`)
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}
