import api, { apiSetup } from '../config/api';
import apiAuth from '../config/apiAuth';

import { getSessionToken } from './LocalStorageService';

export function validateShareCode(params) {
  const validateShareCodeRequest = (resolve, reject) => {
    apiAuth.setHeaders({ AuthorizationApp: process.env.REACT_APP_AUTORIZATION_APP });
    apiSetup();
    apiAuth
      .post('/api/user/ValidateShareCode', params)
      .then((res) => {
        if (res.ok && res.status === 200) {
          if (res.data.httpStatusCode === 200) {
            resolve(res.data);
          }
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(validateShareCodeRequest);
}

export function getMyShareCode(params) {
  const getMyShareCodeRequest = (resolve, reject) => {
    var session = getSessionToken();

    apiSetup();
    api
      .post('/api/user/GetMyShareCode')
      .then((res) => {
        if (res.ok) {
          if (res.data.httpStatusCode === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: res.data.message });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(getMyShareCodeRequest);
}

export function createShareCodeTransaction(params) {
  const request = (resolve, reject) => {
    apiAuth.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      Authorization: 'Bearer ' + getSessionToken(),
    });
    apiSetup();
    apiAuth
      .post('/api/user/CreateShareCodeTransaction', params)
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else {
          reject({ message: res.data.message });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}
