import { isEmpty } from 'lodash';
import React, { useState } from 'react';

import DateUtilsService from '../../../../../service/DateUtils';

interface ConflictingInstrument {
  name: string;
  exclusions: [string];
}

interface ConflictingInstrumentsProps {
  conflictInstruments: [ConflictingInstrument];
  clientFirstName: string;
  portfolioName: string;
  portfolioUploadDate: string;
}

export default function ConflictingInstruments(props: ConflictingInstrumentsProps) {
  const { conflictInstruments, clientFirstName, portfolioName, portfolioUploadDate } = props;
  const [showFullList, setShowFullList] = useState(false);

  const getExclusions = (item: ConflictingInstrument) => {
    const exclusionsString = item.exclusions.map((x: any) => x);
    return exclusionsString.join(' and ');
  };

  const showFullOrCondensedList = () => {
    if (isEmpty(conflictInstruments)) {
      return null;
    }

    let displayLength = 0;
    displayLength = conflictInstruments.length;
    if (conflictInstruments.length > 3 && !showFullList) {
      displayLength = 3;
    }

    const liArray = [];

    for (let i = 0; i < displayLength; i++) {
      liArray.push(
        <li key={i} className="margin-bottom-10">
          <b>{conflictInstruments[i].name}</b>
          <p className="font-14">{`because of ${getExclusions(conflictInstruments[i])}`}</p>
        </li>,
      );
    }
    return liArray;
  };

  const renderShowHideLink = () => {
    if (conflictInstruments.length > 3) {
      return (
        <span
          className="cursor-hand text-link-color"
          onClick={() => setShowFullList((prevState) => !prevState)}
        >
          {showFullList ? 'Condense List' : 'Show full list'}
        </span>
      );
    }
  };

  if (isEmpty(conflictInstruments)) {
    return (
      <>
        <p>
          {`Based on ${clientFirstName}'s sustainability profile, ${
            conflictInstruments.length
          } conflicts were identified in ${portfolioName}, uploaded on ${DateUtilsService.longFormatDate(
            portfolioUploadDate,
          )}`}
        </p>
        <div className="dasshed-box">
          <b
            className="mb-10"
            style={{ display: 'block' }}
          >{`In total ${conflictInstruments.length} conflicts were identified`}</b>
        </div>
      </>
    );
  }

  return (
    <>
      <p>
        {`Based on ${clientFirstName}'s sustainability profile, ${
          conflictInstruments.length
        } conflicts were identified in ${portfolioName}, uploaded on ${DateUtilsService.longFormatDate(
          portfolioUploadDate,
        )}`}
      </p>
      <div className="dasshed-box">
        <ul>{showFullOrCondensedList()}</ul>
        {renderShowHideLink()}
      </div>
      <br />
    </>
  );
}
