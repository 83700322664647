import { ISummaryDto } from 'interfaces/responses/instrument/ISummaryDto';

import { ApiEndpoints } from './ApiEndpoints';
import { BaseService } from './BaseService';

export abstract class SummaryService extends BaseService {
  static getInstance() {
    return this;
  }

  public static getInstrumentSummary(instrumentId: string, signal?: AbortSignal) {
    const instrumentSummary = this.callGet<ISummaryDto>(
      ApiEndpoints.GetInstrumentSummary(instrumentId),
      signal,
    );
    return instrumentSummary;
  }
}
