import api, { apiSetup } from '../config/api';

import { getSessionToken } from './LocalStorageService';

export function getAllClientPortfolios(ClientCoreId) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/Portfolio/GetAllClientPortfolios', { ClientCoreId })
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function deleteClientPortfolio(ClientPortfolioId) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/Portfolio/DeleteClientPortfolio', { ClientPortfolioId })
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getClientPortfolio(ClientPortfolioId) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .get(`v2/Portfolio/${ClientPortfolioId}`)
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function addClientPortfolioBulk(portfolio) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/Portfolio/AddClientPortfolioBulk', portfolio)
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 400 && res.data.isSuccess) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function addClientsPortfolioBulk(portfolio) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/Portfolio/AddClientsPortfolioBulk', portfolio)
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (res.data.isSuccess) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getSustainabilityScores(clientId) {
  const getSustainabilityScoresRequest = (resolve, reject) => {
    apiSetup();
    api
      .get(`/v2/portfolio/sustainabilityscores/${clientId}`)
      .then((res) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(getSustainabilityScoresRequest);
}
/**
 * @typedef {object} SetPortfolioIsActiveBody
 * @property{string} clientId
 * @property{string} portfolioId
 */
/**
 * 
 * @param {SetPortfolioIsActiveBody} portfolio 
 * @returns 
 */
export function setPortfolioIsActive(portfolio) {
  console.log(portfolio, 'wwwww');
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post(`/v2/Portfolio/SetPortfolioAsActive`, portfolio)
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (res.data.isSuccess) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}
