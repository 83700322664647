import { isEmpty } from 'lodash';
import React from 'react';

import { Line } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';

import { optionsLineChart } from '../../_constants';
import NoDataView from '../commons/Instrument/NoDataView';

const RiskAndReturnChart = ({ instrumentGraph }) => {
  const renderContent = () => {
    if (!instrumentGraph || isEmpty(instrumentGraph.graphItems)) {
      return <NoDataView />;
    } else {
      return (
        <Row className="risk-and-return-chart-container line-chart">
          <Line
            style={{
              height: '360px',
              width: '549px',
            }}
            // height={420}
            // width={500}
            data={instrumentGraph.riskAndReturn}
            options={optionsLineChart}
          />
        </Row>
      );
    }
  };

  return (
    <>
      <h4>Past Performance</h4>
      {renderContent()}
    </>
  );
};

export default RiskAndReturnChart;
