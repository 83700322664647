import { Link } from 'react-router-dom';
import { Row, Button } from 'reactstrap';

import viewed from '../../_assets/img/icons/client/viewed@3x.png';
import hasProfile from '../../_assets/img/icons/profile/Complete@3x.png';
import hasNotProfile from '../../_assets/img/icons/profile/Incomplete@3x.png';
import { ButtonModal } from '../commons';

import SendModal from './SendModal';
import './client.scss';

const Client = (props: any) => {
  const { client } = props;
  // const getStartUrl = () => {
  //   return props.history.push(`/clients/${client.clientCoreId}/investing-profile/start`);
  // };

  const renderEye = () => {
    // if (client.hasActiveProfile) {
    if (client.isViewed) {
      return (
        <span>
          <img src={viewed} width="25" className="client-profile-view" />
        </span>
      );
    } else {
      return <p className="client-profile-view">N/A</p>;
    }
    // }
    return null;
  };

  return (
    <Row
      className="client-list-header d-flex mt-5"
      style={{ width: '100%' }}
      client-id={client.clientCoreId}
    >
      <div className="client-list details" style={{ width: '20%' }}>
        <Link to={`/clients/${client.clientCoreId}/results/portfolioResults`}>
          {client.clientFirstName}
        </Link>
      </div>
      <div className="client-list details" style={{ width: '20%' }}>
        <Link to={`/clients/${client.clientCoreId}/results/portfolioResults`}>
          {client.clientLastName}
        </Link>
      </div>

      <div className="client-list details" style={{ width: '15%' }}>
        {client.hasActiveProfile ? (
          <div className="profile-status-details">
            <div>Complete</div>
            <div className="profile-status-icon">
              <img src={hasProfile} alt="Triangle" width="20" />
            </div>
          </div>
        ) : (
          <div className="profile-status-details">
            <div>Not started</div>
            <div className="profile-status-icon">
              <img src={hasNotProfile} alt="Triangle" width="20" />
            </div>
          </div>
        )}
      </div>

      <div className="client-list details" style={{ width: '15%' }}>
        {client.hasActiveProfile ? (
          <div>
            <ButtonModal
              component={SendModal}
              className="link hide-button"
              params={client.clientCoreId}
            />
            Redo Profile
          </div>
        ) : (
          <div style={{ color: ' #3e61df' }}>
            <ButtonModal
              component={SendModal}
              className="link hide-button"
              params={client.clientCoreId}
            />
            Start Profile
          </div>
        )}
      </div>

      <div className="client-list details" style={{ width: '15%' }}>
        {renderEye()}
      </div>

      <div className="client-edit">
        <Button
          color="link"
          onClick={() => props.history.push(`/clients/${client.clientCoreId}/clientDetails`)}
        >
          edit
        </Button>
      </div>
    </Row>
  );
};

export default Client;
