import apiAuth from '../config/apiAuth';

export function forgetPassword(params) {
  const forgetPasswordRequest = (resolve, reject) => {
    apiAuth.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
    });
    apiAuth
      .post('/api/user/forgetPasswordRequest', params)
      .then((res) => {
        if (res.ok && res.status === 200) {
          if (res.data.httpStatusCode === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(forgetPasswordRequest);
}

export function resetPasswordWithCode(params) {
  const resetPasswordWithCodeRequest = (resolve, reject) => {
    apiAuth.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
    });
    apiAuth
      .post('/api/user/resetpasswordwithcode', params)
      .then((res) => {
        if (res.ok && res.status === 200) {
          if (res.data.httpStatusCode === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(resetPasswordWithCodeRequest);
}
