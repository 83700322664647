import React from 'react';
import { useContext } from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import Comparison from '_components/clients/Comparison/Comparison';
import Research from '_components/research';
import PropTypes from 'prop-types';
import { FilterContextProvider } from 'store/FiltersContext';

import { InvestingProfile } from '../../model/profile';
import { ProfileContext } from '../../store/ProfileContext';
import Settings from '../account/Settings';
import Instrument from '../commons/Instrument/Instrument';
import Layout from '../ui/Layout';
import Presets from '../ui/Presets';

import AplAdd from './Apl/AddApl';
import AplSummary from './Apl/AplSummary';
import AplUpload from './Apl/AplUpload';
import ReviewApl from './Apl/ReviewApl';
import ClientAdd from './ClientCreator/Add';
import ClientAddSuccess from './ClientCreator/Success';
import UploadPortfolioWithClient from './ClientCreator/UploadPortfolio';
import ClientAdviceOutputs from './ClientDetails/Advice/Outputs';
import ClientAdviceOutput from './ClientDetails/Advice/Outputs/AdviceOutput';
import AdviceOutputV2 from './ClientDetails/Advice/Outputs/AdviceOutputV2/index';
import ClientProfileDetails from './ClientDetails/Profile/Details';
import ClientEditor from './ClientDetails/Profile/Details/ClientEditor';
import ClientProfilePortfolios from './ClientDetails/Profile/Portfolios';
import ClientProfilePortfoliosAdd from './ClientDetails/Profile/Portfolios/Add';
import ClientProfileValues from './ClientDetails/Profile/Values';
import Results from './ClientDetails/Results/Results';
import ImportClients from './ImportClients';
import ClientList from './List';
import Final from './ProfileFeature/Final';
import LifeStyles from './ProfileFeature/LifeStyles';
import Prioritise from './ProfileFeature/Prioritise';
import ProfileCauses from './ProfileFeature/ProfileCauses';
import States from './ProfileFeature/States';
import Timeframe from './ProfileFeature/Timeframe';
import UploadPortfolio from './UploadPortfolio';
import ClientProfilePortfolioSummary from './UploadPortfolio/ClientProfilePortfolioSummary';
import { XplanConnected } from './Xplan/XplanConnected';
import ClientAddInvestingProfileStart from './investing-profile/Start.tsx';
import ClientAddInvestingProfileStep1 from './investing-profile/Step1.tsx';
import ClientAddInvestingProfileStep2 from './investing-profile/Step2.tsx';
import ClientAddInvestingProfileStep3 from './investing-profile/Step3.tsx';
import ClientAddInvestingProfileStep4 from './investing-profile/Step4.tsx';
import ClientAddInvestingProfileStep5 from './investing-profile/Step5.tsx';
import ClientAddInvestingProfileStep6 from './investing-profile/Step6.tsx';
import ClientAddInvestingProfileStep7 from './investing-profile/Step7.tsx';
import ClientWelcomeInvestingProfileStart from './investing-profile/Welcome.jsx';

// const idRegex = "([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})?"; // Note the questionmark ? at the end

/**
 * Build Layout props for clients ClientDetails section.
 */
function getProps(routerProps, component, sidebarSelect = null, overrides = {}) {
  return {
    router: routerProps,
    header: Presets.main,
    headerSelect: 'clients',
    headerSelect: 'profile',
    sidebar: Presets.profile(routerProps),
    sidebarSelect,
    component,
    onLoad,
    ...overrides,
  };
}

function onLoad(props) {
  let clientId = props.router.match.params.id;
  return new Promise((resolve, reject) => {
    InvestingProfile.load(clientId)
      .then(() => {
        // let conflictsCount = InvestingProfile.getConflicts().length;
        // let alternativesCount = InvestingProfile.getAlternatives().length;
        // let matchesCount = InvestingProfile.getMatches().length;
        let outputsCount = InvestingProfile.getOutputs().length;
        //let outcomesCount = InvestingProfile.getOutputs().length;
        // let isResultsFromPortfolio = InvestingProfile.getIsResultsFromPortfolio();
        // if (isResultsFromPortfolio) {
        //     props.builder.updateSidebar('results.conflicts', {title: `Portfolio Conflicts (${conflictsCount})`});
        //     props.builder.updateSidebar('results.matches', {title: `Portfolio Matches (${matchesCount})`});
        //     props.builder.updateSidebar('results.alternatives', {title: `Alternatives (${alternativesCount})`});
        // } else {
        //     props.builder.updateSidebar('results.conflicts', {title: `Profile Conflicts (${conflictsCount})`});
        //     props.builder.updateSidebar('results.matches', {title: `Profile Matches (${matchesCount})`});
        //     props.builder.updateSidebar('results.alternatives', {title: `Non Matches (${alternativesCount})`});
        // }
        props.builder.updateSidebar('advice.outputs', { title: `Outputs (${outputsCount})` });
        //props.builder.updateSidebar('advice.outcomes', {title: `Outcomes (${outcomesCount})`});
        resolve();
      })
      .catch(reject);
  });
}

const Clients = ({ match: { path } }) => {
  return (
    <Switch>
      <Route
        path={`${path}xplan/success`}
        render={(props) => (
          <Layout
            {...getProps(props, XplanConnected, null, {
              sidebar: null,
              onLoad: null,
            })}
          />
        )}
      />
      <Route
        path={`${path}/uploadPortfolio`}
        render={(props) => (
          <Layout
            {...getProps(props, UploadPortfolioWithClient, 'create.uploadPortfolio', {
              sidebar: Presets.clients,
            })}
          />
        )}
      />
      <Route
        path={`/aplUpload/review`}
        render={(props) => (
          <Layout
            {...getProps(props, AplSummary, 'aplUpload', {
              sidebar: Presets.clients,
            })}
          />
        )}
      />
      <Route
        path={`/aplUpload`}
        render={(props) => (
          <Layout
            {...getProps(props, AplAdd, 'aplUpload', {
              sidebar: Presets.clients,
            })}
          />
        )}
      />
      <Route
        path={`/reviewApl`}
        render={(props) => (
          <Layout
            {...getProps(props, ReviewApl, 'reviewApl', {
              sidebar: Presets.clients,
            })}
          />
        )}
      />

      <Route
        path={`${path}/importClients`}
        render={(props) => (
          <Layout
            {...getProps(props, ImportClients, 'importClients', { sidebar: Presets.clients })}
          />
        )}
      />
      <Route
        path={`${path}/portfoliosUpload`}
        render={(props) => (
          <Layout
            {...getProps(props, UploadPortfolio, 'portfoliosUpload', { sidebar: Presets.clients })}
          />
        )}
      />

      <Route
        path={`${path}/:clientId/instruments/:instrumentId/results`}
        render={(props) => (
          <Layout
            {...getProps(props, Instrument, null, {
              sidebar: null,
              onLoad: null,
            })}
          />
        )}
      />

      <Route
        path={`${path}/:id/investing-profile/start`}
        render={(props) => (
          <Layout
            {...getProps(props, ClientAddInvestingProfileStart, null, {
              sidebar: null,
              onLoad: null,
            })}
          />
        )}
      />

      {/* Implement new profile feature questions */}
      <Route
        path={`${path}/:id/investing-profile/profile-builder`}
        render={(props) => (
          <Layout
            {...getProps(props, ClientWelcomeInvestingProfileStart, null, {
              sidebar: null,
              onLoad: null,
            })}
          />
        )}
      />
      <Route
        path={`${path}/:id/investing-profile/lifestyles`}
        render={(props) => (
          <Layout
            {...getProps(props, LifeStyles, null, {
              sidebar: null,
              onLoad: null,
            })}
          />
        )}
      />
      <Route
        path={`${path}/:id/investing-profile/causes`}
        render={(props) => (
          <Layout
            {...getProps(props, ProfileCauses, null, {
              sidebar: null,
              onLoad: null,
            })}
          />
        )}
      />
      <Route
        path={`${path}/:id/investing-profile/prioritise`}
        render={(props) => (
          <Layout
            {...getProps(props, Prioritise, null, {
              sidebar: null,
              onLoad: null,
            })}
          />
        )}
      />
      <Route
        path={`${path}/:id/investing-profile/states`}
        render={(props) => (
          <Layout
            {...getProps(props, States, null, {
              sidebar: null,
              onLoad: null,
            })}
          />
        )}
      />
      <Route
        path={`${path}/:id/investing-profile/timeframe`}
        render={(props) => (
          <Layout
            {...getProps(props, Timeframe, null, {
              sidebar: null,
              onLoad: null,
            })}
          />
        )}
      />
      <Route
        path={`${path}/:id/investing-profile/final`}
        render={(props) => (
          <Layout
            {...getProps(props, Final, null, {
              sidebar: null,
              onLoad: null,
            })}
          />
        )}
      />
      <Route
        path={`${path}/:id/investing-profile/step1`}
        render={(props) => (
          <Layout
            {...getProps(props, ClientAddInvestingProfileStep1, null, {
              sidebar: null,
              onLoad: null,
            })}
          />
        )}
      />
      <Route
        path={`${path}/:id/investing-profile/step2`}
        render={(props) => (
          <Layout
            {...getProps(props, ClientAddInvestingProfileStep2, null, {
              sidebar: null,
              onLoad: null,
            })}
          />
        )}
      />
      <Route
        path={`${path}/:id/investing-profile/step3`}
        render={(props) => (
          <Layout
            {...getProps(props, ClientAddInvestingProfileStep3, null, {
              sidebar: null,
              onLoad: null,
            })}
          />
        )}
      />
      <Route
        path={`${path}/:id/investing-profile/step4`}
        render={(props) => (
          <Layout
            {...getProps(props, ClientAddInvestingProfileStep4, null, {
              sidebar: null,
              onLoad: null,
            })}
          />
        )}
      />
      <Route
        path={`${path}/:id/investing-profile/step5`}
        render={(props) => (
          <Layout
            {...getProps(props, ClientAddInvestingProfileStep5, null, {
              sidebar: null,
              onLoad: null,
            })}
          />
        )}
      />
      <Route
        path={`${path}/:id/investing-profile/step6`}
        render={(props) => (
          <Layout
            {...getProps(props, ClientAddInvestingProfileStep6, null, {
              sidebar: null,
              onLoad: null,
            })}
          />
        )}
      />
      <Route
        path={`${path}/:id/investing-profile/step7`}
        render={(props) => (
          <Layout
            {...getProps(props, ClientAddInvestingProfileStep7, null, {
              sidebar: null,
              onLoad: null,
            })}
          />
        )}
      />
      <Route
        path={`${path}/:id/advice/outputs/add`}
        render={(props) => <Layout {...getProps(props, ClientAdviceOutput, 'advice.outputs')} />}
      />
      <Route
        path={`${path}/:id/advice/outputs/addV2`}
        render={(props) => <Layout {...getProps(props, AdviceOutputV2, 'advice.outputs')} />}
      />
      <Route
        path={`${path}/:id/advice/outputs/view/:outputid`}
        render={(props) => <Layout {...getProps(props, ClientAdviceOutput, 'advice.outputs')} />}
      />
      <Route
        path={`${path}/:id/advice/outputs/viewV2/:outputid`}
        render={(props) => <Layout {...getProps(props, AdviceOutputV2, 'advice.outputs')} />}
      />
      <Route
        path={`${path}/:id/advice/outputs/edit/:outputid`}
        render={(props) => <Layout {...getProps(props, ClientAdviceOutput, 'advice.outputs')} />}
      />
      <Route
        path={`${path}/:id/advice/outputs/editV2/:outputid`}
        render={(props) => <Layout {...getProps(props, AdviceOutputV2, 'advice.outputs')} />}
      />
      <Route
        path={`${path}/add/:id/success`}
        render={(props) => (
          <Layout
            {...getProps(props, ClientAddSuccess, 'create', {
              sidebar: Presets.clients,
              onLoad: null,
            })}
          />
        )}
      />
      <Route
        path={`${path}/add`}
        render={(props) => (
          <Layout
            {...getProps(props, ClientAdd, 'create', { sidebar: Presets.clients, onLoad: null })}
          />
        )}
      />
      <Route
        path={`${path}/:id/profile/edit`}
        render={(props) => <Layout {...getProps(props, ClientEditor, 'profile.details')} />}
      />
      {/* ClientDetails Details - Results */}
      <Route
        path={`${path}/:id/results`}
        render={(props) => <Layout {...getProps(props, Results, 'results.portfolioResults')} />}
      />

      {/*<Route*/}
      {/*    path={`${path}/:id/results/matches`}*/}
      {/*    render={props => (<Layout {...getProps(props, ClientResultsMatches, 'results.matches')} />)}*/}
      {/*/>*/}
      {/*<Route*/}
      {/*    path={`${path}/:id/results/alternatives`}*/}
      {/*    render={props => (<Layout {...getProps(props, ClientResultsAlternatives, 'results.alternatives')} />)}*/}
      {/*/>*/}

      {/* ClientDetails Details - Profile */}

      <Route
        path={`${path}/:id/profile/portfolios/add`}
        render={(props) => (
          <Layout {...getProps(props, ClientProfilePortfoliosAdd, 'profile.portfolios')} />
        )}
      />
      <Route
        path={`${path}/:id/portfolioUpload/review/:portfolioId`}
        render={(props) => (
          <Layout {...getProps(props, ClientProfilePortfolioSummary, 'profile.portfolios')} />
        )}
      />
      <Route
        path={`/portfolioUpload/review/:portfolioId/bulk`}
        render={(props) => (
          <Layout
            {...getProps(props, ClientProfilePortfolioSummary, 'profile.portfolios', {
              sidebar: Presets.clients,
              onLoad: null,
            })}
          />
        )}
      />
      <Route
        path={`${path}/:id/portfolios`}
        render={(props) => <Layout {...getProps(props, ClientProfilePortfolios, 'portfolios')} />}
      />

      <Route
        path={`${path}/:id/search`}
        render={(props) => <Layout {...getProps(props, Research, 'search')} />}
      />
      <Route
        path={`${path}/:id/compare`}
        render={(props) => <Layout {...getProps(props, Comparison, 'compare')} />}
      />
      <Route
        path={`${path}/:id/profile/values`}
        render={(props) => (
          <Layout {...getProps(props, ClientProfileValues, 'sustainabilityProfile')} />
        )}
      />
      <Route
        path={`${path}/:id/clientDetails`}
        render={(props) => <Layout {...getProps(props, ClientProfileDetails, 'clientDetails')} />}
      />

      {/* ClientDetails Details - Advice */}
      <Route
        path={`${path}/:id/advice/outputs`}
        render={(props) => <Layout {...getProps(props, ClientAdviceOutputs, 'advice.outputs')} />}
      />
      {/*<Route*/}
      {/*    path={`${path}/:id/advice/outcomes`}*/}
      {/*    render={props => (<Layout {...getProps(props, ClientAdviceOutcomes, 'advice.outcomes')} />)}*/}
      {/*/>*/}

      <Route
        path={`${path}/:id/profile`}
        render={(props) => <Redirect to={`${path}/${props.match.params.id}/profile/portfolios`} />}
      />
      <Route
        path={`${path}/:id/advice`}
        render={(props) => <Redirect to={`${path}/${props.match.params.id}/advice/outputs`} />}
      />
      <Route
        path={`${path}/:id`}
        render={(props) => <Redirect to={`${path}/${props.match.params.id}/results`} />}
      />
      <Route
        path={path}
        render={(props) => (
          <Layout
            {...getProps(props, ClientList, 'list', { sidebar: Presets.clients, onLoad: null })}
          />
        )}
      />
    </Switch>
  );
};

Clients.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string }).isRequired,
};

export default Clients;
