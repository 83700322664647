import React from 'react';

import { Table } from 'reactstrap';

import { showTableData } from '../../_helpers/instrument';

interface PersonnelProps {
  officerName?: string;
  officerTitle?: string;
  numberOfEmployees?: string;
}
function Personnel(props: PersonnelProps) {
  const { officerName, officerTitle, numberOfEmployees } = props;
  return (
    <>
      <div className="table-benchmark">
        <Table responsive bordered className="text-left m-0 table-vertical">
          <tbody>
            <tr key="1">
              <td className="gray w-50">{officerTitle ? `${officerTitle}` : '-'}</td>
              <td>{officerName ? `${officerName}` : '-'}</td>
            </tr>
            <tr key="2">
              <td className="gray">Full time employees</td>
              <td>{showTableData(numberOfEmployees)}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default Personnel;
