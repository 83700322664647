import { Fragment, useState, useContext, useEffect } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Container, Row, Col } from 'reactstrap';

import { ProfileContext } from 'store/ProfileContext';

import { getStripeCardAccountsByUserId } from '../../service/PaymentsService';
import { LoadingPage, PageHeader } from '../commons';

import GetInTouch from './GetInTouch';

const BillingShow = (props) => {
  const profileCtx = useContext(ProfileContext);

  const [card, setCard] = useState({
    exp_month: '',
    exp_year: '',
    cvc: '***',
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getStripeCardAccountsByUserId()
      .then((response) => {
        if (response.card !== null) {
          setCard(response.card);
        }
      })
      .catch((err) => {
        // this.setState({ showMessage: true, message: err.message });
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleLogout = () => {
    profileCtx.logOut();
  };

  return (
    <div className="slim-content">
      <Container>
        <PageHeader
          title="Billing details"
          rightButton={
            <Button color="link" onClick={() => handleLogout()}>
              Sign out
            </Button>
          }
        />
        {loading && <LoadingPage />}
        {!loading && (
          <Fragment>
            <Row className="mb-20">
              <Col sm={6}>Name on card</Col>
              <Col sm={6}>{card.name}</Col>
            </Row>
            <Row className="mb-20">
              <Col sm={6}>Card number</Col>
              <Col sm={6}>**** **** **** {card.last4}</Col>
            </Row>
            <Row className="mb-20">
              <Col sm={6}>Expiry date</Col>
              <Col sm={6}>** / {card.exp_year.toString().slice(-2)}</Col>
            </Row>
            <Row className="mb-40">
              <Col sm={6}>CVC</Col>
              <Col sm={6}>***</Col>
            </Row>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Row className="justify-content-center">
              <Col sm={12} className="d-flex justify-content-center font-weight-bolder">
                <GetInTouch />
              </Col>
            </Row>
          </Fragment>
        )}
        <ToastContainer />
      </Container>
    </div>
  );
};

export default BillingShow;
