import { Fragment } from 'react';

import { Doughnut } from 'react-chartjs-2';

import { IResultsSummaryResponse } from './Results';

interface IPortfolioWeighting {
  resultsSummary: IResultsSummaryResponse;
}
const PortfolioWeighting = ({ resultsSummary }: IPortfolioWeighting) => {
  const percentage = resultsSummary.avoidPercentage + resultsSummary.supportPercentage;

  const data = {
    datasets: [
      {
        data: [
          (resultsSummary.avoidPercentage / percentage) * 100,
          (resultsSummary.supportPercentage / percentage) * 100,
        ],
        backgroundColor: ['#bd5f80', '#7BE0D3'],
        borderWidth: 0,
        borderColor: ['#bd5f80', '#7BE0D3'],
        borderRadius: 7,
        rotation: 280,
        cutout: '35%',
      },
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    interaction: {
      mode: 'nearest',
      intersect: false,
      hover: {
        intersect: true,
      },
    },
    elements: {
      arc: {
        hoverBorderColor: 'rgba(0,0,0,0)',
        hoverBackgroundColor: (context: any) => {
          return context.dataset.backgroundColor[context.dataIndex];
        },
      },
    },
  };
  const avoidNumberOfHoldings = resultsSummary?.avoidNumberOfHoldings;
  const supportNumberOfHoldings = resultsSummary?.supportNumberOfHoldings;

  return (
    <Fragment>
      <div className="portfolio-doughnut-container">
        <div className="doughnut-chart">
          <Doughnut data={data} options={options}></Doughnut>
          <div className="legend left">
            <div className="percentage">{resultsSummary?.avoidPercentageDisplay}</div>
            <div className="holdings">{`${avoidNumberOfHoldings} ${
              avoidNumberOfHoldings && avoidNumberOfHoldings > 1 ? 'holdings' : 'holding'
            }`}</div>
            <div className="avoid">{'"Avoid"'}</div>
          </div>
          <div className="legend right">
            <div className="percentage">{resultsSummary?.supportPercentageDisplay}</div>
            <div className="holdings">{`${supportNumberOfHoldings} ${
              supportNumberOfHoldings && supportNumberOfHoldings > 1 ? 'holdings' : 'holding'
            }`}</div>
            <div className="support">{'"Support"'}</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default PortfolioWeighting;
