import React, { Fragment } from 'react';

import Dropzone from 'react-dropzone';
import { Button, Row, Col, Form } from 'reactstrap';

import PropTypes from 'prop-types';

import tipIcon from '../../../_assets/img/icons/profile/Bulb@1.5x.png';
import { ButtonLoading } from '../../commons';

import ExampleCard from './ExampleCard';

const feedbackClassName = (filename, error) => {
  if (error) {
    return 'dropzone justify-content-center is-invalid form-control';
  }
  if (filename && !error) {
    return 'dropzone justify-content-center is-valid form-control';
  }
  return 'dropzone justify-content-center';
};

const ClientsUpload = ({ onDrop, onDropRejected, filename, onBack, onSubmit, error, loading }) => (
  <Form onSubmit={(e) => onSubmit(e)}>
    <ExampleCard />
    <Row className="mt-5">
      <Col className="client-upload-container" sm={{ size: 12 }}>
        <Dropzone {...{ onDrop, onDropRejected, noClick: true }} accept=".csv">
          {({ getRootProps, getInputProps, open }) => (
            <Fragment>
              <Row
                {...getRootProps({ refKey: 'input' })}
                className={feedbackClassName(filename, error)}
              >
                <input {...getInputProps()} />
                <p className="text-comment">{!filename ? '' : filename}</p>
              </Row>
              <Row className="text-comment mb-1 mt-1 align-items-center">
                <Col className="client-upload-container" sm={8} style={{ color: '#727E81', whiteSpace: 'noWrap' }}>
                  <div className="tips-panel d-flex">
                    <img src={tipIcon} alt="icon" className="tip-icon" />
                    Acceptable file types: CSV or tab-delimited text files.
                  </div>
                </Col>
                <Col style={{ paddingLeft: '6px', paddingRight: '6px' }} sm={4}>
                  <Button onClick={open} className="browser-button">
                    Choose File
                  </Button>
                </Col>
              </Row>
            </Fragment>
          )}
        </Dropzone>
      </Col>
    </Row>
    <Row className="mt-1">
      <Col sm={8} className="d-flex justify-content-center align-items mt-1">
        <ButtonLoading
          loading={loading}
          className="btn-main-shadowless"
          label="Upload and review"
          disabled={!filename || loading}
          small
        />
      </Col>
    </Row>
    <br />
    <Row>
      <p className="text-comment">
        Duplicate addresses will be removed. We do not send confirmation emails to imported
        addresses and trust that you've gathered proper permission to send to every address in your
        audience.
      </p>
    </Row>
  </Form>
);

ClientsUpload.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onDropRejected: PropTypes.func.isRequired,
  filename: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

ClientsUpload.defaultProps = {
  filename: null,
};

export default ClientsUpload;
