import React from 'react';

import { Container, Row, Col, Button } from 'reactstrap';

import successIcon from '../../../_assets/img/icons/graphics/jumping@3x.png';

const SuccessUpload = ({ goToClientList }) => (
  <Container>
    <br />
    <Row className="justify-content-center mt-10">
      <img src={successIcon} style={{ maxWidth: '189px', maxHeight: '295px' }} alt="success" />
    </Row>
    <Row className="justify-content-center mt-30">
      <h2>Success!</h2>
    </Row>
    <Row className="justify-content-center  mt-30 mb-30">
      Now you can get your newly-added clients to fill out a responsible investing questionnaire, so
      they can let you know their values around investment.
    </Row>
    <Row className="justify-content-center mt-40">
      <Col sm={6}>
        <Button className="btn-main-shadowless" onClick={goToClientList}>
          View client list
        </Button>
      </Col>
    </Row>
  </Container>
);

export default SuccessUpload;
