import React from 'react';

import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Container } from 'reactstrap';

import valuesProfile from '../../_assets/img/icons/graphics/laptop_2@3x.png';

const StartQuestionnaire = (props: any) => {
  const history = useHistory();
  return (
    <Container>
      <Row className="justify-content-center mt-50">
        <img src={valuesProfile} alt="laptop" width="200" />
      </Row>
      <Row className="justify-content-center mt-30">
        <p>Create a Sustainability Profile to find results</p>
        <Col sm={12} className="d-flex justify-content-center mt-20 mb-20">
          <Button className="btn-main-shadowless" onClick={() => props.startInvestingProfile()}>
            Create a profile
          </Button>
        </Col>
        <a onClick={() => history.push(`/clients`)} style={{ cursor: 'pointer', fontSize: '17px' }}>
          Back to client list
        </a>
      </Row>
    </Container>
  );
};

export default StartQuestionnaire;
