import React, { useContext, useState } from 'react';

import Lottie from 'react-lottie-player';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Container, Table, Row, Spinner } from 'reactstrap';

import { ProfileContext } from 'store/ProfileContext';

import animationData from '../../../_assets/lotties/generating_results.json';
import { createUpdateProfile } from '../../../service/ProfilingService';
import { StepsBar } from '../../commons';

import arrowLogo from './../../../_assets/img/icons/profileIntro/right-arrow@3x.png';
import './styles.scss';

export default function Step7() {
  const history = useHistory();
  const { state } = history.location;
  const params = useParams();

  const profileContext = useContext(ProfileContext);
  const [submittingResults, setSubmittingResults] = useState(false);
  const [timeFrame, setTimeFrame] = useState(undefined);
  const [showModal, setShowModal] = useState(false);

  const handleGenerateResults = (practice: any, e: any) => {
    e.preventDefault();
    if (!timeFrame) {
      return toast.error('Choose at least one');
    }
    const clientId = params.id;
    const requestObject = {
      ClientCoreId: clientId,
      Timeframe: timeFrame,
      Answers: state,
    };
    if (profileContext.isLoggedIn) {
      setSubmittingResults(true);
      createUpdateProfile(requestObject)
        .then(() => {
          setTimeout(function () {
            history.push(`/clients/${clientId}/results`);
          }, 7000);
        })
        .catch((err) => {
          setSubmittingResults(false);
          toast.error(err.message);
        });
    } else {
      setShowModal(true);
      createUpdateProfile(requestObject)
        .then(() => {
          console.log('profile has been sent to adviser');
        })
        .catch((err) => {
          setShowModal(false);
          toast.error(err.message);
        });
    }
  };

  const onChangeValue = (event: any) => {
    setTimeFrame(event.target.value);
  };

  const renderLottie = () => {
    return (
      <div className="client-steps">
        <div className="text-center justify-content-center d-flex header">
          <p className="mt-0">{`Analysing your responses. Generating your results.`}</p>
        </div>
        <Lottie
          animationData={animationData}
          loop={true}
          play={true}
          rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
        />
      </div>
    );
  };

  const renderTimeFrameQuestion = () => {
    return (
      <Container className="client-steps">
        <Row className="text-center justify-content-center mt-30">
          <div className="header">
            <p>{`What time frame do you want your results to be based on?`}</p>
          </div>
        </Row>
        <Table
          borderless
          className="text-center time-frame mt-150 mb-150"
          onChange={(e: any) => {
            onChangeValue(e);
          }}
        >
          <thead>
            <tr>
              <th>
                <input type="radio" value="0" name="timeFrame" />
              </th>
              <th>
                <input type="radio" value="5" name="timeFrame" />
              </th>
              <th>
                <input type="radio" value="4" name="timeFrame" />
              </th>
              <th>
                <input type="radio" value="3" name="timeFrame" />
              </th>
              <th>
                <input type="radio" value="2" name="timeFrame" />
              </th>
              <th>
                <input type="radio" value="1" name="timeFrame" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <label>all years</label>
              </td>
              <td>
                <label>last 5 years</label>
              </td>
              <td>
                <label>{`last 4 years`}</label>
              </td>
              <td>
                <label>{`last 3 years`}</label>
              </td>
              <td>
                <label>{`last 2 years`}</label>
              </td>
              <td>
                <label>{`current year`}</label>
              </td>
            </tr>
          </tbody>
        </Table>
        <Row className="justify-content-center" style={{ marginTop: '250px' }}>
          <Col sm={6} className="mb-2">
            <Button
              outline
              className="btn-secondary mb-10"
              onClick={() => history.push('step6', state)}
            >{`Back`}</Button>
          </Col>
          <Col sm={6}>
            <Button
              className="btn-main-shadowless mb-10"
              onClick={(e: any) => handleGenerateResults(null, e)}
            >
              {submittingResults ? <Spinner /> : `Submit`}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  };

  const renderModal = () => {
    return (
      <div className="overlay">
        <Container className="showup-modal">
          <Row className="mb-50 justify-content-center">
            <img src={arrowLogo} width="200" />
          </Row>
          <Row className="text-center justify-content-center">
            <div className="header">
              <p>{`Nice work, you're all done!`}</p>
            </div>
          </Row>
          <Row className="mt-40">
            <p
              className="d-block text-center w-100"
              style={{ fontSize: '22px', padding: '0 45px' }}
            >{`Your answers have been submitted to your Adviser. Once reviewed, your Adviser will complete their analysis and contact you.`}</p>
          </Row>
          <Row className="justify-content-center mt-30">
            <Col sm={5} className="mb-40">
              <div className="text-center btn-blue">
                <Button onClick={() => window.location.replace(`https://www.okoadviser.com/`)}>
                  Close
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  return (
    <>
      <div className={`justify-content-center d-flex ${!submittingResults ? 'mt-30' : 'mt-10'}`}>
        <StepsBar step={'step3'} location={'clients'}></StepsBar>
      </div>
      {!submittingResults ? renderTimeFrameQuestion() : renderLottie()}
      {showModal && renderModal()}
    </>
  );
}
