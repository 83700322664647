import { isEmpty } from 'lodash';
import React, { PureComponent, Fragment } from 'react';

import { toast } from 'react-toastify';
import { Col, Row, Button, Container } from 'reactstrap';

import { fileEnums } from '../../../../../_constants';
import { getClient } from '../../../../../service/ClientsService';
import FileService from '../../../../../service/FileService';
import { addClientPortfolioBulk } from '../../../../../service/PortfolioService';
// import {PageHeader, UploadSelectMethod, PortfolioCopyPaste} from '../../../../commons';
import { PageHeader, UploadSelectMethod } from '../../../../commons';

import PortfolioUpload from './PortfolioUpload';
import SuccessUpload from './SuccessUpload';

class PortfolioAdd extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      uploaderStep: 0,
      portfolio: {
        AdviserClientPortfolioItems: [],
        PortfolioName: '',
        clientCoreId: null,
        error: null,
        adviserClient: null,
      },
      loading: false,
      portfolioId: '',
    };
  }

  handleDropRejected() {
    this.setState(() => ({ portfolio: { error: fileEnums.WRONG_FORMAT } }));
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    this.setState((state) => ({ portfolio: { ...state.portfolio, clientCoreId: id } }));
    await getClient({ clientCoreId: id }).then((res) =>
      this.setState(() => ({ adviserClient: res.adviserClient })),
    );
  }

  async handleDrop(file) {
    if (file) {
      this.setState(() => ({ filename: file && file.path }));
      await FileService.parsePortfolioCsv(file, this.state.error).then((response) => {
        if (response.error) {
          return this.setState((state) => ({
            portfolio: { ...state.portfolio, error: response.error },
          }));
        }
        return this.setState((state) => ({
          portfolio: {
            ...state.portfolio,
            AdviserClientPortfolioItems: response.AdviserClientPortfolioItems,
            error: response.error,
          },
        }));
      });
    }
    const { portfolio } = this.state;
    if (portfolio && portfolio.error) {
      toast.error(portfolio.error);
    }
  }

  handleChange({ target: { id, value } }) {
    this.setState((state) => ({ portfolio: { ...state.portfolio, [id]: value } }));
  }

  handleChangeUploader(step) {
    this.setState((state) => ({ uploaderStep: step }));
  }

  handleSubmitPortfolio(e) {
    e.preventDefault();
    const id = this.props.router.match.params.id;
    const { portfolio, portfolioId } = this.state;
    if (!this.state.portfolio.error) {
      this.setState(() => ({ loading: true }));
      addClientPortfolioBulk(portfolio)
        .then((data) => {
          if (data.adviserClientPortfolios !== null) {
            const portfolioId = data.adviserClientPortfolios[0].clientPortfolioId;
            this.setState(() => ({ loading: false }));
            this.props.history.push(`/clients/${id}/portfolioUpload/review/${portfolioId}`);
          }
        })
        .catch((err) => {
          toast.error(err.message);
          this.setState(() => ({ loading: false }));
        });
    }
  }

  handleSubmitCopyPaste(AdviserClientPortfolioItems) {
    const { portfolio } = this.state;
    if (!portfolio.PortfolioName || isEmpty(AdviserClientPortfolioItems)) {
      return toast.error('Check portfolio');
    }
    portfolio.AdviserClientPortfolioItems = AdviserClientPortfolioItems;
    this.setState(() => ({ loading: true }));
    addClientPortfolioBulk(portfolio)
      .then(() => {
        this.setState(() => ({ uploaderStep: 2 }));
      })
      .catch((err) => {
        this.setState({ showMessage: true, message: err.message });
        toast.error(err.message);
      })
      .finally(() => this.setState(() => ({ loading: false })));
  }

  render() {
    const { uploaderStep, filename, portfolio, loading, adviserClient } = this.state;
    const { id } = this.props.match.params;

    return (
      <div className="slim-content" style={{ maxWidth: '900px' }}>
        <Container style={{ maxWidth: '900px' }}>
          {/* {uploaderStep === 0 && (
            <Fragment>
              <PageHeader title="Profile" subTitle="Add a new portfolio" />
              <UploadSelectMethod
                onClickCopyPaste={() => this.handleChangeUploader(3)}
                onClickCSV={() => this.handleChangeUploader(1)}
              />
              <Row className="justify-content-center">
                <Col sm={6} className="mb-2">
                  <Button
                    outline
                    className="btn-secondary mb-10"
                    disabled={this.state.loading}
                    onClick={() => window.history.back()}
                  >
                    Back to results
                  </Button>
                </Col>
              </Row>
            </Fragment>
          )} */}
          {uploaderStep === 0 && (
            <Fragment>
              <PageHeader title="Upload a portfolio" />
              <PortfolioUpload
                onDrop={(files) => this.handleDrop(files[0])}
                onDropRejected={(e) => this.handleDropRejected(e)}
                onBack={() => window.history.back()}
                onSubmit={(e) => this.handleSubmitPortfolio(e)}
                onChange={(e) => this.handleChange(e)}
                {...{ filename, portfolio, loading }}
              />
            </Fragment>
          )}
          {/* {uploaderStep === 3 && (
                        <Fragment>
                            <PageHeader title="Add a new portfolio" subTitle="Copy / paste from file"/>
                            // <PortfolioCopyPaste
                           ={() => this.handleChangeUploader(0)}
                                onSubmit={data => this.handleSubmitCopyPaste(data)}
                                onChange={e => this.handleChange(e)}
                                {...{portfolio, loading}}
                            />
                        </Fragment>
                    )} */}
          {uploaderStep === 1 && (
            <SuccessUpload
              onSubmit={() => this.props.history.push(`/clients/${id}/results`)}
              clientName={`${adviserClient.clientFirstName} ${adviserClient.clientLastName} `}
              onBack={() => this.props.history.push('/clients')}
            />
          )}
        </Container>
      </div>
    );
  }
}

export default PortfolioAdd;
