import { ToastContainer } from 'react-toastify';

import { FilterContextProvider } from 'store/FiltersContext';
import { ProfileContextProvider } from 'store/ProfileContext';

import Main from './Main';

const App = () => {
  return (
    <ProfileContextProvider>
      <FilterContextProvider>
        <Main />
        <ToastContainer />
      </FilterContextProvider>
    </ProfileContextProvider>
  );
};

export default App;
