import React, { PureComponent, useEffect } from 'react';

import { toast } from 'react-toastify';
import { Row, Col, Button } from 'reactstrap';

import { saveAs } from 'file-saver';
import update from 'immutability-helper';

import eye from '../../../../../../_assets/img/advice/eye-solid.png';
import { completeAdviceOutput } from '../../../../../../service/AdvisorOutputService';
import {
  createAdviceOutputV2,
  getAdviceOutputV2,
} from '../../../../../../service/AdvisorOutputV2Service';
import { ButtonLoading, LoadingPage } from '../../../../../commons';

import ClientAdviserAction from '../ClientAdviserActions';
import DateTime from '../DateTime';
import { PortfolioService } from 'services/PortfolioService';
import { ProfilingService } from 'services/ProfilingService';
import Printer from '_components/app/Printer';
import { IProfileDto } from 'interfaces/responses/profiling/IProfileDto';
import Values from './Values';
import Soft from './Soft';
import PortfolioBreakdown from './PortfolioBreakdown';
import PortfolioExposure from './PortfolioExposure';
import { getResultsSummary } from 'service/resultsService';
import ExposureBreakdown from './ExposureBreakdown';
import Comparison from './Comparison';
import AdviceOutputActions from './AdviceOutputAction';
import { ExposureReport, PrintIcon } from './PrintIcon';


// type ActionStatus = 'add' | 'retain' | 'remove' | 'review';

const monthNames = { 0: 'January', 1: 'February', 2: 'March', 3: 'April', 4: 'May', 5: 'June', 6: 'July', 7: 'August', 8: 'September', 9: 'October', 10: 'November', 11: 'December' };
const getMonthNames = (month) => monthNames[month]
const formatDate = (date) => `${getMonthNames(date.getMonth())} ${date.getDay()} ${date.getFullYear()}`

export default class AdviceOutputV2 extends PureComponent {
  constructor(props) {
    super(props);
    /** @type {import('_components/clients/ClientDetails/Results/Results').IResultsSummaryResponse} */
    const resultsSummary = {};
    /** @type {IProfileDto} */
    const profile = {}
    /**
     * @type {'add' | 'retain' | 'remove' | 'review' | undefined}
     */
    const action = undefined;
    this.state = {
      data: undefined,
      meetingDate: '',
      meetingTime: '',
      isAm: true,
      meetingNote: '',
      loading: false,
      loadingButton: false,
      isEditable: false,
      portfolioUpdatedWhen: '',
      portfolioName: '',
      profileCompletedDate: '',
      profile,
      resultsSummary,
      action: action,
    };
  }
  async getCurrentPortfolio() {
    const service = PortfolioService.getInstance();
    const { id } = this.props.match.params;
    const response = await service.getAllClientPortfolios(id);
    if (!response.adviserClientPortfolioWithDetails) {
      return;
    }
    const portfolio = response.adviserClientPortfolioWithDetails.find((portfolio) => portfolio.isSelected);
    return portfolio;
  }

  async componentDidMount() {
    this.setState(() => ({ loading: true }));
    const { id, outputid } = this.props.match.params;
    if (!outputid) {
      await createAdviceOutputV2(id)
        .then((res) => {
          this.fetchData(res.outputId);
        })
        .catch((err) => {
          toast.error(err.message);
          this.setState({ showMessage: true, message: err.message, loading: false });
        });
    } else {
      this.fetchData(outputid);
    }
    // PortfolioService.getInstance().getClientPortfolio(id).then((res) => {
    //   console.log(res, 'response');
    //   this.setState({ portfolioUpdatedWhen: res.portfolio.updatedWhen, portfolioName: res.portfolio.portfolioName });
    // });
    const portfolio = await this.getCurrentPortfolio();
    this.setState({ portfolioUpdatedWhen: formatDate(new Date(portfolio.updatedWhen)), portfolioName: portfolio.portfolioName });
    ProfilingService.getInstance().getActiveProfileByClientId(id).then((res) => {
      this.setState({ profileCompletedDate: formatDate(new Date(res.profileDateCreated)) });
      this.setState({ profile: res });
    });
    getResultsSummary(id)
      .then((response) => {
        this.setState({ resultsSummary: response });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  fetchData(outputId) {
    getAdviceOutputV2(outputId)
      .then((res) => {
        console.log(res, 'response');
        this.setState({
          data: res,
          meetingNote: res.meetingNote,
          isEditable: res.isEditable,
          loading: false,
        });
        this.setDateTime(res);
      })
      .catch((err) => {
        toast.error(err.message);
        this.setState({ showMessage: true, message: err.message, loading: false });
      });
  }

  setDateTime(res) {
    if (!res.meetingDateTime) {
      return;
    }
    const dateTime = new Date(res.meetingDateTime);
    let month = this.convertToDoubleDigit(dateTime.getMonth() + 1);
    let date = this.convertToDoubleDigit(dateTime.getDate());
    this.setState({ meetingDate: date + '/' + month + '/' + dateTime.getFullYear() });
    let hours = dateTime.getHours();
    let minutes = this.convertToDoubleDigit(dateTime.getMinutes());
    if (hours >= 12) {
      hours = hours - 12;
      this.setState({ isAm: false });
    } else {
      this.setState({ isAm: true });
    }
    hours = this.convertToDoubleDigit(hours);
    this.setState({ meetingTime: `${hours}:${minutes}` });
  }

  convertToDoubleDigit(value) {
    if (value < 10) {
      value = `0${value}`;
    }
    return value;
  }

  handleActionsChange = ({ target: { name, value } }) => {
    let index = this.state.data.adviceOutputActions.findIndex(function (c) {
      return c.adviceOutputActionId === name;
    });
    let newState = update(this.state, {
      data: {
        adviceOutputActions: {
          [index]: {
            actionStatus: { $set: parseInt(value) },
          },
        },
      },
    });
    this.setState(newState, () => console.log(this.state.data.adviceOutputActions));
  };

  handleChange = ({ target: { id, value } }) => {
    this.setState({ [id]: value });
  };

  handleAmPmClick = ({ target: { id, value } }) => {
    id === 'am' ? this.setState({ isAm: true }) : this.setState({ isAm: false });
  };

  handleSaveClick = (e) => {
    e.preventDefault();
    const { meetingDate, meetingTime, isAm, data, meetingNote } = this.state;
    if (!meetingDate || !meetingTime) {
      return toast.error('Please enter date and time.');
    }
    let timeIn24HoursFormat = meetingTime;
    if (!isAm) {
      const timeArray = meetingTime.split(':');
      timeIn24HoursFormat = Number.parseInt(timeArray[0]) + 12 + ':' + timeArray[1];
    }

    const meetingDateTime = `${meetingDate
      .split('/')
      .reverse()
      .join('-')}T${timeIn24HoursFormat}:00`;

    const request = {
      meetingDateTime: meetingDateTime,
      adviceOutputActionItems: data.adviceOutputActions,
      outputId: data.outputId,
      meetingNote: meetingNote ?? '',
    };
    this.setState({ loadingButton: true }, () => {
      this.saveAdviceOutput(request);
    });
  };

  saveAdviceOutput(request) {
    const { id } = this.props.match.params;
    const { outputId } = request.outputId;
    completeAdviceOutput(request)
      .then((response) => {
        toast.info('Successful update');
        this.fetchData(request.outputId);
      })
      .catch((err) => {
        toast.error(err.message);
        this.setState({ showMessage: true, message: err.message, loading: false });
      })
      .finally(() => this.setState(() => ({ loadingButton: false, loading: false })));
  }

  render() {
    const { loading } = this.state;
    return <>{loading ? <LoadingPage /> : this.renderContent()}</>;
  }

  renderContent() {
    const { meetingDate, meetingTime, isAm, portfolioUpdatedWhen, portfolioName, profileCompletedDate, data, meetingNote, loadingButton, isEditable } =
      this.state;
    if (data) {
      return (
        <>
          <div style={{ padding: '15px', }} >
            <Button
              style={{ marginLeft: '18px' }}
              color="link"
              onClick={() => { window.location.href = '/clients'; }}
              className="font-14 text-darkblue pt-3 pb-3 pl-2 no-underline"
            >
              {'< Back to client list'}
            </Button>
            <Printer style={{ right: "27px" }} text='Print this page'>
              <div style={{ padding: '10px' }}>
                <div style={{ padding: '15px' }}></div>
                <Row style={{
                  marginRight: '18px',
                  marginLeft: '18px',
                }} >
                  <h2 style={{ fontSize: '32px' }}>Sustainability review output report</h2>
                  <div style={{ marginTop: '55px' }} className={isEditable ? 'd-none' : ''}>
                    <div
                      style={{ backgroundColor: '#F8F8F8', borderRadius: '3px' }}
                      className="ml-10 pl-2 pr-2"
                    >
                      <span className="text-link-color">View only mode&nbsp;</span>
                      <img src={eye} alt="view only mode" width="20" />
                    </div>
                  </div>
                </Row>
                <div style={{ padding: '15px' }}></div>
                <div style={{
                  borderRadius: '10px',
                  boxShadow: '0px 0px 10px 0px #0000001a',
                  padding: '30px',
                  marginRight: '18px',
                  marginLeft: '18px',
                }}>
                  <p>
                    The following output is based on:<br></br>{data.clientFirstName}'s sustainable profile completed on {profileCompletedDate}, analysed against {portfolioName} uploaded on {portfolioUpdatedWhen}. <a href={`/clients/${this.props.match.params.id}/portfolios`}>change portfolio</a>
                  </p>
                  <p>The Output has been created :</p>
                  <DateTime
                    handleChange={this.handleChange}
                    handleAmPmClick={this.handleAmPmClick}
                    meetingDate={meetingDate}
                    meetingTime={meetingTime}
                    isAm={isAm}
                    isEditable={isEditable}
                  />
                  <div style={{ fontSize: '12px', fontStyle: 'italic', fontWeight: 'bold', color: '#2e3074' }}>
                    Disclaimer: Oko Adviser does not guarantee the accuracy of any information provided and does not exert any editorial control over third party service provider information. In some instances content has been generated by an artificial intelligence language model and while we strive for accuracy and quality, please note that the information provided may not be entirely error-free or up-to-date. We recommend independently verifying the content and consulting with professionals for specific advice or information. We do not assume any responsibility or liability for the use or interpretation of this content. If you think  this AI response inaccurate or missing information, please email us here.  Oko Adviser does not guarantee the timeliness, sequence, accuracy or completeness of any information provided. This information is for information only and does not constitute advice.
                  </div>
                </div>
                <div style={{ marginLeft: '18px', marginRight: '18px' }}>
                  <div className='box-advice'>
                    <Values { ...data } profileDateCreated={profileCompletedDate} />
                    <Soft {...data} />
                  </div>
                  <div className="mb-20">
                    <PortfolioBreakdown {...data} />
                    <PortfolioExposure {...data} resultsSummary= {this.state.resultsSummary} />
                    <ExposureBreakdown resultsSummary={this.state.resultsSummary} />
                    <Comparison />
                    {/* <AdviceOutputActions /> */}
                    <ClientAdviserAction
                      adviceOutputActions={data.adviceOutputActions}
                      handleActionsChange={this.handleActionsChange}
                      isEditable={isEditable}
                    />
                    <div className='box-advice'>
                      <div className="mt-40">
                        Adviser notes
                      </div>
                      <Row>
                        <Col sm={12} style={{ padding: '0' }}>
                          <textarea
                            id="meetingNote"
                            name="meetingNote"
                            className="dasshed-box w-100 mt-20"
                            onChange={this.handleChange}
                            value={meetingNote}
                            disabled={!isEditable}
                            style={{ height: '300px' }}
                          />
                        </Col>
                      </Row>
                    </div>
                    <Row className="mt-40">
                      <Col md={12} className="d-flex justify-content-center mb-20">
                        <ButtonLoading
                          label={isEditable ? 'Save' : 'Saved'}
                          disabled={!isEditable}
                          className="btn-main-shadowless"
                          loading={loadingButton}
                          onClick={this.handleSaveClick}
                        />
                      </Col>
                    </Row>
                    <p className="text-darkblue font-12 text-center pb-2">Save to download Outputs</p>
                  </div>
                  <div style={{ display: 'flex', gap: '15px' }} >
                    <Button
                      style={{ borderRadius: '28px' }}
                      className="btn-main-shadowless"
                      // disabled={isEditable}
                      onClick={() => {
                        window.open(data.emailDocumentUrl, '_blank', 'noopener,noreferrer');
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
                        <PrintIcon />
                        <div className='text'>Client email</div>
                      </div>
                    </Button>
                    <Button
                      style={{ borderRadius: '28px' }}
                      className="btn-main-shadowless"
                      // disabled={isEditable}
                      onClick={() => {
                        window.open(data.meetingNotesDocumentUrl, '_blank', 'noopener,noreferrer');
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
                        <PrintIcon />
                        <div className='text'>Meeting Note</div>
                      </div>
                    </Button>
                    <Button
                      style={{ borderRadius: '28px' }}
                      className="btn-main-shadowless"
                      // disabled={isEditable}
                      onClick={() => {
                        window.open(data.adviceDocumentUrl, '_blank', 'noopener,noreferrer');
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
                        <PrintIcon />
                        <div className='text'>Advice Doc input</div>
                      </div>
                    </Button>
                    <Button
                      style={{ borderRadius: '28px' }}
                      className="btn-main-shadowless"
                      // disabled={isEditable}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
                        <ExposureReport />
                        <div className='text'>Exposure report</div>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            </Printer>
          </div>
        </>
      );
    }
  }
}
