import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';

const Root = ({ comp: Comp }) => (
  <Fragment>
    <Comp />
  </Fragment>
);

Root.propTypes = {
  comp: PropTypes.oneOfType([PropTypes.instanceOf(Component), PropTypes.func]).isRequired,
};

export default Root;
