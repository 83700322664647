/* eslint-disable prefer-spread */
import { useState } from 'react';

/* eslint-enable prettier/prettier */
import { Bar } from 'react-chartjs-2';
import { Row } from 'reactstrap';

import { Chart } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);
interface InsightsPropertiesState {
  title: string;
  isLoading: boolean;
  graphItems: InsightsBarGraphState[];
  note: string;
  barColor: string;
  labelColor: string;
}

type InsightsBarGraphState = {
  title: string;
  total: number;
};

export default function InsightsBarGraph(props: InsightsPropertiesState) {
  const [startIndex, setStartIndex] = useState(0);
  const graphData = props.graphItems.slice(startIndex, startIndex + 7);
  const MaxData = Math.max.apply(
    Math,
    props.graphItems.map(function (o) {
      return o.total;
    }),
  );
  const data = {
    labels: graphData.map((item) => item.title.split(' ')),
    datasets: [
      {
        data: graphData.map((item) => item.total),
        backgroundColor: props.barColor,
        barPercentage: 0.6,
      },
    ],
  };
  const decrease = () => {
    const index = startIndex - 1;
    if (index >= 0) {
      setStartIndex(index);
    }
  };
  const increase = () => {
    const index = startIndex + 1;
    if (index + 7 <= props.graphItems.length) {
      setStartIndex(index);
    }
  };

  return (
    <div className="barchart-panel">
      <Row style={{ marginLeft: 31, marginBottom: 9, marginTop: 14 }}>
        <h4 className="barchart-title">{props.title}</h4>
        <div className="barchart-buttons">
          <button
            onClick={decrease}
            className={`barchart-buttons-left ${startIndex === 0 && 'disabled'}`}
          >
            &lsaquo;
          </button>
          <button
            onClick={increase}
            className={`barchart-buttons-right ${
              startIndex + 7 >= props.graphItems.length && 'disabled'
            }`}
          >
            &rsaquo;
          </button>
        </div>
      </Row>
      <Row style={{ marginLeft: 32, marginRight: 22 }}>
        <Bar
          options={{
            plugins: {
              legend: { display: false, position: 'right' },
              tooltip: {
                enabled: false,
              },
              datalabels: {
                display: true,
                color: props.labelColor,
                font: {
                  size: 13,
                },
              },
            } as any,
            scales: {
              yAxes: {
                display: true,
                ticks: {
                  display: false,
                  maxTicksLimit: Math.ceil(MaxData / 10) * 10,
                },
                grid: {
                  display: true,
                },
                // gridLines: {
                //   display: true,
                //   drawBorder: false,
                // },
              },
              xAxes: {
                grid: {
                  display: false,
                },
              },
            },
          }}
          data={data}
        />
      </Row>
      <Row style={{ marginLeft: 32, marginTop: 10, padding: 10 }}>
        <p style={{ fontWeight: '300', fontSize: 10 }}>
          <b style={{ fontWeight: '900', fontSize: 10 }}>Note:</b> {props.note}
        </p>
      </Row>
    </div>
  );
}
