import React, { PureComponent } from 'react';

import { toast } from 'react-toastify';
import { Button, Form, Row, FormText, Container, Col } from 'reactstrap';

import { forgetPassword } from 'service/PasswordService';

import ValidatorService from '../../service/validator';
import { ButtonLoading, PageHeader, TextField } from '../commons';

class ForgotPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { email: '' };
  }

  handleSubmit(e) {
    e.preventDefault();
    const forgotPasswordParams = {
      email: this.state.email,
    };
    forgetPassword(forgotPasswordParams).then((data) => {
      if (data.httpStatusCode === 200) {
        toast.info(data.message);
        this.props.history.push('/resetPassword');
      } else {
        toast.error(data.message);
      }
    });
  }

  handleChange({ target: { id, value } }) {
    this.setState(() => ({ [id]: value }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.saving && !this.props.saving) {
      if (!this.props.error) {
        this.props.history.push('/resetPassword');
      } else {
        toast.error(this.props.error);
      }
    }
  }

  render() {
    const { email } = this.state;
    const { saving } = this.props;
    return (
      <Container className="custom-color-labels">
        <PageHeader title="Forgot password" className="mb-30 mt-20 text-center" center />
        <Form onSubmit={(e) => this.handleSubmit(e)}>
          <div className="row-steps">
            <TextField
              validateInput={() => ValidatorService.validateEmail(email)}
              onChange={(e) => this.handleChange(e)}
              control="email"
              value={email}
              label="Email address"
              type="email"
              required
            />
            <Row className="mb-40 mt-50 d-flex justify-content-center align-items-center">
              <Col sm={{ size: 12 }} className="d-flex justify-content-center align-items-center">
                <ButtonLoading
                  className="btn-main-shadowless mb-40 btn-middle mt-20"
                  label="Reset password"
                  loading={saving}
                />
              </Col>
            </Row>
            <Row className="justify-content-center mb-40">
              <Button
                color="link"
                onClick={() => this.props.history.push('/')}
                style={{ color: '#3E61DF' }}
              >
                Sign in
              </Button>
            </Row>
            <Row className="justify-content-center mt-50">
              <FormText>
                Don't have an account?&nbsp;
                <Button
                  color="link"
                  onClick={() => this.props.history.push('/register')}
                  style={{ color: '#3E61DF', verticalAlign: 'bottom', fontSize: '16px' }}
                >
                  Sign up
                </Button>
              </FormText>
            </Row>
          </div>
        </Form>
      </Container>
    );
  }
}

export default ForgotPassword;
