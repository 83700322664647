import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Row, Col, Container, Button } from 'reactstrap';

import Printer from '_components/app/Printer';
import api from 'config/api';
import {
  getCompare,
  deleteAllInstrument as deleteAllCompareInstruments,
} from 'service/CompareServices';
import { ProfileContext } from 'store/ProfileContext';

import unsureIcon from '../../../_assets/img/icons/coDetails/Group 2@4x.png';
import conflictsIcon from '../../../_assets/img/icons/coDetails/conflict/focus@2x.png';
import checkIcon from '../../../_assets/img/icons/profile/Complete@3x.png';
import { LoadingPage } from '../../commons';

import './Comparison.scss';
import ComparisonOverview from './ComparisonOverview';
import InstrumentCompare from './InstrumentCompare';

export default function Comparison(props) {
  const [showInvolvement, setShowInvolvement] = useState(true);
  const [instrumentData, setInstrumentData] = useState([]);

  const [portfolioScores, setPortfolioScores] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [removeInstuments, setRemoveInstruments] = useState(false);
  const [involvements, setInvolvements] = useState([]);
  const history = useHistory();

  const id = props.router.match.params.id;

  useEffect(() => {
    getCompare(props.router.match.params.id)
      .then((response) => {
        setPortfolioScores(response);
        setInstrumentData(response.instruments);
        setInvolvements(involvements);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);

  const handleRemove = () => {
    setShowModal(true);
  };

  const handleRemoveAll = () => {
    const id = props.router.match.params.id;
    deleteAllCompareInstruments(id)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err.message);
      });

    setShowModal(false);
    setRemoveInstruments(true);
  };

  const getStatus = (involvement) => {
    if (!involvement) return '-';
    else if (involvement === 1) return <img src={checkIcon} width="20px" height="20px" />;
    else if (involvement === 2) return <img src={conflictsIcon} width="20px" height="20px" />;
    else if (involvement === 3) return <img src={unsureIcon} width="20px" height="20px" />;
  };
  if (loading) {
    return <LoadingPage />;
  }

  const removeAllModal = () => {
    return (
      <div className="overlay">
        <Container
          className="showup-modal"
          style={{
            width: '225px',
            height: '160px',
            marginTop: '300px',
            padding: '0',
            position: 'relative',
          }}
        >
          <Row className="text-center justify-content-center remove-box-top">
            <p style={{ color: '#fff', fontSize: '15px' }}>Clear All</p>
          </Row>
          <Row>
            <p className="text-center remove-box-text">{`Are you sure you want to remove all comparisons?`}</p>
          </Row>
          <Row className="justify-content-center mt-10">
            <p className="remove-box-cancel" onClick={() => setShowModal(false)}>
              Cancel
            </p>
            <div className="remove-box-button" onClick={() => handleRemoveAll()}>
              {`Remove`}
            </div>
          </Row>
        </Container>
      </div>
    );
  };

  return (
    <div>
      <Printer
        style={{
          right: '0px',
          top: '30px',
        }}
      >
        <div>
          <div style={{ position: 'relative', right: '10px' }}>
            <h3 className="p-4 text-center">Investments Comparison</h3>
            {instrumentData && !removeInstuments && (
              <span className="clear-all-text" onClick={() => handleRemove()}>
                Clear All
              </span>
            )}
          </div>
          <Row className="detail">
            <ComparisonOverview
              showInvolvement={showInvolvement}
              setShowInvolvement={setShowInvolvement}
              portfolioScores={portfolioScores}
              getStatus={getStatus}
              id={id}
            />
            {removeInstuments === false && (
              <InstrumentCompare
                instrumentData={instrumentData}
                involvements={involvements}
                id={id}
              />
            )}
          </Row>
        </div>
      </Printer>
      {showModal && removeAllModal()}
    </div>
  );
}
