import React from 'react';
import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container, Row, Button } from 'reactstrap';

import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  rectIntersection,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import { LoadingPage } from '_components/commons';

import { getLifestyleDetails } from '../../../service/ProfileService';

import tipIcon from './../../../_assets/img/icons/global/tip.png';
import bookmark from './../../../_assets/img/icons/profileIntro/bookmark@3x.png';
import LifestyleCardContainer from './components/LifestyleCardContainer';
import { Item } from './components/LifestyleSortableItem';
import './styles.scss';

export default function LifeStyles() {
  const history = useHistory();

  const [lifestyles, setLifestyles] = useState({
    lifestyleCard: [],
    thatsMe: [],
    notMe: [],
    unsure: [],
  });

  const [activeId, setActiveId] = useState();
  const [showModal, setShowModal] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleButtonShow = () => {
    if (lifestyles.thatsMe.length !== 0 && lifestyles.notMe.length !== 0) {
      setShowModal(true);
      localStorage.setItem('lifestyles', JSON.stringify(lifestyles));
    } else {
      return toast.error('Please drag at least one to Thats Me and Thats not me');
    }
  };

  function findContainer(id) {
    if (id in lifestyles) {
      return id;
    }
    const container = Object.keys(lifestyles).find((key) =>
      lifestyles[key].some((obj) => obj.id === id),
    );
    return container;
  }

  function handleDragOver(event) {
    const { active, over, draggingRect } = event;
    const { id } = active;
    const { id: overId } = over || {};

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return;
    }

    setLifestyles((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      //   Find the indexes for the items
      const activeObj = activeItems.find((obj) => obj.id === id);
      const activeIndex = activeItems.indexOf(activeObj);
      const overObj = overItems.find((obj) => obj.id === overId);
      const overIndex = overItems.indexOf(overObj);
      // console.log(activeObj?.question, ',', overObj?.question);

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          draggingRect.offsetTop > over.rect.offsetTop + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [...prev[activeContainer].filter((item) => item.id !== active.id)],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          lifestyles[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over || {};
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (!activeContainer || !overContainer || activeContainer !== overContainer) {
      return;
    }

    const activeIndex = lifestyles[activeContainer].findIndex((item) => item.id === active.id);
    const overIndex = lifestyles[overContainer].findIndex((item) => item.id === over.id);

    if (active.id !== over.id) {
      setLifestyles((items) => ({
        ...items,
        [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
      }));
    }

    setActiveId(null);
  }

  useEffect(() => {
    getLifestyleDetails()
      .then((response) => {
        const lifestyleDetails = response.lifestyleQuestions;
        setLifestyles((prev) => ({
          ...prev,
          lifestyleCard: lifestyleDetails,
        }));
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);

  const sustainabilityModal = () => {
    return (
      <>
        <Container className="profile-feature">
          <Row className="mt-5 justify-content-center">
            <p className="text-center profile-header">
              Start by sharing a little about you and your habits
            </p>
          </Row>
          <div
            style={{ marginTop: '42px' }}
            className="d-flex justify-content-center overflow-hidden"
          >
            <div
              style={{ gap: '12px', paddingLeft: '10px', paddingRight: '10px' }}
              className="d-flex"
            >
              <div className="draggable-area-grid">
                <p className="category-title lifestyle-tips-panel-header">
                  Habit and <br></br>
                  <span className="category-title" style={{ whiteSpace: 'nowrap' }}>
                    lifestyle &rarr;
                  </span>
                </p>
                <p className="category-title">Habit and lifestyle</p>
                <p className="category-title">{`That's me`}</p>
                <p className="category-title">{`That's not me`}</p>
                <p className="category-title">Not sure</p>

                <p className="category-subtitle">Issues for you to drag and drop.</p>
                <p className="category-subtitle">Things you do, or would like to do.</p>
                <p className="category-subtitle">{`Things that you just don't do.`}</p>
                <p className="category-subtitle">Things that are not on your radar.</p>
                <div style={{ gap: '40px' }} className="d-flex flex-column">
                  <Container style={{ marginTop: '1rem' }} className="tips-panel">
                    <div className="tips-icon">
                      <img src={tipIcon} />
                    </div>
                    <div className="tips-text">
                      <p className="category-subtitle">
                        Drag and drop <b style={{ color: '#3e61DF' }}>at least</b> one item into the
                        column that is most appropriate.
                      </p>
                      {/* <button className="tips-button">Start here</button> */}
                    </div>
                  </Container>
                  <Row className="justify-content-center">
                    <Button
                      onClick={() => handleButtonShow()}
                      className="btn-main-shadowless confirm-button"
                    >
                      Done
                    </Button>
                  </Row>
                </div>
                <DndContext
                  sensors={sensors}
                  collisionDetection={rectIntersection}
                  onDragEnd={handleDragEnd}
                  onDragOver={handleDragOver}
                >
                  <LifestyleCardContainer
                    className="causes"
                    id="lifestyleCard"
                    items={lifestyles.lifestyleCard}
                  />

                  <LifestyleCardContainer
                    className="support"
                    id="thatsMe"
                    items={lifestyles.thatsMe}
                  />

                  <LifestyleCardContainer className="avoid" id="notMe" items={lifestyles.notMe} />
                  <LifestyleCardContainer
                    className="unsure"
                    id="unsure"
                    items={lifestyles.unsure}
                  />
                </DndContext>
              </div>
              <DragOverlay>
                {activeId ? <Item id={activeId} item={lifestyles[activeId]} /> : null}
              </DragOverlay>
            </div>
          </div>
        </Container>
      </>
    );
  };

  const loadingModal = () => {
    return <LoadingPage />;
  };

  const confirmationModal = () => {
    return (
      <div className="overlay">
        <Container className="showup-modal">
          <Row className="mt-10 mb-10 justify-content-center">
            <img src={bookmark} width="150" />
          </Row>
          <Row className="text-center justify-content-center">
            <div className="header">
              <p>{`Nice work!`}</p>
            </div>
          </Row>
          <Row className="mt-20">
            <p className="d-block text-center w-100 comment">{`Now let's understand your Responsible Investing causes.`}</p>
          </Row>
          <Row className="justify-content-center mt-10">
            <Col sm={5} className="mb-40">
              <div className="text-center">
                <Button
                  className="btn-blue btn-main-shadowless"
                  onClick={() => {
                    history.push('causes');
                  }}
                >
                  {`Let's go`} &rarr;
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };
  return (
    <>
      {lifestyles.lifestyleCard.length !== 0 ||
      lifestyles.thatsMe.length !== 0 ||
      lifestyles.notMe.length !== 0 ||
      lifestyles.unsure.length !== 0
        ? sustainabilityModal()
        : loadingModal()}
      {showModal && confirmationModal()}
    </>
  );
}
