import { useState } from 'react';

// import { Tooltip } from 'react-tooltip';
import { Row, Col, Collapse } from 'reactstrap';

import chevronDown from '../../../../_assets/img/icons/global/chevron/down@3x.png';
import chevronUp from '../../../../_assets/img/icons/global/chevron/up@3x.png';

import ExposureDetails from './ExposureDetails';
import { IResultsSummaryResponse, IMatchConflict, IMatchConflictInstrument } from './Results';
import './exposureChart.scss';

interface IExposureChart {
  resultsSummary: IResultsSummaryResponse;
}

interface IHoldingData {
  symbol: string;
  exposure: string;
  numberOfHoldings: number;
}
interface IExposureTable {
  matchConflict: IMatchConflict;
  data: IMatchConflictInstrument;
  isAvoid: boolean;
  toggleDropdown: (rowIndex: number) => void;
  holdingTableData: IHoldingData[];
}

const ExposureChart = ({ resultsSummary }: IExposureChart) => {
  const [openRow, setOpenRow] = useState<number | null>(null);

  const toggleDropdown = (rowIndex: number) => {
    if (openRow === rowIndex) {
      setOpenRow(null);
    } else {
      setOpenRow(rowIndex);
    }
  };

  const ExposureTable = ({
    matchConflict,
    data,
    holdingTableData,
    isAvoid,
    toggleDropdown,
    rowIndex,
  }: IExposureTable & { rowIndex: number }) => (
    <>
      <Row className="exposure-row">
        <Col className="flex-12">
          <img
            src={matchConflict.iconUrl}
            className="icon exposure"
            alt="icon"
            key={matchConflict.id}
            style={{ backgroundColor: isAvoid ? '#ffe5ea' : '#e5f9f6' }}
          />
          <div
            style={{
              maxWidth: '270px',
              paddingRight: '5px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            title={matchConflict.questionDesc}
          >
            {matchConflict.questionDesc}
          </div>
        </Col>

        <Col className="flex-6 profile-selection">
          <div
            style={{
              backgroundColor: isAvoid ? '#bd5f80' : '#7BE0D3',
              color: isAvoid ? 'white' : '#313132',
            }}
          >
            {isAvoid ? '"Avoid"' : '"Support"'}
          </div>
        </Col>
        <Col className="flex-4">Total</Col>
        <Col className="flex-4 exposure-holdings">
          <div>{data.exposure}</div>
        </Col>
        <Col className="flex-4 exposure-holdings">
          <div>{data.numberOfHoldings}</div>
        </Col>
        <Col className="flex-2" onClick={() => toggleDropdown(rowIndex)}>
          {openRow === rowIndex ? (
            <img src={chevronUp} alt="up" width="20" />
          ) : (
            <img src={chevronDown} alt="down" width="20" />
          )}
        </Col>
      </Row>

      <Collapse isOpen={openRow === rowIndex}>
        {data.instruments.map((item, rowIndex) => {
          // const parentInstrumentNames = item.toolTipInstrumentNames.join(' | ');
          return (
            <Row
              key={rowIndex}
              className="holding-row"
              // data-tooltip-id="global-tooltip"
              // data-tooltip-content={parentInstrumentNames}
            >
              <Col className="flex-8"></Col>

              <Col className="flex-24 holding-col">
                <Row
                  className={`holding-table ${rowIndex === 0 ? 'holding-table-top' : ''} ${
                    rowIndex === holdingTableData.length - 1 ? 'holding-table-bottom' : ''
                  }`}
                >
                  <Col className="flex-10 holding-name">
                    <div
                      className="holding-name-padding cursor-hand"
                      id={`exposure-details-${item.instrumentId}`}
                    >
                      {item.instrumentName}{' '}
                      <ExposureDetails id={`exposure-details-${item.instrumentId}`} item={item} />
                    </div>
                  </Col>

                  <Col className="flex-4">{item.symbol}</Col>
                  <Col className="flex-4">
                    <div>{item.exposure}</div>
                  </Col>
                  <Col className="flex-4">
                    <div>{item.numberOfHoldings}</div>
                  </Col>
                  <Col className="flex-2">
                    <div></div>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}
        {/* <Tooltip id={`global-tooltip`} /> */}
      </Collapse>
    </>
  );

  return (
    <div className="mt-30 exposure-table">
      {/* Header Row */}
      <Row className="exposure-row header">
        <Col className="flex-12" style={{ paddingLeft: '30px' }}>
          Category
        </Col>
        <Col className="flex-6">Profile Selection</Col>
        <Col className="flex-4">Symbol</Col>
        <Col className="flex-4">Exposure %</Col>
        <Col className="flex-4"># of holdings</Col>
        <Col className="flex-2"></Col>
      </Row>
      {resultsSummary?.results?.map((item: IMatchConflictInstrument, rowIndex: number) => (
        <ExposureTable
          key={rowIndex}
          matchConflict={item?.matchConflict}
          data={item}
          isAvoid={item?.matchConflict?.isAvoid}
          toggleDropdown={toggleDropdown}
          rowIndex={rowIndex}
          holdingTableData={item?.instruments}
        />
      ))}
    </div>
  );
};

export default ExposureChart;
