import IsNil from 'lodash';
import { useContext, useEffect } from 'react';

import { create } from 'apisauce';
import { LogOut } from 'store/ProfileContext';

import { getSessionToken } from '../service/LocalStorageService';

const { REACT_APP_API_URL } = process.env;

const api = create({
  baseURL: REACT_APP_API_URL,
  timeout: 55000,
});

export const isApiAuthenticated = () => {
  let session = getSessionToken();
  return session !== null;
};

export const apiSetup = (dispatch) => {
  let session = getSessionToken();
  if (session != null) {
    api.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      Authorization: 'Bearer ' + session,
      'Content-Type': 'application/json',
    });
  } else {
    api.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      'Content-Type': 'application/json',
    });
  }

  api.addMonitor((response) => {
    if (response.status === 401) {
      // dispatch(actions.sessionExpired());
      LogOut();
      // const ctx = useContext(ProfileContext);
      // ctx.logOut();
      console.warn('Unhandled session expiration');
    }
  });

  api.addMonitor((response) => {
    if (response.problem === 'NETWORK_ERROR') {
      // dispatch(actions.noInternetConnection());
      console.warn('Unhandled request without connection');
    }
  });
};

export default api;
