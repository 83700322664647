/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Fragment, useState, useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { Table } from 'reactstrap';

import Grade from '_components/commons/Grade';
import StatusPill from '_components/commons/StatusPill';
import { deleteCompareInstrument, addCompare, getCompareCount } from 'service/CompareServices';

import { redirectToInstrumentPage } from '../../../../_helpers/instrument';
import { InstrumentInterface } from '../../../../model/Instrument';
import '../../../research/company.scss';

import './Results.scss';

interface ResultsGridProps {
  results: InstrumentInterface[] | null | undefined;
}

export default function ResultsGrid({ results }: ResultsGridProps) {
  const history = useHistory();
  const { id } = useParams();
  const [compareInstrumentIds, setCompareInstrumentIds] = useState<string[]>([]);
  const compareCount = compareInstrumentIds.length;

  useEffect(() => {
    getCompareCount(id)
      .then((response: any) => {
        setCompareInstrumentIds(response.instrumentIds);
      })
      .catch((err) => {
        console.log(err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  const handleClick = (instrumentId: string) => {
    if (results == null) {
      return;
    }
    if (!compareInstrumentIds.includes(instrumentId)) {
      addCompare(instrumentId, id)
        .then((response: any) => {
          console.log('successfully added!');
          setCompareInstrumentIds(response.instrumentIds);
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      deleteCompareInstrument(instrumentId, id)
        .then((response: any) => {
          console.log('delete successful');
          setCompareInstrumentIds(response.instrumentIds);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  return (
    <>
      <br />
      <br />
      <Table borderless hover className="custom-table text-center">
        <thead>
          <tr>
            <th></th>
            <th style={{ position: 'relative', width: '260px' }}>{`Name`}</th>
            <th>{`Symbol`}</th>
            <th>{`Sector`}</th>
            <th>{`Type`}</th>
            <th style={{ width: '110px' }}>{`Grade`}</th>
            <th>{`Match`}</th>
            <th>
              {compareCount !== 0 ? (
                <span className="checkbox-count-result">{compareCount}</span>
              ) : null}
              {`Compare`}
            </th>
          </tr>
        </thead>
        <tbody>
          {results?.map((value) => {
            return (
              <tr key={value.id} onClick={redirectToInstrumentPage(value, history)}>
                <td className={value.isInApprovedProductList ? 'instrument-symbol' : ''}>
                  <img
                    className="company-icon"
                    src={value.logoUrl}
                    alt="logo"
                    width="42"
                    height="42"
                  />
                  <span className="">
                    {value.isInApprovedProductList ? (
                      <span className="apl-tag-results">APL</span>
                    ) : (
                      ''
                    )}
                  </span>
                </td>
                <td title={value.name} style={{ position: 'relative' }}>
                  {value.name}
                </td>
                <td>{value.symbol}</td>
                <td>{value.sector}</td>
                <td className={value.type.length === 3 ? 'uppercase' : 'capitalize'}>
                  {value.type}
                </td>
                <td>
                  <Grade className="grades-related-investment" grade={value.grade} />
                  {/* {value.overallPercentageValue === null ? (
                    ''
                  ) : (
                    <Gradek
                      className="grades-related-investment"
                    />
                  )} */}
                </td>
                <td>
                  <StatusPill matchToValuesStatus={value.matchToValuesStatus} />
                </td>

                <td style={{ height: '57px' }} onClick={(e) => e.stopPropagation()}>
                  <label className="form-control-checkbox">
                    <input
                      type="checkbox"
                      name="checkbox"
                      className="research-checkbox"
                      checked={compareInstrumentIds.includes(value.id) ? true : false}
                      onChange={() => handleClick(value.id)}
                    />
                  </label>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
