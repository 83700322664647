import { isEmpty } from 'lodash';
import React, { PureComponent, Fragment } from 'react';

import { toast } from 'react-toastify';
import { Container } from 'reactstrap';

import { fileEnums } from '../../../../_constants';
import { getClientSelected } from '../../../../service/ClientsService';
import FileService from '../../../../service/FileService';
import { PageHeader, PortfolioUpload, LoadingPage } from '../../../commons';

import SuccessUpload from './SuccessUpload';

class ClientUpload extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      portfolioName: '',
      filename: null,
      error: null,
      portfolios: [],
      uploading: false,
      upload: false,
      clientAdviser: null,
    };
  }

  componentDidMount() {
    this.setState(() => ({ clientAdviser: getClientSelected() }));
  }

  handleChange({ target: { id, value } }) {
    this.setState(() => ({ [id]: value }));
  }

  async handleDrop(file) {
    if (file) {
      this.setState(() => ({ filename: file && file.path }));
      await FileService.parsePortfolioCsv(file).then((portfolios) =>
        this.setState(() => ({ portfolios })),
      );
    }
    const { portfolios } = this.state;
    if (!isEmpty(portfolios) && portfolios.error) {
      toast.error(portfolios.error);
    }
  }

  handleDropRejected() {
    this.setState(() => ({ portfolios: { error: fileEnums.WRONG_FORMAT } }));
  }

  handleSubmit(e) {
    e.preventDefault();
    const { portfolios, filename } = this.state;
    if (!isEmpty(portfolios) && !portfolios.error) {
      FileService.savePortfolio(filename, portfolios);
      this.setState(() => ({ upload: true }));
    }
  }

  render() {
    const { upload, uploading, filename, portfolioName, portfolios, clientAdviser } = this.state;
    return (
      <Container>
        {uploading && <LoadingPage />}
        {!uploading && !upload && (
          <Fragment>
            <PageHeader title="Upload file" />
            <PortfolioUpload
              onDrop={(files) => this.handleDrop(files[0])}
              onDropRejected={(e) => this.handleDropRejected(e)}
              onBack={() => this.props.history.push('/clients/add')}
              onSubmit={(e) => this.handleSubmit(e)}
              onChange={(e) => this.handleChange(e)}
              portfolio={portfolios.error}
              {...{ filename, portfolioName }}
            />
          </Fragment>
        )}
        {!uploading && upload && (
          <SuccessUpload
            clientName={`${clientAdviser.clientFirstName} ${clientAdviser.clientLastName} `}
            onBack={() => this.props.history.push('/clients/add')}
            onSubmit={() => this.props.history.push('/clients/add')}
          />
        )}
      </Container>
    );
  }
}

export default ClientUpload;
