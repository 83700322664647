import { isEmpty } from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';

import { Row } from 'reactstrap';

import triangle from '../../_assets/img/icons/global/Triangle@3x.png';
import { getAllClients } from '../../service/ClientsService';
import { LoadingPage } from '../commons';

import ClientListShortcuts from './ClientListShortcuts';
import EmptyClientList from './EmptyClientList';
import Client from './Item';
import './client.scss';

export interface ClientInterface {
  adviserCoreId: string;
  clientCoreId: string;
  clientEmailAddress: string;
  clientFirstName: string;
  clientLastName: string;
  hasActiveProfile: boolean;
  isViewed: boolean;
  profileRunId: number;
  updatedWhen: string;
}

type FilterPopup = 'firstName' | 'lastName' | 'profileStatus' | 'profileAction' | 'profileView';

const ClientList = (props: any) => {
  const [list, setList] = useState<ClientInterface[] | null | undefined>([]);
  const [originalList, setOriginalList] = useState<ClientInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const [openFilterPopup, setOpenFilterPopup] = useState<FilterPopup>();
  let sortedItems: ClientInterface[] | null | undefined;

  useEffect(() => {
    getAllClients()
      .then((response) => {
        setList(response.adviserClients);
        setOriginalList([...response.adviserClients]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const sortName = (id: any, name: keyof ClientInterface) => {
    if (id === '0') {
      sortedItems = originalList?.sort((x: ClientInterface, y: ClientInterface) =>
        x[name] > y[name] ? 1 : -1,
      );
      setList(sortedItems);
    } else {
      sortedItems = originalList?.sort((x: ClientInterface, y: ClientInterface) =>
        y[name] > x[name] ? 1 : -1,
      );
      setList(sortedItems);
    }
  };

  const sortProfile = (id: any, profile: keyof ClientInterface) => {
    if (id === '0') {
      sortedItems = originalList?.filter((item) => item[profile] === true);
      setList(sortedItems);
    } else {
      sortedItems = originalList?.filter((item) => item[profile] === false);
      setList(sortedItems);
    }
  };

  const handleFilterPopupChange = (popupType: FilterPopup) => {
    setOpenFilterPopup((currentState) => (currentState === popupType ? undefined : popupType));
  };

  if (loading) {
    return <LoadingPage />;
  }
  const columnData = [
    {
      columnKey: 'firstName',
      columnName: 'First Name',
      sortKey: 'clientFirstName',
      sortLabels: {
        '0': (
          <span>
            {'A'}&nbsp;{'-'}&nbsp;{'Z'}
          </span>
        ),
        '1': (
          <span>
            {'Z'}&nbsp;{'-'}&nbsp;{'A'}
          </span>
        ),
      },
      columnWidth: 'width-20',
      sortFunction: sortName,
    },
    {
      columnKey: 'lastName',
      columnName: 'Last Name',
      sortKey: 'clientLastName',
      sortLabels: {
        '0': (
          <span>
            {'A'}&nbsp;{'-'}&nbsp;{'Z'}
          </span>
        ),
        '1': (
          <span>
            {'Z'}&nbsp;{'-'}&nbsp;{'A'}
          </span>
        ),
      },
      columnWidth: 'width-20',
      sortFunction: sortName,
    },
    {
      columnKey: 'profileStatus',
      columnName: 'Profile Status',
      sortKey: 'hasActiveProfile',
      sortLabels: {
        '0': 'Complete',
        '1': 'Not started',
      },
      columnWidth: 'width-15',
      sortFunction: sortProfile,
    },
    {
      columnKey: 'profileAction',
      columnName: 'Profile Action',
      sortKey: 'hasActiveProfile',
      sortLabels: {
        '0': 'Redo Profile',
        '1': 'Start Profile',
      },
      columnWidth: 'width-15',
      sortFunction: sortProfile,
    },
    {
      columnKey: 'profileView',
      columnName: 'Profile Viewed',
      sortKey: 'isViewed',
      sortLabels: {
        '0': 'Viewed',
        '1': 'Not viewed',
      },
      columnWidth: 'width-15',
      sortFunction: sortProfile,
    },
  ];

  const renderSortableColumn = (columnData: any) => {
    const { columnKey, columnName, sortKey, sortLabels, columnWidth, sortFunction } = columnData;

    return (
      <div
        className={`client-list ${columnWidth}`}
        onClick={() => handleFilterPopupChange(columnKey)}
      >
        {columnName}&nbsp;&nbsp;
        <span>
          <img
            src={triangle}
            alt="triangle"
            width="13"
            className={openFilterPopup === columnKey ? 'open' : ''}
          />
        </span>
        <div
          className={`dropdown-body name ${
            openFilterPopup === columnKey ? 'open' : ''
          } ${columnKey}`}
        >
          {Object.entries(sortLabels).map(([key, label]) => (
            <div
              className="dropdown-item"
              onClick={() => sortFunction(key, sortKey)}
              id={key}
              key={key}
            >
              {label as React.ReactNode}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div style={{ width: '90%', margin: '0 auto' }}>
      {isEmpty(originalList) && (
        <Row className="empty-client-list-heading">
          <h2>Welcome.</h2>
          <p className="empty-client-list-text">Get started by adding a client</p>
        </Row>
      )}

      {!isEmpty(originalList) ? (
        <Row className="mt-30 mb-10 client-heading-list">
          <h2 className="title">Your client list</h2>
          <div className="client-count list">
            <div className="client-count-result">{list?.length}</div>
          </div>
        </Row>
      ) : (
        <Row className="mb-10 client-list-heading">
          <p className="empty-client-list-text">No client added</p>
          <div className="client-count">
            <div className="client-count-result">{list && list.length}</div>
          </div>
        </Row>
      )}

      {!loading && (
        <Fragment>
          {!isEmpty(originalList) ? (
            <>
              <Row className="client-list-header grid mt-20 mb-60">
                {columnData.map((column) => (
                  <Fragment key={column.columnKey}>{renderSortableColumn(column)}</Fragment>
                ))}
                <div className="client-list edit">Edit</div>
              </Row>

              <Row>
                {list && list.map((client, idx) => <Client key={idx} client={client} {...props} />)}
              </Row>
              <ClientListShortcuts list={list} />
            </>
          ) : (
            <EmptyClientList />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default ClientList;
