import api, { apiSetup } from '../config/api';

export function getResultsCount(clientId: string, filters: string[]) {
  const data = {
    clientId,
    filters,
  };
  return new Promise((resolve: any, reject: any) => {
    api.setHeader('AuthorizationApp', `Bearer ${process.env.REACT_APP_AUTORIZATION_APP}`);
    apiSetup();
    api
      .post('/v2/results/count', data)
      .then((result) => {
        if (result.ok && result.status === 200) {
          resolve(result.data);
        } else {
          reject({ message: 'failed to retrieve result count' });
        }
      })
      .catch((error) => {
        reject({ message: error });
      });
  });
}

export function getResults(
  clientId: string,
  type: string,
  skip: number,
  filters: string[],
  sortBy: string,
) {
  return new Promise((resolve: any, reject: any) => {
    const data = {
      clientId,
      type,
      skip,
      take: 50,
      filters,
      sortBy,
    };
    api.setHeader('AuthorizationApp', `Bearer ${process.env.REACT_APP_AUTORIZATION_APP}`);

    apiSetup();
    api
      .post('/v2/results/', data)
      .then((result) => {
        if (result.ok && result.status === 200) {
          resolve(result.data);
        } else {
          reject({ message: 'failed to retrieve results' });
        }
      })
      .catch((error) => {
        reject({ message: error });
      });
  });
}

export function getResultsSummary(id: string) {
  const request = (resolve: any, reject: any) => {
    apiSetup();
    api
      .get(`/v2/results/summary/${id}`)
      .then((res) => {
        if (res.ok && res.status === 200 && res.status === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}
