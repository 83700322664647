/* eslint-disable prettier/prettier */
import React from 'react';
import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Col, Container, Row, Button } from 'reactstrap';

import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  rectIntersection,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import { LoadingPage } from '_components/commons';

import tipIcon from './../../../_assets/img/icons/global/tip.png';
import bookmark from './../../../_assets/img/icons/profileIntro/bookmark@3x.png';
import CardContainer from './components/CardContainer';
import { Item } from './components/SortableItem';
import './styles.scss';

export default function Prioritise() {
  const history = useHistory();

  const [behaviours, setBehaviours] = useState({ support: [], avoid: [], unsure: [] });

  const [activeId, setActiveId] = useState();
  const [showModal, setShowModal] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleButtonShow = () => {
    setShowModal(true);
    localStorage.setItem('behaviours', JSON.stringify(behaviours));
  };

  function findContainer(id) {
    if (id in behaviours) {
      return id;
    }
    return Object.keys(behaviours).find((key) => behaviours[key].some((obj) => obj.id === id));
  }

  function handleDragOver(event) {
    const { active, over, draggingRect } = event;
    const { id } = active;
    const { id: overId } = over || {};

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return;
    }

    setBehaviours((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      //   Find the indexes for the items
      const activeObj = activeItems.find((obj) => obj.id === id);
      const activeIndex = activeItems.indexOf(activeObj);
      const overObj = overItems.find((obj) => obj.id === overId);
      const overIndex = overItems.indexOf(overObj);

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          draggingRect.offsetTop > over.rect.offsetTop + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [...prev[activeContainer].filter((item) => item.id !== active.id)],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          behaviours[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over || {};
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (!activeContainer || !overContainer || activeContainer !== overContainer) {
      return;
    }

    const activeIndex = behaviours[activeContainer].findIndex((item) => item.id === active.id);
    const overIndex = behaviours[overContainer].findIndex((item) => item.id === over.id);

    if (active.id !== over.id) {
      setBehaviours((items) => ({
        ...items,
        [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
      }));
    }

    setActiveId(null);
  }

  useEffect(() => {
    const behaviours = JSON.parse(localStorage.getItem('behaviours'));
    setBehaviours(behaviours);
  }, []);

  const sustainabilityModal = () => {
    return (
      <>
        <Container className="profile-prioritise">
          <Row className="mt-5 justify-content-center">
            <p className="text-center profile-header">{`Prioritise which cause is most and least important to you.`}</p>
          </Row>
          <Row className="mt-2 justify-content-center">
            <DndContext
              sensors={sensors}
              collisionDetection={rectIntersection}
              onDragEnd={handleDragEnd}
              onDragOver={handleDragOver}
            >
              <Col className="category-panel">
                <Row className="pl-3 mb-10">
                  <p className="category-title">Set your Priorities &rarr;</p>
                </Row>
                <Row className="mb-40">
                  <Container className="tips-panel">
                    <div className="tips-icon">
                      <img src={tipIcon} />
                    </div>
                    <div className="tips-text">
                      <p className="category-subtitle">
                        Drag and drop each cause into{' '}
                        <b style={{ color: '#3e61DF' }}>priority order.</b> Drag those that are more
                        important to you to the top. Had a change or heart? You can also move causes
                        between columns. <br />
                        <br />
                        Rating all your decisions relative to each other helps us create better,
                        more personalized outcomes for you. Factors you say are more important
                        receive greater weight in your ratings, and vice versa.
                        <br />
                        <br />
                        Tap done when you’ve finalised your selections.
                      </p>
                    </div>
                  </Container>
                </Row>
                <Row className="justify-content-center">
                  <Button
                    onClick={() => handleButtonShow()}
                    className="btn-main-shadowless confirm-button"
                  >
                    Done
                  </Button>
                </Row>
              </Col>
              <Col className="category-panel">
                <Row style={{ flexDirection: 'column' }}>
                  <p className="category-title">Support</p>
                  <p className="category-subtitle">Causes you support</p>
                </Row>
                <Row className="mt-3 support">
                  <CardContainer id="support" items={behaviours.support} />
                </Row>
              </Col>
              <Col className="category-panel">
                <Row style={{ flexDirection: 'column' }}>
                  <p className="category-title">Avoid</p>
                  <p className="category-subtitle">Behaviours you want to avoid</p>
                </Row>
                <Row className="mt-3 avoid">
                  <CardContainer id="avoid" items={behaviours.avoid} />
                </Row>
              </Col>
              <Col className="category-panel">
                <Row style={{ flexDirection: 'column' }}>
                  <p className="category-title">Neutral</p>
                  <p className="category-subtitle">Behaviours not on your radar right now</p>
                </Row>
                <Row className="mt-3 unsure">
                  <CardContainer id="unsure" items={behaviours.unsure} />
                </Row>
              </Col>
              <DragOverlay>
                {activeId ? <Item id={activeId} item={behaviours[activeId]} /> : null}
              </DragOverlay>
            </DndContext>
          </Row>
        </Container>
      </>
    );
  };

  const loadingModal = () => {
    return <LoadingPage />;
  };

  const confirmationModal = () => {
    return (
      <div className="overlay">
        <Container className="showup-modal">
          <Row className="mt-10 mb-10 justify-content-center">
            <img src={bookmark} width="150" />
          </Row>
          <Row className="text-center justify-content-center">
            <div className="header">
              <p>{`Nice work!`}</p>
            </div>
          </Row>
          <Row className="mt-20">
            <p className="d-block text-center w-100 comment">{`Now select any sovereign states that you want to avoid.`}</p>
          </Row>
          <Row className="justify-content-center mt-10">
            <Col sm={5} className="mb-40">
              <div className="text-center">
                <Button className="btn-blue btn-main-shadowless" onClick={() => history.push('states')}>{`Let's go`} &rarr;</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };
  return (
    <>
      {behaviours.support.length !== 0 ||
        behaviours.avoid.length !== 0 ||
        behaviours.unsure.length !== 0
        ? sustainabilityModal()
        : loadingModal()}
      {showModal && confirmationModal()}
    </>
  );
}
