import React from 'react';

import { Container, Row, Table } from 'reactstrap';

import './PortfolioUpload.scss';

const PortfolioExampleCard = () => (
  <Container className="example-panel">
    <Row>
      <p className="panel-title">
        Upload a .CSV file that includes Investment name, Exchange, Weight and other relevant
        identifiers, eg Ticker Code.{' '}
      </p>
    </Row>
    <Table bordered responsive>
      <tbody>
        <tr>
          <td>Investment Name</td>
          <td>Exchange</td>
          <td>Ticker Code</td>
          <td>APIR Code</td>
          <td>ISIN</td>
          <td>Citi Code</td>
          <td>Allocation %</td>
        </tr>
        <tr>
          <td>Walt Disney Co</td>
          <td>NYSE</td>
          <td>DIS</td>
          <td />
          <td />
          <td />
          <td>0.25</td>
        </tr>
        <tr>
          <td>Alphabet Inc</td>
          <td>NYSE</td>
          <td>GOOGL</td>
          <td />
          <td />
          <td />
          <td>0.25</td>
        </tr>
        <tr>
          <td>Magellen Global</td>
          <td>ASX</td>
          <td />
          <td>MGE0001AU</td>
          <td />
          <td />
          <td>0.40</td>
        </tr>
        <tr>
          <td>Beta Shares A200</td>
          <td>ASX</td>
          <td />
          <td />
          <td>AU00000A2000</td>
          <td />
          <td>0.10</td>
        </tr>
      </tbody>
    </Table>
  </Container>
);

export default PortfolioExampleCard;
