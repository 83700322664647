import { IInstrumentDto } from '../interfaces/responses/instrument/IInstrumentDto';

export const emptyInstrument: IInstrumentDto = {
  instrumentId: '',
  type: '',
  name: '',
  logo: '',
  exchange: '',
  symbol: '',
  matchScoreDisplay: '',
  grade: {
    gradeValue: '',
    gradeLevel: 0,
  },
  matchToValuesStatus: 1,
  performanceOverview: {
    sharePrice: '',
    yearReturn: '',
    dividendPerShare: '',
  },
  scores: {
    ethicsScore: 0,
    planetScore: 0,
    communityScore: 0,
    employeesScore: 0,
  },
  sustainabilityData: {
    dataLastUpdated: '',
    numberOfActiveDataSources: '',
  },
  priceHistory: {
    current: '',
    currentDate: new Date(),
    low: '',
    lowDate: new Date(),
    high: '',
    highDate: new Date(),
  },
  fundamentals: {
    averageReturn: '',
    earningsPerShare: '',
    priceEarningsRatio: '',
    priceEarningsRatioGrowth: '',
    shares: '',
    dividendPerShare: '',
    dividendYield: '',
    marketCap: '',
  },
  etfFundamentals: {
    marketCap: '',
    averageReturn: '',
    netExpensesRatio: '',
    yield: '',
    assetTurnover: '',
    totalAssets: '',
  },
  about: {
    name: '',
    industry: '',
    sector: '',
    bloombergWebsite: '',
    marketWatchWebsite: '',
    description: '',
  },
  personnel: {
    officerName: '',
    officerTitle: '',
    numberOfEmployees: '',
  },
  holdings: [],
  relatedInvestments: [],
  conflicts: [],
  matches: [],
  relatedIssues: {
    conflicts: [],
    matches: [],
  },
  isInApprovedProductList: true,
};
