import { useHistory } from 'react-router-dom';
import { Row, Button } from 'reactstrap';

import { redirectToInstrumentPage } from '../../../_helpers/instrument';

const HoldingsLinkedWithIssues = (props: any) => {
  const { instrument } = props;
  const history = useHistory();

  return (
    <Row className="d-flex">
      <p style={{ fontSize: '14.5px' }}>{instrument.name}</p>
      <Button
        color="link"
        className="mr-0 ml-auto"
        style={{ marginTop: '-21px', fontSize: '15px' }}
        onClick={redirectToInstrumentPage(instrument, history)}
      >
        view&nbsp;&nbsp;&nbsp;
        <span style={{ fontSize: '24px', position: 'relative', top: '2px', color: '#3e61df' }}>
          {'>'}
        </span>
      </Button>
    </Row>
  );
};
export default HoldingsLinkedWithIssues;
