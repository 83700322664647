import React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import PropTypes from 'prop-types';

import '../styles.scss';

export function Item(props) {
  const { item } = props;

  const style = {
    padding: '10px 35px 10px 25px',
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 12px 0',
    borderRadius: '10px',
    cursor: 'move',
    boxShadow: '0 0 5px rgba(0,0,0,0.1)',
    background: 'white',
    zIndex: '1000',
    // flexWrap: 'wrap',
  };

  const iconStyle = {
    borderRadius: '5px',
    boxShadow: '0 1px 2px 0.5px rgba(0, 0, 0, 0.5)',
  };

  return (
    <div style={style}>
      <img src={item.logoUrl} style={iconStyle} width="50" />
      <p style={{ color: '#2e3074', margin: '0 0 0 10%', fontSize: '15px' }}>{item.name}</p>
    </div>
  );
}

export default function StatesSortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Item id={props.id} item={props.item} />
    </div>
  );
}

Item.propTypes = {
  item: PropTypes.shape({
    logoUrl: PropTypes.string,
    name: PropTypes.string,
  }),
};

StatesSortableItem.propTypes = {
  id: PropTypes.string,
  item: PropTypes.shape({
    logoUrl: PropTypes.string,
    name: PropTypes.string,
  }),
};
