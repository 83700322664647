import { every } from 'lodash';

import ValidatorService from '../service/validator';

class CompanyAddress {
  addressLine1 = '';
  addressLine2 = '';
  citySuburb = '';
  stateProvince = '';
  country = 'Australia';
  postCode = '';

  constructor(obj) {
    Object.assign(this, obj);
  }
}

export default class Adviser {
  firstName = '';
  middleName = '';
  lastName = '';
  email = '';
  companyName = '';
  numberOfAdvisers = 0;
  carNo = '';
  afslNo = '';
  primaryInvestmentSolution = null;
  primaryTypeOfAdviceProvided = null;
  numberOfClients = 0;
  averageOfClient = 0;
  companyAddress = {};

  constructor(obj) {
    Object.assign(this, obj);
    if (obj) {
      this.companyAddress = new CompanyAddress(this.companyAddress);
      return;
    }
    this.companyAddress = new CompanyAddress();
  }

  isValidCompanyAddress() {
    const companyAddressFields = every(
      ['addressLine1', 'citySuburb', 'country', 'stateProvince'],
      (field) => ValidatorService.validateInputTextWithComma(this.companyAddress[field]),
    );
    return (
      companyAddressFields &&
      ValidatorService.validateInputNumber(this.companyAddress.postCode) &&
      ValidatorService.validateInputTextOrNumber(this.companyName)
    );
  }

  isValidStep3() {
    return every(
      [
        'numberOfClients',
        'averageOfClient',
        'primaryTypeOfAdviceProvided',
        'primaryInvestmentSolution',
      ],
      (field) => ValidatorService.validateInputTextOrNumberWithoutRange(this[field]),
    );
  }
}

Adviser.CompanyAddress = CompanyAddress;
