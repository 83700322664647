import React from 'react';

import { Container, Row, Col } from 'reactstrap';

import Printer from '_components/app/Printer';
import { LoadingPage } from '_components/commons';

import { getTotalPreferences } from '../../service/InsightsService';
import { getTotalExclusions } from '../../service/InsightsService';
import { getTop5Preferences } from '../../service/InsightsService';
import { getTop5Exclusions } from '../../service/InsightsService';
import { getMostMatches } from '../../service/InsightsService';
import { getMostConflict } from '../../service/InsightsService';
import { getMostRemoved } from '../../service/InsightsService';
import { getAdviceActivityInsights } from '../../service/InsightsService';
import { getTotalAverages } from '../../service/InsightsService';
import { getAverageActions } from '../../service/InsightsService';

import InsightsAdviceActivity from './InsightsAdviceActivity';
import InsightsAverageActions from './InsightsAverageActions';
import InsightsBarGraph from './InsightsBarGraph';
import InsightsDoughnutGraph from './InsightsDoughnutGraph';
import InsightsMatchesConflicts from './InsightsMatchesConflicts';
import InsightsTable from './InsightsTable';

type InsightsState = {
  isLoadingTotalPreferences?: boolean;
  totalPreferences?: any;
  isLoadingTotalExclusions?: boolean;
  totalExclusions?: any;
  isLoadingTop5Preferences?: boolean;
  top5Preferences?: any;
  isLoadingTop5Exclusions?: boolean;
  top5Exclusions?: any;
  isLoadingMostMatches?: boolean;
  mostMatches?: any;
  isLoadingMostConflict?: boolean;
  mostConflict?: any;
  isLoadingMostRemoved?: boolean;
  mostRemoved?: any;
  isLoadingAdviceActivityInsights?: boolean;
  adviceActivityInsights?: any;
  isLoadingAverageActions?: boolean;
  averageActions?: any;
  isLoadingTotalAverages?: boolean;
  totalAverages?: any;
  mostMatchesInnerScope: string;
  mostRemovedInnerScope: string;
  mostConflictInnerScope: string;
  active: string;
  scope: string;
};

class InsightsPage extends React.Component<any, InsightsState> {
  state: InsightsState = {
    isLoadingTotalPreferences: true,
    totalPreferences: {},
    isLoadingTotalExclusions: true,
    totalExclusions: {},
    isLoadingTop5Preferences: true,
    top5Preferences: {},
    isLoadingTop5Exclusions: true,
    top5Exclusions: {},
    isLoadingMostMatches: true,
    mostMatches: {},
    isLoadingMostConflict: true,
    mostConflict: {},
    isLoadingMostRemoved: true,
    mostRemoved: {},
    isLoadingAverageActions: true,
    averageActions: {},
    isLoadingTotalAverages: true,
    totalAverages: {},
    mostMatchesInnerScope: 'portfolios',
    mostRemovedInnerScope: 'portfolios',
    mostConflictInnerScope: 'portfolios',
    active: 'adviser',
    scope: 'adviser',
  };

  componentDidMount() {
    this.loadData(this.state.scope);
  }

  loadData(scope: string) {
    const params = {
      scope: scope,
      duration: 'year',
    };
    getTotalPreferences(params)
      .then((data) => {
        const tp = {
          isLoading: false,
          title: 'TOTAL PREFERENCES',
          graphItems: data.totalPreferences,
          note: 'Preference selection across all clients',
          barColor: '#A7D9C5',
          labelColor: '#FFFFFF',
        };

        this.setState({ totalPreferences: tp });
      })
      .catch()
      .finally();

    getTop5Preferences(params)
      .then((data) => {
        const t5p = {
          isLoading: false,
          title: 'TOP 5 Preferences',
          graphItems: data.top5Preferences,
          mostCommon: data.mostCommon.title,
          leastCommon: data.leastCommon.title,
          note: 'Preference selection across all clients',
          doughnutColor: ['#A7D9C5', '#E998B4', '#7BE0D3', '#CDB3F1', '#7492FF'],
        };

        this.setState({ top5Preferences: t5p });
      })
      .catch()
      .finally();

    getTotalExclusions(params)
      .then((data) => {
        const te = {
          isLoading: false,
          title: 'TOTAL EXCLUSIONS',
          graphItems: data.totalExclusions,
          note: 'Preference selection across all clients',
          barColor: '#FFE5EA',
          labelColor: '#2E3074',
        };

        this.setState({ totalExclusions: te });
      })
      .catch()
      .finally();

    getTop5Exclusions(params)
      .then((data) => {
        const t5e = {
          isLoading: false,
          title: 'TOP 5 Exclusion',
          graphItems: data.top5Exclusions,
          mostCommon: data.mostCommon.title,
          leastCommon: data.leastCommon.title,
          note: 'Preference selection across all clients',
          doughnutColor: ['#A7D9C5', '#E998B4', '#7BE0D3', '#CDB3F1', '#7492FF'],
        };

        this.setState({ top5Exclusions: t5e });
      })
      .catch()
      .finally();

    this.callMostMatches(scope, this.state.mostMatchesInnerScope);

    this.callMostConflict(scope, this.state.mostConflictInnerScope);

    this.callMostRemoved(scope, this.state.mostRemovedInnerScope);

    getAdviceActivityInsights(params)
      .then((data) => {
        const aai = {
          isLoading: false,
          average: data.average,
          total: data.total,
          note: 'Number of values profile. Total | Average per client',
        };
        this.setState({ adviceActivityInsights: aai });
      })
      .catch()
      .finally();

    getTotalAverages(params)
      .then((data) => {
        const ta = {
          isLoading: false,
          portfolioConflicts: data.portfolioConflicts,
          portfolioMatches: data.portfolioMatches,
          profileConflicts: data.profileConflicts,
          profileMatches: data.profileMatches,
          note: 'Matches and Conflicts. Total | Average per client',
        };
        this.setState({ totalAverages: ta });
      })
      .catch()
      .finally();

    getAverageActions(params)
      .then((data) => {
        const aa = {
          isLoading: false,
          averageItems: data.averageActions,
          note: 'Advice actions. Average per client',
          barColor: '#A7D9C5',
        };

        this.setState({ averageActions: aa });
      })
      .catch()
      .finally();
  }

  handleClick(name: string) {
    this.setState({ active: name, scope: name });
    this.loadData(name);
  }

  mostMatchesHandleClick(key: string) {
    this.setState({ mostMatchesInnerScope: key });
    this.callMostMatches(this.state.scope, key);
  }

  callMostMatches(scope: string, innerScope: string) {
    const params = {
      scope: scope,
      innerScope: innerScope,
    };
    getMostMatches(params)
      .then((data) => {
        const mm = {
          isLoading: false,
          title: 'MOST MATCHED INSTRUMENTS',
          tableItems: data.mostMatchedCompanies,
          note: 'Instruments most commonly identified as a Match',
        };
        this.setState({ mostMatches: mm });
      })
      .catch()
      .finally();
  }

  mostConflictHandleClick(key: string) {
    this.setState({ mostConflictInnerScope: key });
    this.callMostConflict(this.state.scope, key);
  }

  callMostConflict(scope: string, innerScope: string) {
    const params = {
      scope: scope,
      innerScope: innerScope,
    };
    getMostConflict(params)
      .then((data) => {
        const mc = {
          isLoading: false,
          title: 'MOST CONFLICT INSTRUMENTS',
          tableItems: data.mostConflictCompanies,
          note: 'Instruments most commonly identified as a Conflict',
        };
        this.setState({ mostConflict: mc });
      })
      .catch()
      .finally();
  }

  mostRemovedHandleClick(key: string) {
    this.setState({ mostRemovedInnerScope: key });
    this.callMostRemoved(this.state.scope, key);
  }

  callMostRemoved(scope: string, innerScope: string) {
    const params = {
      scope: scope,
      innerScope: innerScope,
    };

    getMostRemoved(params)
      .then((data) => {
        const mr = {
          isLoading: false,
          title: 'MOST REMOVED INSTRUMENTS',
          tableItems: data.mostRemoved,
          note: "Instruments most often marked as 'Removed'",
        };
        this.setState({ mostRemoved: mr });
      })
      .catch()
      .finally();
  }

  render() {
    const {
      totalPreferences,
      top5Preferences,
      totalExclusions,
      top5Exclusions,
      mostMatches,
      mostConflict,
      mostRemoved,
      averageActions,
      totalAverages,
      adviceActivityInsights,
    } = this.state;
    if (
      Object.keys(totalPreferences).length &&
      Object.keys(top5Preferences).length &&
      Object.keys(totalExclusions).length &&
      Object.keys(top5Exclusions).length &&
      Object.keys(mostMatches).length &&
      Object.keys(mostConflict).length &&
      Object.keys(mostRemoved).length &&
      Object.keys(averageActions).length &&
      Object.keys(totalAverages).length &&
      Object.keys(adviceActivityInsights).length
    )
      return (
        <Printer
          style={{
            top: '30px',
            right: '0px',
          }}
        >
          <main>
            <Container className="d-inline flex-grow-1">
              <Row>
                <Col xs="10" />
                <Col xs="2" style={{ marginBottom: 30, marginTop: 9 }}>
                  <button
                    onClick={() => this.handleClick('adviser')}
                    className={`insights-options-adviser ${
                      this.state.active === 'adviser' ? 'active' : ''
                    }`}
                  >
                    Adviser
                  </button>
                  <span className="insights-options-symbol"> | </span>
                  <button
                    onClick={() => this.handleClick('ethicadviser')}
                    className={`insights-options-ethicAdviser ${
                      this.state.active === 'ethicadviser' ? 'active' : ''
                    }`}
                  >
                    Oko Adviser
                  </button>
                </Col>
              </Row>
              <Row>
                <Col xs="7" style={{ marginLeft: 15, marginBottom: 18 }}>
                  <InsightsBarGraph {...totalPreferences} />
                </Col>
                <Col xs="4" style={{ marginLeft: 7, marginBottom: 18 }}>
                  <InsightsDoughnutGraph {...top5Preferences} />
                </Col>
              </Row>

              <Row>
                <Col xs="7" style={{ marginLeft: 15, marginBottom: 18 }}>
                  <InsightsBarGraph {...totalExclusions} />
                </Col>
                <Col xs="4" style={{ marginLeft: 7, marginBottom: 18 }}>
                  <InsightsDoughnutGraph {...top5Exclusions} />
                </Col>
              </Row>

              <Row>
                <Col xs="7" style={{ marginRight: 10 }}>
                  <Col style={{ marginBottom: 30, marginTop: 9 }}>
                    <InsightsTable
                      {...mostMatches}
                      handleClick={this.mostMatchesHandleClick.bind(this)}
                    />
                  </Col>
                  <Col style={{ marginBottom: 30 }}>
                    <InsightsTable
                      {...mostConflict}
                      handleClick={this.mostConflictHandleClick.bind(this)}
                    />
                  </Col>
                  <Col style={{ marginBottom: 10 }}>
                    <InsightsTable
                      {...mostRemoved}
                      handleClick={this.mostRemovedHandleClick.bind(this)}
                    />
                  </Col>
                </Col>

                <Col xs="4" className="advice-panels-group">
                  <Col style={{ marginBottom: 50, marginTop: 15 }}>
                    <p className="advice-activity-title">ADVICE ACTIVITY INSIGHTS</p>
                    <InsightsAdviceActivity {...adviceActivityInsights} />
                  </Col>
                  <Col style={{ marginBottom: 50 }}>
                    <InsightsMatchesConflicts {...totalAverages} />
                  </Col>
                  <Col>
                    <InsightsAverageActions {...averageActions} />
                  </Col>
                </Col>
              </Row>
            </Container>
          </main>
        </Printer>
      );
    return <LoadingPage />;
  }
}

export default InsightsPage;
