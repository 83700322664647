import { isEmpty } from 'lodash';

import { LoadingPageSmall } from '_components/commons/LoadingPage';
import StatusPill from '_components/commons/StatusPill';
import { IIssueDto } from 'interfaces/responses/instrument/IIssueDto';
import { IReason } from 'interfaces/responses/reasons/IReasonsDto';

// import noMatchIcon from '../../_assets/img/icons/coDetails/conflict/focus@2x.png';
// import matchIcon from '../../_assets/img/icons/coDetails/match/focus@3x.png';
import BusinessActivityWithPopup from '../commons/BusinessActivityWithPopup';
import '../commons/Instrument/Instrument.scss';
import NoDataView from '../commons/Instrument/NoDataView';

interface ProfileStatusProps {
  conflicts: IIssueDto[];
  matches: IIssueDto[];
  instrumentName?: string;
  clientFirstName?: string;
  matchToValuesStatus: number;
  dataLastUpdated: string | undefined;
  reasons: IReason[];
  reasonsLoading: boolean;
}

function InstrumentStatus(props: ProfileStatusProps) {
  const {
    conflicts,
    matches,
    instrumentName,
    clientFirstName,
    matchToValuesStatus,
    dataLastUpdated,
    reasons,
  } = props;
  const noProfileMessage =
    'If there is no Profile linked to the Investment (either no Profile complete, or when using Research), data is not shown.';
  const nonMatchMessage = 'This company is a non match so no Match or Conflict data is shown';
  const area: string = conflicts.length > 1 || matches.length > 1 ? 'areas' : 'area';
  if (props.reasonsLoading) {
    return (
      <>
        <h4 className="title-score mb-20">Why is this a...</h4>
        <LoadingPageSmall />
      </>
    );
  }

  if (!isEmpty(conflicts)) {
    return (
      <>
        <div className="d-flex align-items-center" style={{ gap: '6px', marginBottom: '8px' }}>
          <h4 className="m-0">Why is this a conflict?</h4>
          {/* <div className="ml-20" style={{ paddingTop: '.15rem' }}>
            <img src={noMatchIcon} className="icon" alt="icon" height="20" width="20" />
          </div> */}
          <StatusPill
            className="partial-status-pill justify-content-center align-items-center d-flex"
            matchToValuesStatus={matchToValuesStatus}
          />
        </div>
        <p>
          {`${instrumentName} is exposed to the following ${area} which ${clientFirstName} doesn't want to support:`}
        </p>
        <div className="d-flex flex-wrap">
          {conflicts.map((item, key) => (
            <div key={key} className="instrument-status">
              <BusinessActivityWithPopup
                item={item}
                reason={reasons.find((reason) => reason.questionId === item.id)?.reason ?? ''}
                instrumentName={instrumentName}
                className="conflict"
                dataLastUpdated={dataLastUpdated}
                affectedInstruments={item.affectedInstruments}
              />
            </div>
          ))}
        </div>
      </>
    );
  }

  if (!isEmpty(matches)) {
    return (
      <>
        <div className="d-flex align-items-center" style={{ gap: '6px', marginBottom: '8px' }}>
          <h4 className="m-0">Why is this a match?</h4>
          {/* <div className="ml-20 mr-2" style={{ paddingTop: '.15rem' }}>
            <img src={matchIcon} className="icon" alt="icon" height="20" width="20" />
          </div> */}
          <StatusPill
            className="partial-status-pill justify-content-center align-items-center d-flex"
            matchToValuesStatus={matchToValuesStatus}
          />
        </div>
        <p>
          {`${instrumentName} is exposed to the following ${area} which ${clientFirstName} supports:`}
        </p>
        <div className="d-flex flex-wrap instrument-status">
          {matches.map((item, key) => (
            <div key={key}>
              <BusinessActivityWithPopup
                item={item}
                instrumentName={instrumentName}
                reason={reasons.find((reason) => reason.questionId === item.id)?.reason ?? ''}
                className="match"
                dataLastUpdated={dataLastUpdated}
                affectedInstruments={item.affectedInstruments}
              />
            </div>
          ))}
        </div>
      </>
    );
  }

  if (matchToValuesStatus === 3) {
    return (
      <>
        <h4 className="title-score mb-20">Why is this a match?</h4>
        <NoDataView message={nonMatchMessage} />
      </>
    );
  }
  return (
    <>
      <h4 className="title-score mb-20">Why is this a match?</h4>
      <NoDataView message={noProfileMessage} />
    </>
  );
}

export default InstrumentStatus;
