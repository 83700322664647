import React from 'react';

import { useHistory } from 'react-router-dom';
import { Button, Col, Row, Container } from 'reactstrap';

import valuesProfile from '../../_assets/img/icons/graphics/laptop_2@3x.png';

import ButtonLoading from './ButtonLoading';

const StartQuestionnaireNow = ({ startInvestingProfile }) => {
  const history = useHistory();
  return (
    <Container>
      <Row className="justify-content-center mt-50">
        <img src={valuesProfile} alt="laptop" width="200" />
      </Row>
      <Row className="justify-content-center mt-30">
        <p style={{ fontSize: '17px' }}>Create a Sustainability Profile to find results</p>
        <Col sm={12} className="d-flex justify-content-center mt-20 mb-20">
          <ButtonLoading
            className="btn-main-shadowless"
            label="Create a profile"
            onClick={() => startInvestingProfile()}
          />
        </Col>
        <a onClick={() => history.push(`/clients`)} style={{ cursor: 'pointer', fontSize: '17px' }}>
          Back to client list
        </a>
      </Row>
    </Container>
  );
};

export default StartQuestionnaireNow;
