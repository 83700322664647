import { Switch, Route } from 'react-router-dom';

import Faqs from '_components/assistance/Faqs';
import Support from '_components/assistance/Support';
import PropTypes from 'prop-types';

import Layout from '../ui/Layout';
import Presets from '../ui/Presets';

import AccountInvite from './AccountInvite';
import BillingEdit from './BillingEdit';
import BillingShow from './BillingShow';
import AccountEdit from './DetailsEdit';
import AccountDetails from './DetailsShow';
import Settings from './Settings';
import SubscriptionShow from './SubscriptionShow';

const Account = ({ match: { path } }) => (
  <Switch>
    <Route
      path={`${path}/accountedit`}
      render={() => (
        <Layout
          header={Presets.main}
          sidebar={Presets.account}
          sidebarSelect="account-details"
          component={AccountEdit}
        />
      )}
    />
    <Route
      path={`${path}/settings`}
      render={() => (
        <Layout
          header={Presets.main}
          sidebar={Presets.account}
          sidebarSelect="account-settings"
          component={Settings}
        />
      )}
    />
    <Route
      path={`${path}/billing`}
      render={() => (
        <Layout
          header={Presets.main}
          sidebar={Presets.account}
          sidebarSelect="account-billing"
          component={BillingShow}
        />
      )}
    />
    <Route
      path={`${path}/billingedit`}
      render={() => (
        <Layout
          header={Presets.main}
          sidebar={Presets.account}
          sidebarSelect="account-billing"
          component={BillingEdit}
        />
      )}
    />
    <Route
      path={`${path}/subscription`}
      render={() => (
        <Layout
          header={Presets.main}
          sidebar={Presets.account}
          sidebarSelect="account-subscription"
          component={SubscriptionShow}
        />
      )}
    />
    <Route
      path={`${path}/accountinvite`}
      render={() => (
        <Layout
          header={Presets.main}
          sidebar={Presets.account}
          sidebarSelect="account-invite"
          component={AccountInvite}
        />
      )}
    />
    <Route
      path={`${path}/faqs`}
      render={() => (
        <Layout
          header={Presets.main}
          sidebar={Presets.account}
          sidebarSelect="faqs"
          component={Faqs}
        />
      )}
    />
    <Route
      path={`${path}/support`}
      render={() => (
        <Layout
          header={Presets.main}
          sidebar={Presets.account}
          sidebarSelect="support"
          component={Support}
        />
      )}
    />
    <Route
      path={path}
      render={() => (
        <Layout
          header={Presets.main}
          sidebar={Presets.account}
          sidebarSelect="account-details"
          component={AccountDetails}
        />
      )}
    />
  </Switch>
);

Account.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string }).isRequired,
};

export default Account;
