/* eslint-disable @typescript-eslint/no-unused-vars */
import { isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Row, Spinner } from 'reactstrap';
import { Container } from 'reactstrap';

import Printer from '_components/app/Printer';
import Modal from '_components/commons/Modal';
import useQuery from '_helpers/useQuery';
import { GradeInterface } from 'model/Instrument';
import { getResultsSummary } from 'service/resultsService';

import tipIcon from '../../../../_assets/img/icons/profile/Bulb@1.5x.png';
import infoModalIcon from '../../../../_assets/img/icons/profile/infoModalIcon.png';
import { InstrumentInterface } from '../../../../model/Instrument';
import { IToolTip } from '../../../../model/Instrument';
import { TileType } from '../../../../model/enums';
import { getAplDetails } from '../../../../service/AplService';
import { getClient } from '../../../../service/ClientsService';
import { getSustainabilityScores } from '../../../../service/PortfolioService';
import { getResults, getResultsCount } from '../../../../service/resultsService';
import { FilterContext } from '../../../../store/FiltersContext';
import LoadingPage from '../../../commons/LoadingPage';
import StartQuestionnaire from '../../../commons/StartQuestionnaire';
import UploadAPLPortfolio from '../../../commons/UploadAPLPortfolio';
import UploadClientPortfolio from '../../../commons/UploadClientPortfolio';

import EmptyResults from './EmptyResults';
import FilterAndSortBy from './FilterAndSortBy';
import './Results.scss';
import ResultsGrid from './ResultsGrid';
import ResultsProfilePortfolio from './ResultsProfilePortfolio';
import SustainabilityOverview from './SustainabilityOverview';

interface ClientInterface {
  clientFirstName: string;
}

interface ResultsInterface {
  loadResults: boolean;
  skip: number;
  take: number;
  type: TileType;
  results: InstrumentInterface[] | null;
}

export interface ResultCountInterface {
  portfolioConflicts: number;
  portfolioMatches: number;
  portfolioAlternatives: number;
  profileAlternatives: number;
  profileConflicts: number;
  profileMatches: number;
  aplConflicts: number;
  aplMatches: number;
  aplAlternatives: number;
  hasProfile: boolean;
  hasPortfolio: boolean;
  hasApprovedProductList: boolean;
}

export interface IMatchConflict {
  id: string;
  questionDesc: string;
  iconUrl: string;
  isSupport: boolean;
  isAvoid: boolean;
}

export interface IInstrumentBase {
  instrumentId: string;
  instrumentName: string;
  totalPercentage: number;
  symbol: string;
  exposure: string;
  numberOfHoldings: number;
  toolTipInstrumentNames: string[];
  toolTips: IToolTip[];
}
export interface IMatchConflictInstrument {
  exposure: string;
  numberOfHoldings: number;
  instruments: IInstrumentBase[];
  matchConflict: IMatchConflict;
}
export interface IResultsSummaryResponse {
  hasPercentageInPortfolio: boolean;
  avoidNumberOfHoldings: number;
  avoidPercentage: number;
  avoidPercentageDisplay: string;
  supportNumberOfHoldings: number;
  supportPercentage: number;
  supportPercentageDisplay: string;
  totalNumberOfHoldings: number;
  results: IMatchConflictInstrument[];
}
export interface SustainabilityResultsInterface {
  history: any;
  hasPercentageInPortfolio: boolean;
  clientFirstName: string;
  numberOfConflicts: number;
  numberOfHoldings: number;
  numberOfInstrumentsAnalysed: number;
  numberOfMatches: number;
  portfolioName: string;
  profileCreatedDate: string;
  grade: GradeInterface;
  scores: {
    communityScore: number;
    employeesScore: number;
    ethicsScore: number;
    planetScore: number;
  };
  uploadDate: string;
}
export default function Results(props: any) {
  const { id } = props.match.params;
  const clientId = id as string;

  const [loadingResults, setLoadingResults] = useState(false);
  const [loadingResultsCount, setLoadingResultsCount] = useState(true);
  const [resultCountResponse, setResultsCountResponse] = useState<ResultCountInterface>();
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [skip, setSkip] = useState<number>(50);
  const [isPortfolioBreakdownVisible, setIsPortfolioBreakdownVisible] = useState<boolean>(false);

  const [selectedTileType, setSelectedTileType] = useState<TileType>(
    TileType.SustainabilityOverview,
  );
  const [client, setClient] = useState<ClientInterface>();
  const [profileCreatedDate, setProfileCreatedDate] = useState(null);
  const [aplFileName, setAplFileName] = useState('');
  const [sustainabilityOverviewResults, setSustainabilityOverviewResults] =
    useState<SustainabilityResultsInterface>();
  const [resultsSummary, setResultsSummary] = useState<IResultsSummaryResponse>();
  const [loadingOverviewResults, setLoadingOverviewResults] = useState(true);
  const [loadingSummary, setLoadingSummary] = useState(true);
  const [resultsArray, setResultsArray] = useState<ResultsInterface[]>([
    {
      loadResults: true,
      skip: 0,
      take: 50,
      type: TileType.SustainabilityOverview,
      results: [],
    },
    {
      loadResults: true,
      skip: 0,
      take: 50,
      type: TileType.ProfileAlternatives,
      results: [],
    },
    {
      loadResults: true,
      skip: 0,
      take: 50,
      type: TileType.ProfileConflicts,
      results: [],
    },
    {
      loadResults: true,
      skip: 0,
      take: 50,
      type: TileType.ProfileMatches,
      results: [],
    },
    {
      loadResults: true,
      skip: 0,
      take: 50,
      type: TileType.PortfolioConflicts,
      results: [],
    },
    {
      loadResults: true,
      skip: 0,
      take: 50,
      type: TileType.PortfolioMatches,
      results: [],
    },
    {
      loadResults: true,
      skip: 0,
      take: 5000,
      type: TileType.PortfolioAlternatives,
      results: [],
    },
    {
      loadResults: true,
      skip: 0,
      take: 50,
      type: TileType.AplConflicts,
      results: [],
    },
    {
      loadResults: true,
      skip: 0,
      take: 50,
      type: TileType.AplMatches,
      results: [],
    },
    {
      loadResults: true,
      skip: 0,
      take: 5000,
      type: TileType.AplAlternatives,
      results: [],
    },
  ]);
  const filterContext = useContext(FilterContext);

  const location = useLocation();
  const query = useQuery();
  const history = useHistory();
  useEffect(() => {
    if (
      location.pathname.endsWith('/results/portfolioResults') ||
      location.pathname.endsWith('/results')
    ) {
      setSelectedTileType((query.get('tab') as TileType) || TileType.SustainabilityOverview);
    } else if (window.location.pathname.endsWith('/results/aplResults')) {
      setSelectedTileType((query.get('tab') as TileType) || TileType.AplConflicts);
    } else if (window.location.pathname.endsWith('/results/profileResults')) {
      setSelectedTileType((query.get('tab') as TileType) || TileType.ProfileConflicts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // When the selectedFilters and the sortBy are changed, then the results data is wiped
  useEffect(() => {
    const newArray = [...resultsArray];
    newArray.forEach((x) => {
      x.loadResults = true;
      x.skip = 0;
      x.results = [];
    });
    setResultsArray(newArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterContext.selectedFilters, filterContext.selectedSortBy]);

  // Get Results Count
  useEffect(() => {
    if (clientId) {
      setLoadingResultsCount(true);
      getResultsCount(clientId, filterContext.selectedFilters)
        .then((response) => {
          setResultsCountResponse(response as ResultCountInterface);
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setLoadingResultsCount(false);
        });
    }
  }, [filterContext.selectedFilters, clientId]);

  const getSelectedResultsEntry = () => {
    const item = resultsArray.find((x) => x.type === selectedTileType);
    return item ? item : null;
  };

  useEffect(() => {
    const selectedTile = getSelectedResultsEntry();
    if (selectedTile !== null && selectedTile.results?.length === 0) {
      if (selectedTile?.loadResults) {
        setLoadingResults(true);
        const currentSelectedTileType = selectedTile.type;
        const selectedFilters = filterContext.selectedFilters;
        const selectedSortBy = filterContext.selectedSortBy;
        getResults(clientId, selectedTileType, selectedTile.skip, selectedFilters, selectedSortBy)
          .then((response: any) => {
            if (response.typeExists) {
              if (
                selectedFilters === filterContext.selectedFilters &&
                selectedSortBy === filterContext.selectedSortBy
              ) {
                const clonedArray = [...resultsArray];
                const resultArraySpot = clonedArray.find((i) => i.type == currentSelectedTileType);
                if (resultArraySpot?.results != null && response.results != null) {
                  resultArraySpot.results = resultArraySpot?.results?.concat(response.results);
                  setResultsArray(clonedArray);
                }
              }
              const rounded = Math.round(response.results?.length / 50) * 50;
              setSkip(rounded);
            }
          })
          .finally(() => {
            setLoadingResults(false);
            setShowLoadMoreButton(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTileType, filterContext.selectedFilters, filterContext.selectedSortBy]);

  const handleLoadMore = async () => {
    const selectedTile = getSelectedResultsEntry();
    if (selectedTile == null) {
      return;
    }
    setShowLoadMoreButton(true);
    selectedTile.skip += 50;

    selectedTile.loadResults = true;
    const currentSelectedTileType = selectedTile.type;
    const type = selectedTileType;
    getResults(
      clientId,
      type,
      selectedTile.skip,
      filterContext.selectedFilters,
      filterContext.selectedSortBy,
    )
      .then((response: any) => {
        if (response.typeExists) {
          const clonedArray = [...resultsArray];
          const resultArraySpot = clonedArray.find((i) => i.type == currentSelectedTileType);
          if (resultArraySpot?.results != null) {
            resultArraySpot.results = resultArraySpot?.results?.concat(response.results);
            setResultsArray((prev) => [...prev, ...clonedArray]);

            setSkip((prev) => prev + 50);
          }
        }
      })
      .finally(() => {
        setShowLoadMoreButton(false);
      });
  };

  useEffect(() => {
    async function onEffect() {
      if (clientId) {
        const params = { ClientCoreId: clientId };
        await getClient(params)
          .then((response) => {
            setClient(response.adviserClient);
          })
          .catch((err) => {
            // this.setState({ showMessage: true, message: err.message });
            toast.error(err.message);
          });

        // Only get sustainability scores, if a Profile exists
        if (resultCountResponse?.hasPortfolio) {
          await getSustainabilityScores(clientId)
            .then((response) => {
              setProfileCreatedDate(response.profileCreatedDate.slice(0, 10));
              setSustainabilityOverviewResults(response as SustainabilityResultsInterface);
              setLoadingOverviewResults(false);
            })
            .catch((err) => {
              toast.error(err.message);
              setLoadingOverviewResults(false);
            });

          await getResultsSummary(clientId)
            .then((response) => {
              setResultsSummary(response as IResultsSummaryResponse);
              setLoadingSummary(false);
            })
            .catch((err) => {
              toast.error(err.message);
              setLoadingSummary(false);
            });
        }
      }

      if (resultCountResponse?.hasApprovedProductList) {
        await getAplDetails()
          .then((response) => {
            setAplFileName(response.name);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
    onEffect();
  }, [clientId, resultCountResponse?.hasApprovedProductList, resultCountResponse?.hasPortfolio]);

  const onTileClick = (tileType: TileType) => {
    setSelectedTileType(tileType);
    if (
      location.pathname.endsWith('/results/portfolioResults') ||
      location.pathname.endsWith('/results') ||
      location.pathname.endsWith('/results/aplResults') ||
      location.pathname.endsWith('/results/profileResults')
    ) {
      history.replace(`${location.pathname}?tab=${tileType}`);
    }
  };

  const renderTiles = () => {
    return (
      <>
        <ResultsProfilePortfolio
          handleTileClick={onTileClick}
          selectedTile={selectedTileType}
          loadingResults={loadingResultsCount}
          resultCountResponse={resultCountResponse}
        />
      </>
    );
  };

  const renderHeader = () => {
    return (
      <Container style={{ width: '100%', maxWidth: '1100px', margin: '0 auto' }}>
        <div
          style={{
            display:
              (window.location.pathname.includes('/results/portfolioResults') ||
                window.location.pathname.includes('/results')) &&
              !window.location.pathname.includes('/results/aplResults') &&
              !window.location.pathname.includes('/results/profileResults')
                ? 'block'
                : 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
          className="justify-content-center mt-40 mb-10"
        >
          {(window.location.pathname.includes('/results/portfolioResults') ||
            window.location.pathname.includes('/results')) &&
            !window.location.pathname.includes('/results/aplResults') &&
            !window.location.pathname.includes('/results/profileResults') && (
              <>
                <h3 className="text-center">Portfolio Results for {client?.clientFirstName}</h3>
                {selectedTileType === TileType.SustainabilityOverview ? (
                  <div style={{ marginBottom: '40px', marginTop: '45px' }}>
                    <div style={{ marginLeft: '30px', marginRight: '30px' }}>
                      <div
                        style={{
                          display: 'inline-flex',
                          gap: '10px',
                          alignItems: 'center',
                        }}
                      >
                        <h4 style={{ marginBottom: '0' }}>Portfolio Breakdown</h4>
                        <button
                          style={{ border: '0', padding: '0', backgroundColor: 'transparent' }}
                          onClick={() =>
                            void setIsPortfolioBreakdownVisible((prevIsVisible) => !prevIsVisible)
                          }
                        >
                          <img src={infoModalIcon} alt="info" width={20} />
                        </button>
                      </div>
                      <p>
                        The Portfolio Breakdown shows the breakdown of your clients portfolio
                        against their Sustainability profile.{' '}
                      </p>
                    </div>
                    <Modal
                      onClose={() => void setIsPortfolioBreakdownVisible(false)}
                      isVisible={isPortfolioBreakdownVisible}
                    >
                      <h5>Portfolio Breakdown</h5>
                      <p>
                        The Portfolio Breakdown shows the breakdown of your clients portfolio
                        against their Sustainability profile.
                      </p>
                      <p>
                        A Match is when an investment aligns to an area that your client selected in
                        their profile as something they wish to support and is does not have
                        exposure to any Conflicts. A Conflict is when investments is aligned to an
                        area that the client had selected in their profile as something they do not
                        wish to support. Alternatives - also displayed as a &quot;non match&quot;
                        anything that is not a match or a conflict
                      </p>
                      <p>
                        Each instrument receives and overall Grades (from A* to F), supported with
                        an easy to follow Green, Amber and Red scale. This grading system provides a
                        concise and user-friendly summary of a company&apos;s overall ESG
                        performance and makes it easier to compare ratings and find solutions that
                        align with your client’s values.
                      </p>
                      <p style={{ fontStyle: 'italic', fontSize: '11px' }}>
                        The data across all of Oko Adviser, including the Portfolio results is based
                        on data provided by external suppliers. The charts and percentages created
                        are based on the available the available holding data as provided. Oko
                        Adviser does not guarantee the accuracy of any information provided by a
                        third party service provider does not exert any editorial control over such
                        information. Oko Adviser does not guarantee the timeliness, sequence,
                        accuracy or completeness of any information provided by a service provider.
                      </p>
                    </Modal>
                  </div>
                ) : null}
              </>
            )}
          {window.location.pathname.endsWith('/results/aplResults') && (
            <h3 style={{ marginBottom: '0' }} className="text-center">
              APL Results for {client?.clientFirstName}
            </h3>
          )}
          {window.location.pathname.endsWith('/results/profileResults') && (
            <h3 style={{ marginBottom: '0' }} className="text-center">
              Profile Results for {client?.clientFirstName}
            </h3>
          )}
          <div
            style={
              selectedTileType === TileType.SustainabilityOverview
                ? { display: 'none' }
                : { display: 'block' }
            }
          >
            <FilterAndSortBy isColorIcon={false} />
          </div>
        </div>
        {/* {(window.location.pathname.includes('/results/portfolioResults') ||
          window.location.pathname.includes('/results')) &&
          !window.location.pathname.includes('/results/aplResults') &&
          !window.location.pathname.includes('/results/profileResults') &&
          resultCountResponse &&
          resultCountResponse.hasProfile &&
          resultCountResponse.hasPortfolio && (
            <Row className="justify-content-center">
              <div className="tips-panel tipCard">
                <img src={tipIcon} alt="icon" className="tip-icon" width={25} />
                {`See how ${client?.clientFirstName}'s portfolio performs based on their sustainability profile created on ${profileCreatedDate}`}
              </div>
            </Row>
          )} */}
        {window.location.pathname.endsWith('/results/aplResults') &&
          resultCountResponse &&
          resultCountResponse.hasProfile && (
            <Row className="justify-content-center">
              <div className="tips-panel tipCard">
                <img src={tipIcon} alt="icon" className="tip-icon" width={25} />
                {`Investments from APL ${aplFileName} analysed against ${client?.clientFirstName}'s profile created on ${profileCreatedDate}`}
              </div>
            </Row>
          )}
        {window.location.pathname.endsWith('/results/profileResults') &&
          resultCountResponse &&
          resultCountResponse.hasProfile && (
            <Row className="justify-content-center">
              <div className="tips-panel tipCard">
                <img src={tipIcon} alt="icon" className="tip-icon" width={25} />
                {`Research investments based on ${client?.clientFirstName}'s sustainability profile created on ${profileCreatedDate}`}
              </div>
            </Row>
          )}
      </Container>
    );
  };

  const renderBody = () => {
    const selectedTile = getSelectedResultsEntry();

    if (loadingResults) {
      return <LoadingPage />;
    }
    if (resultCountResponse && !resultCountResponse.hasProfile && isEmpty(selectedTile?.results)) {
      return (
        <StartQuestionnaire
          startInvestingProfile={() =>
            props.history.push(`/clients/${clientId}/investing-profile/start`)
          }
        />
      );
    }

    if (
      (window.location.pathname.endsWith('/results/portfolioResults') &&
        resultCountResponse &&
        !resultCountResponse?.hasPortfolio &&
        isEmpty(selectedTile?.results) &&
        isEmpty(sustainabilityOverviewResults)) ||
      (resultCountResponse && !resultCountResponse?.hasPortfolio)
    ) {
      return (
        <UploadClientPortfolio
          addPortfolioRedirect={() =>
            props.history.push(`/clients/${clientId}/profile/portfolios/add`)
          }
        />
      );
    }
    if (
      window.location.pathname.endsWith('/results/aplResults') &&
      resultCountResponse &&
      !resultCountResponse?.hasApprovedProductList &&
      isEmpty(selectedTile?.results)
    ) {
      return <UploadAPLPortfolio addAPLRedirect={() => props.history.push(`/aplUpload`)} />;
    }

    if (selectedTile?.type === 'portfolioSustainabilityOverview') {
      return (
        <>
          {/* {isEmpty(sustainabilityOverviewResults) && <LoadingPage />} */}
          {(loadingOverviewResults || loadingSummary) && <LoadingPage />}
          {sustainabilityOverviewResults && resultsSummary && (
            <SustainabilityOverview
              sustainabilityResults={sustainabilityOverviewResults}
              resultsSummary={resultsSummary}
            />
          )}
          {(!loadingOverviewResults || !loadingSummary) &&
            (!sustainabilityOverviewResults || !resultsSummary) && (
              <EmptyResults
                clientFirstName={client?.clientFirstName}
                selectedTileType={selectedTile?.type}
              />
            )}
          {!loadingOverviewResults &&
            !loadingSummary &&
            !resultCountResponse?.hasPortfolio &&
            isEmpty(sustainabilityOverviewResults) && (
              <UploadClientPortfolio
                addPortfolioRedirect={() =>
                  props.history.push(`/clients/${clientId}/profile/portfolios/add`)
                }
              />
            )}
        </>
      );
    } else {
      return (
        <>
          {!isEmpty(selectedTile?.results) && renderGridWithButton()}
          {isEmpty(selectedTile?.results) && (
            <EmptyResults
              selectedTileType={selectedTileType}
              clientFirstName={client?.clientFirstName}
            />
          )}
        </>
      );
    }
  };

  const renderGridWithButton = () => {
    const selectedResultsEntry = getSelectedResultsEntry();
    const selectedTilesResults = selectedResultsEntry?.results;

    let totalCount = 0;
    if (resultCountResponse) {
      switch (selectedTileType) {
        case TileType.AplAlternatives:
          totalCount = resultCountResponse.aplAlternatives;
          break;
        case TileType.AplConflicts:
          totalCount = resultCountResponse.aplConflicts;
          break;
        case TileType.AplMatches:
          totalCount = resultCountResponse.aplMatches;
          break;
        case TileType.PortfolioConflicts:
          totalCount = resultCountResponse.portfolioConflicts;
          break;
        case TileType.PortfolioMatches:
          totalCount = resultCountResponse.portfolioMatches;
          break;
        case TileType.PortfolioAlternatives:
          totalCount = resultCountResponse.portfolioAlternatives;
          break;
        case TileType.ProfileAlternatives:
          totalCount = resultCountResponse.profileAlternatives;
          break;
        case TileType.ProfileConflicts:
          totalCount = resultCountResponse.profileConflicts;
          break;
        case TileType.ProfileMatches:
          totalCount = resultCountResponse.profileMatches;
          break;
        default:
          totalCount = 0;
          break;
      }
    }

    return (
      <>
        <ResultsGrid results={selectedTilesResults} />
        <div className="text-center justify-content-center pt-4">
          {totalCount !== selectedTilesResults?.length && (
            <button onClick={handleLoadMore} className="load-more">
              {!showLoadMoreButton ? 'Load more results ' : <Spinner color="secondary" />}
            </button>
          )}
        </div>

        <br />
      </>
    );
  };

  const renderPage = () => {
    return (
      <Printer
        style={{
          right: '10px',
          top: '10px',
        }}
      >
        {renderHeader()}
        {renderTiles()}
        {renderBody()}
      </Printer>
    );
  };

  return <div style={{ width: '99.9%' }}>{renderPage()}</div>;
}
