import { useContext, useState, useEffect } from 'react';

import { Redirect } from 'react-router-dom';
import countryList from 'react-select-country-list';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Label, Input, Col, Row } from 'reactstrap';

import ValidatorService from 'service/validator';
import { ProfileContext } from 'store/ProfileContext';

import { getAdviser, updateAdviser } from '../../service/AdvisorService';
import { LoadingPage, PageHeader, Dropdown } from '../commons';
import TextField from '../commons/TextField';

import './account.scss';

interface AdvisorInterface {
  firstName: string;
  lastName: string;
  email: string;
  resetPassword: string;
  companyName: string;
  companyPostcode: string;
  companyState: string;
  companyStreetAddress: string;
  companySuburb: string;
  supportNotificationEmail: string;
}

const DetailsEdit = () => {
  const profileCtx = useContext(ProfileContext);
  const [advisor, setAdvisor] = useState<AdvisorInterface>({
    firstName: '',
    lastName: '',
    email: '',
    resetPassword: '',
    companyName: '',
    companyPostcode: '',
    companyState: '',
    companyStreetAddress: '',
    companySuburb: '',
    supportNotificationEmail: '',
  });
  const stateOptions = countryList().getData();
  const [loading, setLoading] = useState<boolean>(true);
  const [completedRedirect, setCompletedRedirect] = useState<boolean>(false);

  useEffect(() => {
    getAdviser()
      .then((response) => {
        setAdvisor(response);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChange = ({ target: { id, value } }: any) => {
    setAdvisor((prevState) => {
      return {
        ...prevState,
        [id]: value,
      };
    });
  };

  const changeSupportEmail = ({ target: { value } }: any) => {
    setAdvisor((prevState) => {
      return {
        ...prevState,
        supportNotificationEmail: value,
      };
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    updateAdviser(advisor)
      .then(() => {
        setCompletedRedirect(true);
      })
      .catch((err) => {
        // this.setState({ showMessage: true, message: err.message });
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLogout = () => {
    profileCtx.logOut();
  };

  if (completedRedirect) {
    return <Redirect push to={{ pathname: '/account' }} />;
  }

  return (
    <div className="slim-content">
      <div className="container">
        <PageHeader
          title="Your details"
          className="mb-10"
          rightButton={
            <Button color="link" onClick={() => handleLogout()} className="mb-05">
              Sign out
            </Button>
          }
        />
        {loading && <LoadingPage />}
        {!loading && (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label for="firstName">First name</Label>
                  <Input
                    maxLength={45}
                    type="text"
                    name="firstName"
                    id="firstName"
                    disabled
                    placeholder={advisor.firstName}
                    onChange={(e: any) => handleChange(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label for="lastName">Last name</Label>
                  <Input
                    maxLength={45}
                    type="text"
                    name="lastName"
                    id="lastName"
                    disabled
                    placeholder={advisor.lastName}
                    onChange={(e: any) => handleChange(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    maxLength={100}
                    type="text"
                    name="email"
                    id="email"
                    // defaultValue={advisor.email}
                    disabled
                    placeholder={advisor.email}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label for="email">Password (only if you want to reset it)</Label>
                  <Input
                    maxLength={100}
                    type="password"
                    name="password"
                    id="resetPassword"
                    defaultValue={advisor.resetPassword}
                    onChange={(e: any) => handleChange(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label for="companyName">Company name</Label>
                  <Input
                    maxLength={250}
                    type="text"
                    name="companyName"
                    id="companyName"
                    defaultValue={advisor.companyName}
                    onChange={(e: any) => handleChange(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label for="companyStreetAddress">Company Street Address</Label>
                  <Input
                    maxLength={45}
                    name="companyStreetAddresss"
                    id="companyStreetAddress"
                    onChange={(e: any) => handleChange(e)}
                    value={advisor.companyStreetAddress}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label for="companySuburb">Company Suburb</Label>
                  <Input
                    maxLength={45}
                    name="companySuburb"
                    id="companySuburb"
                    onChange={(e: any) => handleChange(e)}
                    value={advisor.companySuburb}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup className="mb-30">
                  <Label for="companyState">Company State</Label>
                  <Input
                    type="companyState"
                    name="companyState"
                    id="companyState"
                    value={advisor.companyState}
                    onChange={(e: any) => handleChange(e)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-30">
                  <Label for="companyPostcode">Company Postcode</Label>
                  <Input
                    type="companyPostcode"
                    name="companyPostcode"
                    id="companyPostcode"
                    value={advisor.companyPostcode}
                    onChange={(e: any) => handleChange(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Dropdown
                  className="mb-30"
                  control="country"
                  label="Country"
                  onChange={(e: any) => handleChange(e)}
                  options={stateOptions}
                  placeholder="Australia"
                ></Dropdown>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="support-email">
                Support email
              </Col>
              <Col sm={12}>
                <TextField
                  className="mb-0 support-email-input"
                  onChange={changeSupportEmail}
                  value={advisor.supportNotificationEmail}
                  validateInput={(email: string) => ValidatorService.validateEmail(email)}
                  required={false}
                />
              </Col>
            </Row>
            <div className="text-center">
              <Button
                className="mt-40 mb-40 btn-main-shadowless"
                onClick={(e: any) => handleSubmit(e)}
              >
                Update details
              </Button>
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};

export default DetailsEdit;
