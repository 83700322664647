import { Row } from 'reactstrap';

import DoughnutGraph from './DoughnutGraph';

interface InsightsDoughnutState {
  title: string;
  isLoading: boolean;
  graphItems: InsightsDoughnutGraphState[];
  mostCommon: string;
  leastCommon: string;
  doughnutColor: string[];
}

type InsightsDoughnutGraphState = {
  title: string;
  percent: number;
};

export default function InsightsDoughnutGraph(props: InsightsDoughnutState) {
  return (
    <div className="doughnut-Panel">
      <Row style={{ marginLeft: 15, marginBottom: 35 }}>
        <h4 className="doughnut-title">{props.title}</h4>
      </Row>
      <Row>
        <DoughnutGraph dataItems={props.graphItems} doughnutColor={props.doughnutColor} />
      </Row>
      <Row className="doughnut-footer">
        <div className="doughnut-footer-left">
          Most common:
          <span className="doughnut-footer-text"> {props.mostCommon} </span>
        </div>
        <div className="doughnut-footer-right">
          Least common:
          <span className="doughnut-footer-text"> {props.leastCommon} </span>
        </div>
      </Row>
    </div>
  );
}
