import { createContext, useContext, useEffect, useState } from 'react';

import { getFilters } from 'service/ReferenceService';

import { ProfileContext } from './ProfileContext';

export interface FilterInterface {
  key: string;
  value: string[];
}

export interface FilterContextInterface {
  filters: FilterInterface[];
  loading: boolean;
  commonFilters: string[];
  selectedFilters: string[];
  toggleFilter: (filter: string) => void;
  clearFilters: () => void;
  sortOptions: string[];
  selectedSortBy: string;
  setSelectedSortBy: (sort: string) => void;
  setSelectedFilters: (filters: string[]) => void;
}

export const FilterContext = createContext<FilterContextInterface>({} as FilterContextInterface);

interface FilterResponseInterface {
  filters: FilterInterface[];
  commonFilters: string[];
  sortOptions: string[];
}

export function FilterContextProvider(props: any) {
  const [filters, setFilters] = useState<FilterInterface[]>([]);
  const [commonFilters, setCommonFilters] = useState<string[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [selectedSortBy, setSelectedSortBy] = useState<string>('');
  const [sortOptions, setSortOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const profileContext = useContext(ProfileContext);

  useEffect(() => {
    if (profileContext?.isLoggedIn) {
      setLoading(true);
      getFilters()
        .then((response: FilterResponseInterface) => {
          setFilters(response.filters);
          setCommonFilters(response.commonFilters);
          setSortOptions(response.sortOptions);
        })
        .finally(() => setLoading(false));
    }
  }, [profileContext.isLoggedIn]);

  const selectFilterHandler = (filter: string) => {
    if (selectedFilters.includes(filter)) {
      const newFilters = selectedFilters.filter((i) => i !== filter);
      setSelectedFilters(newFilters);
    } else {
      const newFilters = [...selectedFilters];
      newFilters.push(filter);
      setSelectedFilters(newFilters);
    }
  };

  const clearFiltersHandler = () => {
    setSelectedFilters([]);
  };

  return (
    <FilterContext.Provider
      value={{
        filters,
        loading,
        commonFilters,
        selectedFilters,
        toggleFilter: selectFilterHandler,
        clearFilters: clearFiltersHandler,
        sortOptions,
        selectedSortBy,
        setSelectedSortBy,
        setSelectedFilters,
      }}
    >
      {props.children}
    </FilterContext.Provider>
  );
}
