import { IProfileQuestion } from 'interfaces/responses/profile/IProfileQuestion';

export default function Soft(props: { soft: IProfileQuestion[] }) {
  const { soft } = props;
  return (
    <div style={{ marginTop: '30px', marginBottom: '30px' }} className="dasshed-box">
      <p className="category-title">Most interested in achieving with your investments?</p>
      {soft.length ? (
        soft.map((item, key) => (
          <p className="card-text" key={key} style={{ fontSize: '17px', marginLeft: '15px' }}>
            - {item.question}
          </p>
        ))
      ) : (
        <p style={{ marginLeft: '15px', fontSize: '17px' }} className="category-subtitle">
          No selections were made
        </p>
      )}
    </div>
  );
}
