import { IConflictInstrument } from 'interfaces/responses/adviserOutputs/IConflictInstrument';
import { IMatchInstrument } from 'interfaces/responses/adviserOutputs/IMatchInstrument';

export default function PortfolioBreakdown(props: {
  matchingInstruments: IMatchInstrument[];
  conflictInstruments: IConflictInstrument[];
  clientFirstName: string;
  portfolioUpdatedWhen: string;
  portfolioName: string;
}) {
  const {
    matchingInstruments,
    conflictInstruments,
    portfolioName,
    portfolioUpdatedWhen,
    clientFirstName,
  } = props;
  return (
    <div className="box-advice">
      <h5>Portfolio Breakdown</h5>
      <p>
        The Portfolio Breakdown shows the breakdown of your clients portfolio against their
        Sustainability profile.
      </p>
      <p>
        A Match is when an investment aligns to an area that your client selected in their profile
        as something they wish to support and is does not have exposure to any Conflicts. A Conflict
        is when investments is aligned to an area that the client had selected in their profile as
        something they do not wish to support. Alternatives - also displayed as a &quot;non
        match&quot; anything that is not a match or a conflict
      </p>
      <p>
        Each instrument receives and overall Grades (from A* to F), supported with an easy to follow
        Green, Amber and Red scale. This grading system provides a concise and user-friendly summary
        of a company&apos;s overall ESG performance and makes it easier to compare ratings and find
        solutions that align with your client’s values.Companies are show in the Oko Adviser as: •
      </p>
      <div>
        Based on {clientFirstName}’s sustainability profile, {matchingInstruments.length} matches
        were identified in {portfolioName}, uploaded on {portfolioUpdatedWhen}
        <div style={{ marginTop: '20px' }} className="dasshed-box">
          {matchingInstruments.length ? (
            matchingInstruments.map((item, key) => (
              <div key={key} style={{ marginLeft: '15px' }}>
                <p style={{ margin: '0', paddingTop: '25px', fontSize: '15px' }}>{item.name}</p>
                <p style={{ fontSize: '14px', marginBottom: '40px' }}>
                  because it supports {item.preferences.join(' and ')}
                </p>
              </div>
            ))
          ) : (
            <div>No matching instruments</div>
          )}
        </div>
      </div>
      <div>
        <p style={{ marginTop: '40px', marginBottom: '30px', fontSize: '15px' }}>
          Based on {clientFirstName}’s sustainability profile, {conflictInstruments.length} matches
          were identified in {portfolioName}, uploaded on {portfolioUpdatedWhen}
        </p>
        <div className="dasshed-box">
          {conflictInstruments.length ? (
            conflictInstruments.map((item, key) => (
              <div key={key} style={{ marginLeft: '15px' }}>
                <p style={{ marginTop: '40px', fontSize: '15px' }}>{item.name}</p>
                <p style={{ margin: '0', fontSize: '14px' }}>
                  because it supports {item.exclusions.join(' and ')}
                </p>
              </div>
            ))
          ) : (
            <div>No conflicting instruments</div>
          )}
        </div>
      </div>
    </div>
  );
}
