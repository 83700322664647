import React, { Fragment } from 'react';

import { Row, Col, Button } from 'reactstrap';

import iconLaptop from '../../../../../_assets/img/icons/graphics/laptop@3x.png';

const SuccessUpload = ({ clientName, onBack, onSubmit }) => (
  <Fragment>
    <br />
    <Row className="justify-content-center mt-30">
      <img src={iconLaptop} alt="laptop" width="183" />
    </Row>
    <Row className="justify-content-center mt-10">
      <h3>Success!</h3>
    </Row>
    <Row className="justify-content-center mt-20">
      <p>A portfolio for {clientName} has been uploaded</p>
    </Row>
    <Row className="justify-content-center mt-40">
      <Col sm={4} className="mb-20">
        <Button outline className="btn-secondary" onClick={onBack}>
          Back
        </Button>
      </Col>
      <Col sm={4}>
        <Button className="btn-main-shadowless" onClick={onSubmit}>
          &nbsp;Back to profile results
        </Button>
      </Col>
    </Row>
  </Fragment>
);

export default SuccessUpload;
