import React from 'react';

import { Container } from 'reactstrap';

const InfoModal = ({ onDismiss, params }) => (
  <Container className="container-body-modal">
    <div className="content-body-modal">
      <div className="icon-modal">
        <img src={params.logoUrl} alt={params.commentTitle} width="90" />
      </div>
      <h2 className="title-content-modal mb-40">{params.commentTitle}</h2>
      <p
        className="desc-content-modal mb-40"
        dangerouslySetInnerHTML={{
          __html: params.commentBody.replace(/\\n/g, '<br/>').replace(/\\r/g, ''),
        }}
      ></p>
    </div>
  </Container>
);

export default InfoModal;
