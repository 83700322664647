import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Container, Row } from 'reactstrap';

import {
  getSelectedProfileAnswersOnPageLoad,
  selectUnselect,
} from '../../../_helpers/investing-profile';
import { Behaviour, Category, SelectedAnswers } from '../../../model/InvestingProfile';
import { getBehaviourDetails } from '../../../service/ProfilingService';
import { LoadingPage, StepsBar } from '../../commons';

import BehaviourList from './BehaviourList';

export default function Step4() {
  const history = useHistory();

  const [behaviours, setBehaviours] = useState<Behaviour[]>([]);
  const [selectedAnswers, setSelectedAnswers] = useState<SelectedAnswers>({});
  const { state } = history.location;

  function getPeopleConflictBehaviour(behaviourDetails: Behaviour[]) {
    return behaviourDetails?.filter(
      (x: Behaviour) => x.category === Category['People'] && x.isForConflict,
    );
  }

  useEffect(() => {
    getBehaviourDetails()
      .then((response) => {
        setBehaviours(getPeopleConflictBehaviour(response.profileQandAWithBehaviourDetails));
      })
      .catch((err) => {
        toast.error(err.message);
      });
    setSelectedAnswers(getSelectedProfileAnswersOnPageLoad(state));
  }, [state]);

  const toggleChoice = (answer: string) => {
    return setSelectedAnswers({ ...selectUnselect(selectedAnswers, answer) });
  };

  const handleNext = (e: any) => {
    e.preventDefault();
    if (isEmpty(selectedAnswers)) {
      return toast.error('Choose at least one');
    }
    history.push('step5', Object.keys(selectedAnswers));
  };

  if (isEmpty(behaviours)) {
    return <LoadingPage />;
  } else {
    return (
      <Container className="client-steps">
        <Row className="justify-content-center mt-30">
          <StepsBar step={'step2'} location={'clients'}></StepsBar>
        </Row>
        <br />
        <Row className="text-center justify-content-center mt-30 mb-30">
          <div className="header mb-20">
            <p>
              {`Which impacting `}
              <span className="category-heading">{Category['People']}</span>
              {` issues and business activities do you want to avoid?`}
            </p>
          </div>
          <p className="sub-heading">{`Choose at least one.`}</p>
        </Row>
        <BehaviourList
          behaviours={behaviours}
          selectedOptions={selectedAnswers}
          toggleChoice={toggleChoice}
          imgLocation="GetExclusionQandA"
        />
        <br />
        <Row className="">
          <Col sm={{ size: 6 }} className="mb-2 justify-content-right">
            <Button
              outline
              className="btn-secondary mb-10"
              onClick={() => history.push('step3', state)}
            >
              {`Back`}
            </Button>
          </Col>
          <Col sm={6}>
            <Button
              className="btn-main-shadowless justify-content-left mb-10"
              onClick={(e: any) => handleNext(e)}
            >
              {`Next`}
            </Button>
          </Col>
        </Row>
        <br />
      </Container>
    );
  }
}
