import api, { apiSetup } from '../config/api';

export function createAdviceOutputV2(id) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post(`/v2/advice/create/${id}`)
      .then((res) => {
        if (res.ok && res.status === 200 && res.data.httpStatusCode === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getAdviceOutputV2(id) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .get(`/v2/advice/output/${id}`)
      .then((res) => {
        if (res.ok && res.status === 200 && res.data.httpStatusCode === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}
