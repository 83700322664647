import api, { apiSetup } from '../config/api';
import { ICreateUpdateProfileForAdviserClientRequest } from '../interfaces/requests/profiling/ICreateUpdateProfileForAdviserClientRequest';
import { IAdviserClientMatches } from '../interfaces/responses/profiling/IAdviserClientMatches';
import { IProfileDto } from '../interfaces/responses/profiling/IProfileDto';
import { IProfilingQuestion } from '../interfaces/responses/profiling/IProfilingQuestion';
import { IRefineRequest } from '../interfaces/responses/profiling/IRefineRequest';

import { ApiEndpoints } from './ApiEndpoints';
import { BaseService } from './BaseService';

export abstract class ProfilingService extends BaseService {
  static getInstance() {
    return this;
  }

  public static getPreferenceQandA() {
    return this.callGet<IProfilingQuestion[]>(ApiEndpoints.GetPreferenceQandA);
  }

  public static getExclusionQandA() {
    return this.callGet<IProfilingQuestion[]>(ApiEndpoints.GetExclusionQandA);
  }

  public static getChoiceModellingQandA() {
    return this.callGet<IProfilingQuestion[]>(ApiEndpoints.GetChoiceModellingQandA);
  }

  public static getBehaviourDetails() {
    return this.callGet<IProfilingQuestion[]>(ApiEndpoints.GetBehaviourDetails);
  }

  public static getProfileMatches(params: IRefineRequest) {
    return this.callPost<IAdviserClientMatches>(ApiEndpoints.GetProfileMatches, params);
  }

  public static createUpdateProfile(params: ICreateUpdateProfileForAdviserClientRequest) {
    return this.callPost(ApiEndpoints.CreateUpdateProfile, params);
  }

  public static getActiveProfileByClientId(clientCoreId: string) {
    // console.log(clientCoreId, 'profile clientId');
    // return this.callPost<IProfileDto>(ApiEndpoints.GetActiveProfileByClientId, { clientCoreId });
    const url = '/api/Profiling/GetActiveProfileByClientId';
    return new Promise<IProfileDto>((resolve, reject) => {
      apiSetup();
      api
        .post<IProfileDto>(url, { clientCoreId })
        .then((res) => {
          if (res.ok && res.status === 200 && res.data !== undefined) {
            resolve(res.data);
          } else {
            //todo:- Show Error Message Here
            reject({ error: 'Something went wrong' });
          }
        })
        .catch((err) => {
          reject({ error: err.message });
        });
    });
  }
}
