import { isEmpty } from 'lodash';
import React, { PureComponent } from 'react';

import { withRouter } from 'react-router-dom';
import { Row } from 'reactstrap';

import queryString from 'query-string';

import { LoadingPage } from '../commons';

import LayoutBuilder from './Builder';
import Header from './Header';
import Sidebar from './Sidebar';

class Layout extends PureComponent {
  constructor(props) {
    super(props);
    let builder = this.createFromProps(props);
    if (typeof props.component.buildLayout === 'function') {
      props.component.buildLayout(builder);
    }
    this.state = {
      header: builder.layout.header,
      sidebar: builder.layout.sidebar,
      builder,
      loading: !!this.props.onLoad,
    };
  }

  createFromProps(props) {
    let builder = new LayoutBuilder(this);
    if (props.header && typeof props.header === 'function') {
      props.header(builder);
    }
    if (props.sidebar && typeof props.sidebar === 'function') {
      props.sidebar(builder);
    }
    if (props.sidebarSelect) {
      let pattern = props.location.pathname.match(/results\/\w+/g);
      if (pattern && pattern.length) {
        builder.selectSidebar(pattern[0].replace('/', '.'));
      } else {
        builder.selectSidebar(props.sidebarSelect);
      }
    }
    if (props.headerSelect) {
      builder.selectHeader(props.headerSelect);
    }
    return builder;
  }

  runOnLoadFunction(builder) {
    if (this.props.onLoad) {
      this.props.onLoad({ ...this.props, builder }).then(() => {
        this.setState({ loading: false });
      });
    }
  }

  componentDidMount() {
    this.runOnLoadFunction(this.state.builder);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.component && this.props.component !== prevProps.component) {
      let builder = this.createFromProps(this.props);
      this.setState(() => ({
        header: builder.layout.header,
        sidebar: builder.layout.sidebar,
        builder,
      }));
      this.runOnLoadFunction(builder);
    }
  }

  render() {
    const { header, sidebar, builder } = this.state;
    const { component } = this.props;
    const { classLayout } = this.props;
    const values = queryString.parse(this.props?.location?.search);
    const showSidebar = values && values.v === 'all' ? true : false;
    return (
      <div className={classLayout ? classLayout + ' layout' : 'layout'}>
        {!isEmpty(header) && <Header config={header} />}
        <div className="layout-container">
          {!isEmpty(sidebar.items) && (
            <section className={showSidebar ? 'sidebar d-none' : 'sidebar'}>
              <Sidebar builder={builder} config={header} />
            </section>
          )}
          {this.state.loading && (
            <Row className="content-with-sidebar">
              <LoadingPage />
            </Row>
          )}
          {!this.state.loading && (
            <section className={!isEmpty(sidebar.items) ? 'content-with-sidebar' : 'content'}>
              {React.createElement(component, { ...this.props, builder })}
            </section>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Layout);
