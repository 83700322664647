import { useLocation } from 'react-router-dom';
import { Row, Container, Col } from 'reactstrap';

import CommonFiltersComponent from '_components/research/CommonFiltersComponent';
import { TileType } from 'model/enums';

import sustainabilityOverviewImg from '../../../../_assets/img/results/Group 2@3x.png';
import portfoliAlternativesImg from '../../../../_assets/img/results/Group 4@4x.png';
import aplAlternativesImg from '../../../../_assets/img/results/Group 17@3x.png';
import portfolioConflictsImg from '../../../../_assets/img/results/portfolioConflicts.png';
import portfolioMatchesImg from '../../../../_assets/img/results/portfolioMatches.png';
import profileAlternativesImg from '../../../../_assets/img/results/profileAlternatives.png';
import profileConflictsImg from '../../../../_assets/img/results/profileConflicts.png';
import profileMatchesImg from '../../../../_assets/img/results/profileMatches.png';

import ProfilePortfolioTile from './ProfilePortfolioTile';
import { ResultCountInterface } from './Results';

interface ResultsCountResponse {
  loadingResults: boolean;
  selectedTile: TileType;
  resultCountResponse: ResultCountInterface | undefined;
  handleTileClick: (type: TileType) => void;
}

export default function ResultsProfilePortfolio({
  loadingResults,
  selectedTile,
  handleTileClick,
  resultCountResponse,
}: ResultsCountResponse) {
  const location = useLocation();

  const portfolioResultsURL = location.pathname.includes('/results/portfolioResults');
  const profileResultsURL = location.pathname.endsWith('/results/profileResults');
  const aplResultsURL = location.pathname.endsWith('/results/aplResults');
  const resultsURL = location.pathname.endsWith('/results');
  const isShowingCommonFilters = selectedTile !== TileType.SustainabilityOverview;

  return (
    <Container style={{ width: '75%', maxWidth: '1100px', margin: '0 auto' }}>
      <Row style={{ flexWrap: 'nowrap' }} className="results-profile-portfolio mt-10">
        {(portfolioResultsURL || resultsURL) && (
          <>
            <Col className="tile-col">
              <ProfilePortfolioTile
                imageSrc={sustainabilityOverviewImg}
                heading="Portfolio"
                subHeading="Sustainability Overview"
                count={0}
                showAddIcon={!resultCountResponse?.hasPortfolio}
                onClick={() => handleTileClick(TileType.SustainabilityOverview)}
                isSelected={selectedTile === TileType.SustainabilityOverview}
                loadingResults={loadingResults}
              />
            </Col>
            <Col className="tile-col">
              <ProfilePortfolioTile
                imageSrc={portfolioConflictsImg}
                heading="Portfolio"
                subHeading="Conflicts"
                count={resultCountResponse?.portfolioConflicts}
                showAddIcon={!resultCountResponse?.hasPortfolio}
                onClick={() => handleTileClick(TileType.PortfolioConflicts)}
                isSelected={selectedTile === TileType.PortfolioConflicts}
                loadingResults={loadingResults}
              />
            </Col>
            <Col className="tile-col">
              <ProfilePortfolioTile
                imageSrc={portfolioMatchesImg}
                heading="Portfolio"
                subHeading="Matches"
                count={resultCountResponse?.portfolioMatches}
                showAddIcon={!resultCountResponse?.hasPortfolio}
                onClick={() => handleTileClick(TileType.PortfolioMatches)}
                isSelected={selectedTile === TileType.PortfolioMatches}
                loadingResults={loadingResults}
              />
            </Col>
            <Col className="tile-col">
              <ProfilePortfolioTile
                imageSrc={portfoliAlternativesImg}
                heading="Portfolio"
                subHeading="Alternatives"
                count={resultCountResponse?.portfolioAlternatives}
                showAddIcon={!resultCountResponse?.hasPortfolio}
                onClick={() => handleTileClick(TileType.PortfolioAlternatives)}
                isSelected={selectedTile === TileType.PortfolioAlternatives}
                loadingResults={loadingResults}
              />
            </Col>
          </>
        )}
        {profileResultsURL && (
          <>
            <ProfilePortfolioTile
              imageSrc={profileConflictsImg}
              heading="Profile"
              subHeading="Conflicts"
              count={resultCountResponse?.profileConflicts}
              showAddIcon={!resultCountResponse?.hasProfile}
              onClick={() => handleTileClick(TileType.ProfileConflicts)}
              isSelected={selectedTile === TileType.ProfileConflicts}
              loadingResults={loadingResults}
            />
            <ProfilePortfolioTile
              imageSrc={profileMatchesImg}
              heading="Profile"
              subHeading="Matches"
              count={resultCountResponse?.profileMatches}
              showAddIcon={!resultCountResponse?.hasProfile}
              onClick={() => handleTileClick(TileType.ProfileMatches)}
              isSelected={selectedTile === TileType.ProfileMatches}
              loadingResults={loadingResults}
            />
            <ProfilePortfolioTile
              imageSrc={profileAlternativesImg}
              heading="Profile"
              subHeading="Alternatives"
              count={resultCountResponse?.profileAlternatives}
              showAddIcon={!resultCountResponse?.hasProfile}
              onClick={() => handleTileClick(TileType.ProfileAlternatives)}
              isSelected={selectedTile === TileType.ProfileAlternatives}
              loadingResults={loadingResults}
            />
          </>
        )}
        {aplResultsURL && (
          <>
            <ProfilePortfolioTile
              imageSrc={portfolioConflictsImg}
              heading="APL"
              subHeading="Conflicts"
              count={resultCountResponse?.aplConflicts}
              showAddIcon={!resultCountResponse?.hasProfile}
              onClick={() => handleTileClick(TileType.AplConflicts)}
              isSelected={selectedTile === TileType.AplConflicts}
              loadingResults={loadingResults}
            />
            <ProfilePortfolioTile
              imageSrc={portfolioMatchesImg}
              heading="APL"
              subHeading="Matches"
              count={resultCountResponse?.aplMatches}
              showAddIcon={!resultCountResponse?.hasProfile}
              onClick={() => handleTileClick(TileType.AplMatches)}
              isSelected={selectedTile === TileType.AplMatches}
              loadingResults={loadingResults}
            />
            <ProfilePortfolioTile
              imageSrc={aplAlternativesImg}
              heading="APL"
              subHeading="Alternatives"
              count={resultCountResponse?.aplAlternatives}
              showAddIcon={!resultCountResponse?.hasProfile}
              onClick={() => handleTileClick(TileType.AplAlternatives)}
              isSelected={selectedTile === TileType.AplAlternatives}
              loadingResults={loadingResults}
            />
          </>
        )}
      </Row>
      <Row>
        {isShowingCommonFilters && (
          <CommonFiltersComponent
            showAllOption={false}
            isInApl={window.location.pathname.endsWith('/results/aplResults')}
          />
        )}
      </Row>
    </Container>
  );
}
