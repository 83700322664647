import React from 'react';

import Parser from 'html-react-parser';
import { Container } from 'reactstrap';

const InfoModal = ({ onDismiss, params }) => (
  <Container className="container-body-modal">
    <div className="content-body-modal">
      <div className="icon-modal">
        <img src={params.item.iconUrl} alt="" width="90" />
      </div>
      <div style={{ marginLeft: '6rem', marginRight: '6rem' }}>
        <h2 className="title-content-modal mb-40" style={{ fontSize: '32px', marginTop: '10px' }}>
          {params.item.questionDesc}
        </h2>
      </div>
      <p className="desc-content-modal mb-40 ml-30 mr-40">{Parser(params.item.behaviourDetail)}</p>
    </div>
  </Container>
);

export default InfoModal;
