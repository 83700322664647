import { isEmpty, map } from 'lodash';
import React, { PureComponent, Fragment } from 'react';

import { toast } from 'react-toastify';
import { Container, Row, Col } from 'reactstrap';

import arrowLogo from '../../../_assets/img/icons/nav/Line 2@2x.png';
import iressLogo from '../../../_assets/img/icons/nav/iress-xplan-logo.png';
import { fileEnums } from '../../../_constants';
import { addClientsBulk } from '../../../service/ClientsService';
import FileService from '../../../service/FileService';
import { PageHeader, UploadSelectMethod } from '../../commons';

import ClientCopyPaste from './ClientCopyPaste';
import ClientsUpload from './ClientsUpload';
import SuccessUpload from './SuccessUpload';

class ImportClients extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      uploaderStep: 0,
      closeToolTip: false,
      clients: [],
      filename: '',
      loading: false,
    };
  }

  handleChangeUploader(step) {
    this.setState((state) => ({ uploaderStep: step }));
  }

  async handleDrop(file) {
    if (file) {
      this.setState(() => ({ filename: file && file.path }));
      await FileService.parseClientCsv(file).then((clients) => this.setState(() => ({ clients })));
    }
    const { clients } = this.state;
    if (!isEmpty(clients) && clients.error) {
      toast.error(clients.error);
    }
  }

  handleChange({ target: { id, value } }) {
    this.setState(() => ({ [id]: value }));
  }

  handleDropRejected() {
    this.setState(() => ({ clients: { error: fileEnums.WRONG_FORMAT } }));
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { clients } = this.state;
    this.setState(() => ({ loading: true }));
    const invalidClients = await FileService.validateClientsUpload(clients);
    if (!isEmpty(invalidClients)) {
      const error = `Has errors in the following rows: ${map(
        invalidClients,
        (invalid) => invalid,
      )}`;
      this.setState(() => ({ loading: false }));
      toast.error(error);
      return this.setState(() => ({ clients: { error } }));
    }
    if (!isEmpty(clients) && !clients.error) {
      await this.handleSubmitCopyPaste(clients);
      this.setState(() => ({ loading: false }));
    }
    if (clients.error) {
      toast.error(clients.error);
      this.setState(() => ({ loading: false }));
    }
  }

  goToClientList() {
    this.props.history.push('/clients');
  }

  handleSubmitCopyPaste(clients) {
    this.setState(() => ({ loading: true }));
    addClientsBulk(clients)
      .then(() => {
        this.setState(() => ({ uploaderStep: 2 }));
      })
      .catch((err) => {
        this.setState({ showMessage: true, message: err.message });
        toast.error(err.message);
      })
      .finally(() => this.setState(() => ({ loading: false })));
  }

  handleClick = () => {
    localStorage.setItem('closeToolTip', true);
    this.setState({ closeToolTip: true });
  };

  render() {
    const { uploaderStep, clients, filename, loading } = this.state;
    return (
      <div style={{ width: '1300px' }} className="slim-content">
        <Container style={{ width: '1300px' }}>
          {/* {uploaderStep === 0 && (
            <Fragment>
              <Row>
                <Col sm={12}>
                  <PageHeader title="Import clients" />
                </Col>
              </Row>
              <UploadSelectMethod
                onClickCopyPaste={() => this.handleChangeUploader(3)}
                onClickCSV={() => this.handleChangeUploader(1)}
              />
            </Fragment>
          )} */}
          {uploaderStep === 0 && (
            <Fragment>
              {!localStorage.getItem('closeToolTip') &&
                localStorage.getItem('isXplanConnected') === false && (
                  <>
                    <div className="tooltip-container">
                      <button className="tooltip-button" onClick={this.handleClick}>
                        x
                      </button>
                      <p className="tooltip-text">Connect to import clients and portfolios</p>
                      <img src={iressLogo} width={70} />
                    </div>
                    <img src={arrowLogo} className="tooltip-arrow" width={70} />
                  </>
                )}
              <Row style={{ marginTop: '-2rem' }}>
                <Col sm={12}>
                  <PageHeader title="Upload file" />
                </Col>
              </Row>
              <ClientsUpload
                onDrop={(files) => this.handleDrop(files[0])}
                onDropRejected={(e) => this.handleDropRejected(e)}
                onBack={() => this.handleChangeUploader(0)}
                onSubmit={(e) => this.handleSubmit(e)}
                onChange={(e) => this.handleChange(e)}
                error={clients.error}
                filename={filename}
                loading={loading}
              />
            </Fragment>
          )}
          {uploaderStep === 2 && (
            <Fragment>
              <Row>
                <Col sm={12}>
                  <PageHeader title="Copy / paste" />
                </Col>
              </Row>
              <ClientCopyPaste
                onBack={() => this.handleChangeUploader(0)}
                onSubmit={(clients) => this.handleSubmitCopyPaste(clients)}
                loading={loading}
              />
            </Fragment>
          )}
          {uploaderStep === 1 && <SuccessUpload goToClientList={() => this.goToClientList()} />}
          <Row className="mt-40 mb-40">&nbsp;</Row>
        </Container>
      </div>
    );
  }
}

export default ImportClients;
