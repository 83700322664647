/* eslint-disable prefer-spread */
import { Bar } from 'react-chartjs-2';
import { Row } from 'reactstrap';

interface AverageActionsState {
  isLoading: boolean;
  averageItems: InsightsAverageActionsState[];
  note: string;
  barColor: string;
}

type InsightsAverageActionsState = {
  title: string;
  data: number;
};

export default function InsightsAverageActions(props: AverageActionsState) {
  const data = {
    labels: props.averageItems.map((item) => item.title.split(' ')),
    datasets: [
      {
        data: props.averageItems.map((averageItem) => averageItem.data),
        backgroundColor: props.barColor,
        barPercentage: 0.5,
      },
    ],
  };
  const dataNumbers = data.datasets[0].data;
  // const MaxData = Math.max(...dataNumbers);
  const MaxData = Math.max.apply(
    Math,
    dataNumbers,
    // props.averageItems.map(function (o) {
    //   return o.data;
    // }),
  );
  // console.log(dataNumbers);
  // console.log(MaxData);

  return (
    <div className="advice-panel">
      <Row style={{ marginTop: 17 }}>
        <Bar
          options={{
            plugins: {
              tooltip: {
                enabled: false,
              },
              legend: { display: false },

              datalabels: {
                display: true,
                color: '#fff',
              } as any,
            },

            scales: {
              yAxes: {
                display: false,
                ticks: {
                  maxTicksLimit: Math.round(MaxData * 1.4),
                  // beginAtZero: true,
                },
                grid: {
                  display: false,
                },
              },

              xAxes: {
                grid: {
                  display: false,
                },
              },
            },
          }}
          data={data}
        />
      </Row>
      <Row>
        <p className="advice-panel-note">
          <b style={{ fontWeight: '900', fontSize: 10 }}>Note:</b> {props.note}
        </p>
      </Row>
    </div>
  );
}
