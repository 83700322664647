import { PureComponent, useContext, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { Row } from 'reactstrap';

import Auth from 'service/AuthService';
import { ProfileContext } from 'store/ProfileContext';

import { setSessionToken } from '../../service/LocalStorageService';

const Authorize = (props) => {
  const profileContext = useContext(ProfileContext);
  useEffect(() => {
    try {

      var code = props.location.search;
      var res = code.substring(6);
      Auth.loginWithCode(res)
        .then((response) => {
          if (response.data.adviserUser) {
            setSessionToken(response.data.userToken.success_token);
            profileContext.setProfile(response.data.adviserUser);
          } else if (response.data.httpStatusCode === 400 || response.data.httpStatusCode === 401) {
            toast.error(response.data.message);
          }
        })
        .catch((exception) => {
          console.log(exception);
        });
    } catch (err) {
      console.log(err);
    }

    if (profileContext.profile != null && !profileContext.hasSubscription) {
      props.history.push('/account/chooseSubscription');
    }

  }, [profileContext]);

  return (
    <Row className="layout window-in-two full-height centered">
    </Row>
  );
};

export default Authorize;
