import React from 'react';

import { Col, Row } from 'reactstrap';

import noResults from '../../_assets/img/icons/graphics/manMag@3x.png';

interface NoInstrumentViewProps {
  message: string;
}

const NoInstrumentView = (props: NoInstrumentViewProps) => {
  const { message } = props;
  return (
    <>
      <Row className="justify-content-center mt-5" style={{ marginBottom: '1em' }}>
        <img src={noResults} alt="No results" width="155" />
      </Row>
      <Row>
        <Col sm={12}>
          <p className="text-center text-darkblue font-weight-bolder font-18 letter-spacing">
            {message}
          </p>
        </Col>
      </Row>
    </>
  );
};

export default NoInstrumentView;
