import { PureComponent, useContext, useEffect, useState } from 'react';

import PasswordMask from 'react-password-mask';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, FormGroup, Label, Input, FormText, Row, Col } from 'reactstrap';

import Auth from 'service/AuthService';
import { ProfileContext } from 'store/ProfileContext';

import eye from '../../_assets/img/icons/forms/eye@3x.png';
import AdviserLogo from '../../_assets/img/logo/adviser-logo-small.svg';
import OkoLogo from '../../_assets/img/logo/oko-logo-no-background-padding.svg';
import { setSessionToken } from '../../service/LocalStorageService';
import { ButtonLoading } from '../commons';
import animationData from '../../_assets/lotties/sign-in.json';
import Lottie from 'react-lottie-player';

const Login = (props) => {
  const profileContext = useContext(ProfileContext);
  const [Email, setEmail] = useState('');
  const [Password, setPassword] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const ErrorValidationLabel = ({ txtLbl }) => (
    <Label htmlFor="" style={{ color: 'red' }}>
      {txtLbl}
    </Label>
  );
  const renderErrorLabel = showMessage ? <ErrorValidationLabel txtLbl={message} /> : '';

  const handleInputChange = ({ target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.name == 'Email') {
      setEmail(value);
    }
    if (target.name == 'Password') {
      setPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const params = {
      Email: Email,
      Password: Password,
    };
    try {
      Auth.login(params)
        .then((response) => {
          if (response.data?.adviserUser) {
            setSessionToken(response.data.userToken.success_token);
            profileContext.setProfile(response.data?.adviserUser);
          } else if (response.data.httpStatusCode === 400 || response.data.httpStatusCode === 401) {
            toast.error(response.data.message);
          }
        })
        .catch((exception) => {
          console.log(exception);
        }).finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (profileContext.profile != null && !profileContext.hasSubscription) {
      props.history.push('/account/chooseSubscription');
    }
  }, [profileContext]);

  return (
    <div className='full-height full-width' style={{ backgroundColor: 'black' }}>
      <Row className="layout window-in-two full-height centered">
        <Col md={{ size: 5, offset: 1 }} xs="12" className="pl-0 pr-0">
          <div className="login-left-side">
            <div className="wizard-top-title text-left">
              <div style={{
                gap: '3px'
              }} className='d-inline-flex align-items-end'>
                <img src={OkoLogo} height="39" alt="Oko Adviser Logo" />
                <img style={{
                  paddingBottom: '2px'
                }} src={AdviserLogo} height="20" alt="Adviser Logo" />
              </div>
              <h2 className="sign-up-title">Sign in</h2>
            </div>
            <Form className="white-form signin-form" onSubmit={(e) => handleSubmit(e)}>
              <FormGroup>
                <Label for="email">Email address</Label>
                <Input
                  type="text"
                  name="Email"
                  id="email"
                  required
                  onChange={(e) => handleInputChange(e)}
                />
              </FormGroup>
              <FormGroup className="mb-10 mt-20">
                <Label for="password">Password</Label>
                <PasswordMask
                  maxLength={30}
                  required
                  id="password"
                  name="Password"
                  inputClassName="form-control"
                  buttonClassName="input-toggle"
                  showButtonContent={<img alt="eye-slash" className="eye-password" src={eye} />}
                  hideButtonContent={<img alt="eye" className="eye-password" src={eye} />}
                  value={Password}
                  onChange={(e) => handleInputChange(e)}
                />
              </FormGroup>
              <FormText className="mt-20">
                <p>
                  <Link className='forgot-password' to="/forgotpassword">Forgot password?</Link>
                </p>
              </FormText>
              {renderErrorLabel}
              <div className="text-center btn-blue">
                <ButtonLoading className='sign-in-btn mb-20'
                  label="Sign in"
                  loading={loading}
                  size="sm"
                  color="primary"
                  block
                />
                <small className="don-t-have-an-accoun form-text">
                  Don't have an account?&nbsp;
                  <Link className='sign-up-link' to="/register">Sign up</Link>
                </small>
              </div>
            </Form>
          </div>
        </Col>
        <Col md="6" xs="12" className="pl-0 overflow-hidden pr-0 d-none d-sm-block">
          <Lottie animationData={animationData} loop={true} play={true} rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }} className='sign-in-lottie' />
        </Col>
      </Row>
    </div>
  );
};

export default Login;
