import { getTooltipLabel } from '../utils';

import alternatives from './alternatives';
import clientDefaultRow from './clientDefaultRow';
import clientRow from './clientRow';
import clients from './clients';
import companies from './companies';
import companiesConflict from './companiesConflict';
import companiesShort from './companiesShort';
import conflicts from './conflicts';
import defaultSortBy from './defaultSortBy';
import fileEnums from './file';
import portfolioDefaultRow from './portfolioDefaultRow';
import portfolioRow from './portfolioRow';
import questions from './questions';
import resetStatusEnum from './resetStatus';
import resetStatusOptions from './resetStatusOptions';
import topics from './topics';

const optionChart = {
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: getTooltipLabel,
      },
      enabled: false,
    },
  },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  events: [],
  maintainAspectRatio: true,
  cutout: '75%',
  responsive: true,
};

const optionsLineChart = {
  responsive: true,
  maintainAspectRatio: true,
  legend: {
    display: true,
    position: 'top',
    labels: {
      fontColor: '#2E3074',
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    xAxes: [
      {
        display: false,
      },
    ],
    yAxes: [
      {
        display: true,
      },
    ],
  },
};

const typeFilter = {
  ALTERNATIVES: 'alternatives',
  MATCHES: 'matches',
  CONFLICTS: 'conflicts',
};

export { typeFilter };
export { questions };
export { companiesConflict };
export { clients };
export { companies };
export { optionChart };
export { optionsLineChart };
export { alternatives };
export { conflicts };
export { companiesShort };
export { defaultSortBy };
export { resetStatusEnum };
export { resetStatusOptions };
export { fileEnums };
export { topics };
export { clientDefaultRow };
export { clientRow };
export { portfolioDefaultRow };
export { portfolioRow };
