// import React, { useContext, useState } from 'react';
// import { useHistory } from 'react-router-dom';
// import { Container } from 'reactstrap';
import { useContext } from 'react';

import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';

import Auth from 'service/AuthService';
import { ProfileContext } from 'store/ProfileContext';

// import Auth from 'service/AuthService';
// import { ProfileContext } from 'store/ProfileContext';
// import closeIcon from '../../../../_assets/img/icons/global/close@2x.png';
// import iressLogo from '../../../../_assets/img/icons/nav/iress-xplan-logo.png';
import bulb from '../../../../_assets/img/icons/profile/Bulb@1.5x.png';
import check from '../../../../_assets/img/icons/profile/Complete@3x.png';
import welcomeImg from '../../../../_assets/img/icons/profile/WelcomeImg.svg';
import arrowNext from '../../../../_assets/img/icons/profile/arrowNext.svg';

import './Welcome.scss';
import WelcomeCompliiScreen from './Welcome/CompliiView';

interface WelcomeProps {
  code: string;
  next: () => {};
}

const Welcome = (props: WelcomeProps) => {
  // const [showPopup, setShowPopup] = useState(false);
  const profileContext = useContext(ProfileContext);

  const history = useHistory();

  const handleClick = () => {
    Auth.setWelcomeScreen().then(() => {
      Auth.getUser().then((params) => {
        if (params.ok && params.data != null) {
          profileContext.setProfile(params.data.adviserUser);
          history.push('/clients');
        }
      });
    });
  };

  return (
    <>
      {profileContext.isComplii ? (
        <WelcomeCompliiScreen />
      ) : (
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '1300px',
          }}
          className="mt-60"
        >
          <div className="welcome">
            <div style={{ display: 'flex', gap: '14px', justifyContent: 'space-between' }}>
              <div style={{ padding: '40px' }}>
                <h2 className="welcome-title">{`Welcome to Oko Adviser`}</h2>
                <div className="mt-50">
                  <Row>
                    <Col xs="1">
                      <img src={check} alt="check" width="20" />
                    </Col>
                    <Col xs="11" className="welcome-text" style={{ paddingLeft: '5px' }}>
                      {`Digital Sustainable Fact Find`}
                    </Col>
                  </Row>
                </div>
                <div className="mt-20">
                  <Row>
                    <Col xs="1">
                      <img src={check} alt="check" width="20" />
                    </Col>
                    <Col xs="11" className="welcome-text" style={{ paddingLeft: '5px' }}>
                      {`Unlimited analysis on client profiles and portfolios`}
                    </Col>
                  </Row>
                </div>
                <div className="mt-20">
                  <Row>
                    <Col xs="1">
                      <img src={check} alt="check" width="20" />
                    </Col>
                    <Col xs="11" className="welcome-text" style={{ paddingLeft: '5px' }}>
                      {`Access to sustainability data on over 100,000+ shares, ETFs and Funds`}
                    </Col>
                  </Row>
                </div>
                <div className="mt-20">
                  <Row>
                    <Col xs="1">
                      <img src={check} alt="check" width="20" />
                    </Col>
                    <Col xs="11" className="welcome-text" style={{ paddingLeft: '5px' }}>
                      {'Full access to create, export and store compliance and advice documents'}
                    </Col>
                  </Row>
                </div>
                <div className="mt-20">
                  <Row>
                    <Col xs="1">
                      <img src={check} alt="check" width="20" />
                    </Col>
                    <Col xs="11" className="welcome-text" style={{ paddingLeft: '5px' }}>
                      {`Want a cheaper plan? Get others to sign up with your invite code `}
                      {<span className="welcome-code">{props.code}</span>}
                    </Col>
                  </Row>
                </div>
                <div className="mt-20">
                  <Row>
                    <Col xs="11" className="welcome-text" style={{ paddingLeft: '65px' }}>
                      {`Plus much more....`}
                    </Col>
                  </Row>
                </div>
                <div
                  style={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    gap: '10px',
                    alignItems: 'center',
                    paddingLeft: '65px',
                  }}
                  className="mt-20"
                >
                  <div className="welcome-button">
                    <Button
                      style={{ width: '286px', height: '56px' }}
                      className="btn-main-shadowless"
                      onClick={handleClick}
                      // onClick={() => {xt}
                      // onClick={() => {
                      //   if (profileContext.featureFlags.xPlanIntegration !== undefined) {
                      //     setShowPopup(true);
                      //   } else {
                      //     props.next();
                      //   }
                      // }}
                    >
                      Got it - lets go <img src={arrowNext} />
                    </Button>
                  </div>
                  <div className="tip">
                    <span>
                      <img src={bulb} alt="bulb" width="20" />
                    </span>
                    &nbsp; {`Start by uploading your client list`}
                  </div>
                </div>
              </div>
              <img style={{ height: '600px' }} src={welcomeImg} alt="welcome" />
            </div>
          </div>

          {/* {showPopup && (
      <div className="overlay">
        <Container className="showup-modal modal-width">
          <div className="close-button mb-15" onClick={() => setShowPopup(false)}>
            <img src={closeIcon} width="20" />
          </div>
          <Row className="mt-30 mb-30 justify-content-center">
            <img src={iressLogo} width="150" />
          </Row>
          <Row className="text-center justify-content-center">
            <div className="text-center justify-content-center">
              <h2 className="mb-30">Use Xplan?</h2>
              <p
                style={{ fontSize: 25, color: 'black' }}
              >{`Instantly import your Client Lists and Portfolios.`}</p>
            </div>
          </Row>
          <br />
          <br />
          <Row className="justify-content-center">
            <Col sm={5}>
              <div className="text-center btn-blue">
                <ButtonLoading
                  className="btn-main-shadowless mb-20"
                  label="Connect now"
                  onClick={handleClick}
                />
              </div>
            </Col>
          </Row>
          <Row className="text-center padding-20">
            <Col sm={12} className="mb-10">
              <Button color="link" className="text-center text-darkblue" onClick={props.next}>
                Skip
              </Button>
            </Col>
          </Row>
          <br />
        </Container>
      </div>
    )} */}
        </div>
      )}
    </>
  );
};
export default Welcome;
