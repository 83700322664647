import { useContext } from 'react';

import { ProfileContext } from 'store/ProfileContext';

// eslint-disable-next-line no-undef
const { REACT_APP_STRIPE_PRICE_PAGE_ID, REACT_APP_STRIPE_PAGE_KEY } = process.env;

export default function ChooseSubscription() {
  const profileContext = useContext(ProfileContext);
  const clientReferenceId = profileContext.profile.coreId;
  return (
    <stripe-pricing-table
      pricing-table-id={REACT_APP_STRIPE_PRICE_PAGE_ID}
      publishable-key={REACT_APP_STRIPE_PAGE_KEY}
      client-reference-id={clientReferenceId}
    />
  );
}
