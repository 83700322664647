import React, { useContext, useState } from 'react';

import Parser from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import { Button, Col, Container, Input, Label, Row } from 'reactstrap';

import { ProfileContext } from 'store/ProfileContext';

import termsDoc from '../../../_assets/documents/Oko Adviser Terms of Service.pdf';

import ProfileBuilder from './../../../_assets/img/icons/profileIntro/profileBuilder.svg';

const disclaimer = [
  {
    title: 'Support',
    description:
      'Choose the lifestyle and business behaviours to <span style="font-family: Chapeau-Medium; font-weight: bolder">support</span> across Planet, People and Ethic issues.',
  },
  {
    title: 'Avoid',
    description:
      'Choose the lifestyle and business behaviours to <span style="font-family: Chapeau-Medium; font-weight: bolder">avoid</span> across Planet, People and Ethic issues.',
  },
  {
    title: 'Assess',
    description:
      'Your Adviser will <span style="font-family: Chapeau-Medium; font-weight: bolder">Analyse</span> and <span style="font-family: Chapeau-Medium; font-weight: bolder">assess</span> your results which are based on your personal profile and portfolios.',
  },
];
export default function Start() {
  const history = useHistory();

  const profileContext = useContext(ProfileContext);
  const featureFlag = profileContext.featureFlags?.profileQuestionsV2;

  const [checked, setChecked] = useState(false);

  const handleCheck = () => {
    setChecked((prevValue) => !prevValue);
  };

  const handleClick = () => {
    // if url contains "investing-profile/welcome" || featureFlag
    if (window.location.pathname.endsWith('/investing-profile/welcome') || featureFlag) {
      history.push('lifestyles');
    } else {
      history.push('step1');
    }
  };

  return (
    <>
      <Container className="investing-profile start">
        <Row className="mt-5">
          <h2 className="d-block text-center w-100 mt-30">{`Create a personal sustainability value profile`}</h2>
        </Row>
        <Row className="mb-5">
          <p
            className="d-block text-center w-75 mx-auto"
            style={{ fontSize: '22px' }}
          >{`Follow the steps to create your profile. Our systems and your Adviser will do the rest`}</p>
        </Row>
        <div style={{ paddingBottom: '35px' }}>
          <img
            style={{ width: '289px', height: '246px' }}
            className="d-block text-center w-100"
            src={ProfileBuilder}
            alt="ProfileBuilder"
          />
        </div>
        <Row>
          {disclaimer.map((item, idx) => (
            <Col xs={12} sm={4} key={idx}>
              <div className="d-flex justify-contents-center align-items-center flex-column">
                <h4>{item.title}</h4>
                <p className="text-center" style={{ fontSize: '17px', padding: '0 .5em' }}>
                  {Parser(item.description)}
                </p>
              </div>
            </Col>
          ))}
        </Row>
        <Row className="justify-content-center mt-5 mb-5">
          <Label className="terms-and-condition">
            <Input
              style={{ position: 'relative', top: '0px', marginTop: '0.18rem' }}
              type="checkbox"
              checked={!!checked}
              onChange={handleCheck}
            />
            &nbsp; {`Please accept `}
            <a href={termsDoc} target="_blank" rel="noopener noreferrer">
              {`Terms of Use`}
            </a>{' '}
            {`to continue`}
          </Label>
        </Row>
        <Row className="justify-content-center">
          <Col sm={5} className="mb-2">
            <Button outline className="btn-secondary" onClick={() => history.goBack()}>
              {`Cancel`}
            </Button>
          </Col>
          <Col className="text-center mb-5" style={{ marginTop: '0px' }} sm={5}>
            <Button
              className=" btn-blue mt-0 btn-main-shadowless"
              disabled={!checked}
              onClick={handleClick}
            >{`Start your Profile`}</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
