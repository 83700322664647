import React, { Fragment, useState } from 'react';

import Dropzone from 'react-dropzone';
import { Button, Row, Col, Form } from 'reactstrap';

import PropTypes from 'prop-types';

import tipIcon from '../../../../../_assets/img/icons/profile/Bulb@1.5x.png';
import ValidatorService from '../../../../../service/validator';
import { ButtonLoading, PortfolioExampleCard, TextField } from '../../../../commons';
import '../../../../commons/PortfolioUpload.scss';

const feedbackClassName = (filename, error) => {
  if (error) {
    return 'dropzone justify-content-center is-invalid form-control';
  }
  if (filename && !error) {
    return 'dropzone justify-content-center is-valid form-control';
  }
  return 'dropzone justify-content-center';
};

const PortfolioUpload = ({
  onDrop,
  onDropRejected,
  filename,
  onBack,
  onSubmit,
  error,
  loading,
  onChange,
  portfolio,
}) => {
  return (
    <>
      <Form onSubmit={(e) => onSubmit(e)}>
        <PortfolioExampleCard />
        <Row className="mt-10">
          <Col xs={12} className="mt-1">
            <p className="subtitle">Give the portfolio a name</p>
          </Col>
          <Row style={{ width: '100%' }}>
            <Col xs={8}>
              <TextField
                control="PortfolioName"
                onChange={onChange}
                value={portfolio.PortfolioName}
                required
                validateInput={(input) => ValidatorService.validateInputTextOrNumber(input, 30)}
              />
              <div className="tips-panel">
                <img src={tipIcon} alt="icon" className="tip-icon" />
                Acceptable file types: CSV or tab-delimited text files.
              </div>
            </Col>
            <Col xs={4}>
              <Dropzone {...{ onDrop, onDropRejected, noClick: true }} accept=".csv">
                {({ getRootProps, getInputProps, open }) => (
                  <Fragment>
                    <Button onClick={open} className="browser-button">
                      Select file
                    </Button>
                    <Row
                      {...getRootProps({ refKey: 'input' })}
                      className={feedbackClassName(filename, portfolio.error)}
                    >
                      <input {...getInputProps()} />
                      <p className="text-comment">{!filename ? '' : filename}</p>
                    </Row>
                  </Fragment>
                )}
              </Dropzone>
            </Col>
          </Row>
        </Row>
        <Row className="mt-7">
          <Col xs={8} className="d-flex justify-content-center align-items mt-2">
            <ButtonLoading
              className="btn-main-shadowless"
              label="Upload to profile"
              loading={loading}
              disabled={!filename || loading}
              small
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

PortfolioUpload.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onDropRejected: PropTypes.func.isRequired,
  filename: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

PortfolioUpload.defaultProps = {
  filename: null,
};

export default PortfolioUpload;
