import { filter } from 'lodash';

import Presents from './Presets';

export default class LayoutBuilder {
  layout = {
    header: {
      items: [],
      banner: null,
    },
    sidebar: {
      items: [],
      backButton: null,
    },
  };
  component = {};

  constructor(component) {
    this.component = component;
  }

  addHeader(id, title, link, icon) {
    this.layout.header.items.push({
      id: id,
      icon: icon,
      link: link,
      title: title,
      selected: false,
    });
  }

  selectHeader(id) {
    this.layout.header.items.map((item) => (item.selected = item.id === id));
  }

  addSidebar(id, title, link, icon = null) {
    let ids = this.getIds(id);
    let itemId = ids.pop();
    let parentId = ids.pop();

    const item = {
      id: itemId,
      icon: icon,
      link: link,
      title: title,
      items: [],
      parent: parentId,
      selected: false,
    };

    this.layout.sidebar.items.push(item);
  }

  getSidebar(parentId = undefined) {
    return filter(this.layout.sidebar.items, (item) => item.parent === parentId);
  }

  selectSidebar(id) {
    let ids = this.getIds(id);
    let itemId = ids.pop();
    let parentId = ids.pop();
    this.layout.sidebar.items.map(
      (item) => (item.selected = item.id === itemId || item.id === parentId),
    );
  }

  updateSidebar(id, data) {
    this.layout.sidebar.items = this.layout.sidebar.items.map((item) => {
      return item.id === id ? { ...item, ...data } : item;
    });
    this.component.forceUpdate();
  }

  loadHeader(header) {
    return Presents[header] && this.addHeader(Presents[header]);
  }

  getIds(id) {
    let path = id.split('.');
    return path.map((item, idx) => path.slice(0, idx + 1).join('.'));
  }

  addBackButton(id, title, link, clientId) {
    this.layout.sidebar.backButton = { id, title, link, clientId };
  }
}
