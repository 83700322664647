import { noop } from 'lodash';
import React, { useState, Fragment } from 'react';

import { Button, Modal, ModalBody } from 'reactstrap';

import closeIcon from '../../_assets/img/icons/global/close@2x.png';
import filter from '../../_assets/img/icons/global/filter 2@3x.png';
import filtersAndSort from '../../_assets/img/results/filters_sort.png';

import './ButtonModal.scss';

interface ButtonModalProps {
  component: React.ElementType;
  onSubmit?: () => void;
  header?: string;
  name?: string;
  className?: string;
  params?: Record<string, unknown> | string | number | (() => void);
  showImage?: boolean;
  handleClick?: (totalSelectedFilters: any) => void;
  isColorIcon?: boolean;
  totalSelectedFilters?: number;
  isSearchModal?: boolean;
}

const ButtonModal = ({
  onSubmit = noop,
  name = 'Button',
  header,
  params,
  className = 'btn-modal',
  component: Component,
  showImage,
  handleClick,
  isColorIcon,
  totalSelectedFilters,
  isSearchModal,
  ...props
}: ButtonModalProps) => {
  const [modal, setModal] = useState(false);
  const backdrop = false;
  const keyboard = true;

  const handleSubmit = () => {
    setModal(false);
    onSubmit();
  };

  const renderButtonOrIcon = () => {
    if (showImage) {
      return (
        <div
          className="cursor-hand filter-button"
          onClick={(e) => {
            e.stopPropagation();
            setModal(true);
          }}
          {...props}
        >
          {isColorIcon ? (
            <img src={filter} width="25" style={{ marginLeft: '0.5em', marginRight: '0.7em' }} />
          ) : (
            <img src={filtersAndSort} style={{ marginLeft: '0.5em', marginRight: '0.7em' }} />
          )}
        </div>
      );
    }
    return (
      <Button
        className={className}
        onClick={(e: any) => {
          e.stopPropagation();
          setModal(true);
        }}
        {...props}
      >
        {name}
      </Button>
    );
  };
  return (
    <Fragment>
      {renderButtonOrIcon()}
      {modal && isSearchModal ? (
        <Modal
          isOpen={modal}
          toggle={() => setModal(false)}
          backdrop={backdrop}
          keyboard={keyboard}
          className="custom-modal-style"
        >
          <ModalBody className="gray-modal" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
            <div className="d-flex filter-header">
              <div className="d-flex">
                <span className="filter-header-title">{header}</span>
                <span className="filter-tag" style={{ top: '1.3em', left: '8em' }}>
                  {totalSelectedFilters}
                </span>
              </div>

              <div
                className="d-flex"
                onClick={(e) => {
                  e.stopPropagation();
                  setModal(false);
                }}
              >
                <div>
                  <img src={filter} alt="X" height="22" />
                </div>
                <div style={{ marginLeft: '5px', color: 'lightgray' }}>Filter</div>
              </div>
            </div>
            <div className="modal-filter-sort-content d-flex flex-column">
              <div className="modal-common-filters-header">Common Filters:</div>

              <div className="modal-filter-sort-body d-flex">
                <Component
                  onSubmit={() => handleSubmit()}
                  onDismiss={() => setModal(false)}
                  params={params}
                  handleClick={
                    handleClick
                      ? (totalSelectedFilters: any) => handleClick(totalSelectedFilters)
                      : undefined
                  }
                  isSearchModal={isSearchModal}
                />
              </div>
            </div>
          </ModalBody>
        </Modal>
      ) : (
        <Modal isOpen={modal} toggle={() => setModal(false)}>
          <ModalBody className="gray-modal">
            <span
              className="cursor-hand x-modal-filter"
              onClick={(e) => {
                e.stopPropagation();
                setModal(false);
              }}
            >
              <img src={closeIcon} alt="X" height="24" />
            </span>
            <div className="modal-filter-sort-content d-flex flex-column">
              <div className="modal-filter-header d-flex" style={{ color: '#3e61df' }}>
                <h2 className="d-flex">
                  {header}

                  {totalSelectedFilters ? (
                    <h2 style={{ marginLeft: '5px' }}>{`(${totalSelectedFilters})`}</h2>
                  ) : null}
                </h2>
              </div>
              <div className="modal-filter-sort-body d-flex">
                <Component
                  onSubmit={() => handleSubmit()}
                  onDismiss={() => setModal(false)}
                  params={params}
                  handleClick={
                    handleClick
                      ? (totalSelectedFilters: any) => handleClick(totalSelectedFilters)
                      : undefined
                  }
                />
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </Fragment>
  );
};

export default ButtonModal;
