import React from 'react';

import Layout from '../ui/Layout';
import Presets from '../ui/Presets';

import InsightsPage from './InsightsPage';

const Insights = () => <Layout header={Presets.main} component={InsightsPage} />;

export default Insights;
