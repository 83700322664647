import { isEmpty } from 'lodash';
import React from 'react';

import { IConflictMatchItemDto } from 'interfaces/responses/instrument/IConflictMatchItemDto';
import { IReason } from 'interfaces/responses/reasons/IReasonsDto';

import BusinessActivityWithPopup from '../commons/BusinessActivityWithPopup';
import NoDataView from '../commons/Instrument/NoDataView';

interface MatchConflict {
  conflicts: IConflictMatchItemDto[];
  matches: IConflictMatchItemDto[];
}
interface RelatedIssuesProps {
  relatedIssues?: MatchConflict;
  instrumentName?: string;
  dataLastUpdated?: string;
  reasons: IReason[];
}
const RelatedIssues = (props: RelatedIssuesProps) => {
  const { relatedIssues, instrumentName, dataLastUpdated, reasons } = props;
  let combinedArray = [];

  const renderContent = () => {
    if (isEmpty(relatedIssues)) {
      return (
        <>
          <NoDataView />
        </>
      );
    }
    combinedArray = relatedIssues ? relatedIssues.conflicts.concat(relatedIssues.matches) : [];
    return (
      <div className="d-flex flex-wrap">
        {combinedArray.map((item: any, key) => (
          <div key={key}>
            <BusinessActivityWithPopup
              item={item}
              reason={reasons.find((reason) => reason.questionId === item.id)?.reason ?? ''}
              instrumentName={instrumentName}
              dataLastUpdated={dataLastUpdated}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <p>{`${instrumentName} has been identified as being involved in the following areas`}</p>
      {renderContent()}
    </>
  );
};

export default RelatedIssues;
