import { isEmpty } from 'lodash';
import React, { PureComponent, Fragment } from 'react';

import { toast } from 'react-toastify';
import { Col, Row, Button, Container } from 'reactstrap';

import { addClientApl } from 'service/AplService';
import FileService from 'service/FileService';

import { fileEnums } from '../../../_constants';
import { PageHeader } from '../../commons';

import AplSummary from './AplSummary';
import AplUpload from './AplUpload';

class AplAdd extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      apl: {
        approvedProductListItems: [],
        name: '',
        error: null,
      },
      loading: false,
    };
  }

  handleDropRejected() {
    this.setState(() => ({ apl: { error: fileEnums.WRONG_FORMAT } }));
  }

  async componentDidMount() {}

  async handleDrop(file) {
    if (file) {
      this.setState(() => ({ filename: file && file.path }));
      await FileService.parseAplCsv(file, this.state.error).then((response) => {
        if (response.error) {
          return this.setState((state) => ({
            apl: { ...state.apl, error: response.error },
          }));
        }
        return this.setState((state) => ({
          apl: {
            ...state.apl,
            approvedProductListItems: response.approvedProductListItems,
            error: response.error,
          },
        }));
      });
    }
    const { apl } = this.state;
    if (apl && apl.error) {
      toast.error(apl.error);
    }
  }

  handleChange({ target: { id, value } }) {
    this.setState((state) => ({ apl: { ...state.apl, [id]: value } }));
  }

  handleSubmitApl(e) {
    e.preventDefault();
    const { apl } = this.state;
    if (this.state.apl.error === null) {
      this.setState(() => ({ loading: true }));
      addClientApl(apl)
        .then((data) => {
          this.props.history.push(`/aplUpload/review`);
        })
        .catch((err) => {
          toast.error(err.message);
          this.setState(() => ({ loading: false }));
        });
    }
  }

  handleSubmitCopyPaste(approvedProductListItems) {
    const { apl } = this.state;
    if (!apl.name || isEmpty(approvedProductListItems)) {
      return toast.error('Check apl');
    }
    apl.approvedProductListItems = approvedProductListItems;
    this.setState(() => ({ loading: true }));
    addClientApl(apl)
      .then(() => {
        // Navigate to the new screen that Mai is doing!!!!!
      })
      .catch((err) => {
        this.setState({ showMessage: true, message: err.message });
        toast.error(err.message);
      })
      .finally(() => this.setState(() => ({ loading: false })));
  }

  render() {
    const { filename, apl, loading, aplId } = this.state;
    const { id } = this.props.match.params;

    return (
      <div className="slim-content" style={{ maxWidth: '900px' }}>
        <Container style={{ maxWidth: '900px' }}>
          <Fragment>
            <PageHeader title="Upload Approved Product List file" />
            <AplUpload
              onDrop={(files) => this.handleDrop(files[0])}
              onDropRejected={(e) => this.handleDropRejected(e)}
              onBack={() => window.history.back()}
              onSubmit={(e) => this.handleSubmitApl(e)}
              onChange={(e) => this.handleChange(e)}
              {...{ filename, apl, loading }}
            />
          </Fragment>
        </Container>
      </div>
    );
  }
}

export default AplAdd;
