import { PureComponent, useContext } from 'react';
import { useState, useEffect } from 'react';

import { withRouter, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Button, Nav, NavItem, Container, Row, Col } from 'reactstrap';

// import { isApiAuthenticated } from 'config/api';
import $ from 'jquery';
import { deleteConnection, getConnectedStatus } from 'service/SendXplanCodeService';
import { ProfileContext } from 'store/ProfileContext';

// import closeIcon from '../../_assets/img/icons/global/close@2x.png';
// import arrowIcon from '../../_assets/img/icons/graphics/values-arrow@3x.png';
// import activeLogo from '../../_assets/img/icons/nav/Group 2@3x.png';
// import iressLogo from '../../_assets/img/icons/nav/iress-xplan-logo.svg';
import ProfileIcon from '../../_assets/img/icons/nav/profile/Group 2@3x.png';
import adviserLogo from '../../_assets/img/logo/adviser-logo-small.svg';
// import Logo from '../../_assets/img/logo/circular_ea_logo.svg';
import OkoLogo from '../../_assets/img/logo/oko-logo-no-background-padding.svg';

import './Header.scss';

const { REACT_APP_XPLAN_CLIENT_ID, REACT_APP_XPLAN_RE_DIRECT_URI } = process.env;

const Header = (props) => {
  const profile = useContext(ProfileContext);
  const location = useLocation();
  const [defaultActiveCheck, setDefautActiveCheck] = useState(true);
  const [defaultCheck, setDefautCheck] = useState(false);
  // const [showPopup, setShowPopup] = useState(false);
  // const [isXplanActive, setIsXplanActive] = useState(false);
  // const [xplanCustomerUrl, setXplanCustomerUrl] = useState('');

  const handleClickLogo = () => {
    props?.history.push('/clients');
  };

  // useEffect(() => {
  //   if (isApiAuthenticated()) {
  //     getConnectedStatus()
  //       .then((response) => {
  //         localStorage.setItem('isXplanConnected', response.isXplanConnected);
  //         setIsXplanActive(response.isXplanConnected);
  //         setXplanCustomerUrl(response.xplanCustomerUrl);
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //       });
  //   }
  // }, []);

  const toggleMenu = () => {
    $('body').toggleClass('menu-active');
  };

  const showTitle = () => {
    let show = false;
    if (props.config.items[0].id !== 'Sign Up') {
      show = true;
    } else if (String(this.props.location.pathname).includes('/investing-profile/')) {
      show = true;
    } else if (String(this.props.location.pathname).includes('/profile/portfolios/')) {
      show = true;
    }
    return show;
  };

  const getTitle = () => {
    let text = '';

    if (location.pathname === '/register/basicInformation') {
      text = 'Sign up';
    }
    // else if (location.pathname === '/register/inviteCodeSkip') {
    // text = 'Sign up';}
    else if (location.pathname === '/register/stepControl') {
      text = 'Sign up';
    } else if (location.pathname === '/forgotpassword') {
      text = 'Sign in';
    } else if (String(location.pathname).includes('/verify/')) {
      text = 'Oko Advisor -- Sign In';
    } if (location.pathname.includes('/investing-profile/profile-builder') || location.pathname.includes('/investing-profile/start')) {
      text = '';
    }
    else if (String(location.pathname).includes('/investing-profile/')) {
      text = 'Client responsible investing profile';
    }
    
    // else if (String(location.pathname).includes('/register/inviteCodeApplied')) {
    // text = 'Sign up';}
    // else if (String(location.pathname).includes('/profile/portfolios/')) {
    //   text = `Client responsible investing profile — ${profile.firstName} ${profile.lastName}`;
    // }
    else {
      text = '';
    }

    return text;
  };

  // const handleClick = () => {
  //   const clientID = REACT_APP_XPLAN_CLIENT_ID;
  //   const redirectURI = REACT_APP_XPLAN_RE_DIRECT_URI;
  //   const path = `${xplanCustomerUrl}/oauth2/auth?client_id=${clientID}&response_type=code&redirect_uri=${redirectURI}`;
  //   window.location.href = path;
  // };

  const handleClickNext = () => {
    setShowPopup(false);
    props.history.push('/account/settings');
  };

  const handleClickButton = () => {
    setShowPopup(true);
    setDefautCheck(true);
  };

  const handleSwitchChange = () => {
    setDefautActiveCheck(!defaultActiveCheck);
    deleteConnection()
      .then(() => {})
      .catch((err) => {
        console.log(err.message);
      });
    setIsXplanActive(false);
  };

  const handleCloseButton = () => {
    setShowPopup(false);
    setDefautCheck(false);
  };

  return (
    <header className="header background-gradient" style={{ background: 'black' }}>
      <div id="nav-icon" onClick={toggleMenu} className="d-sm-block d-md-none">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="header__left">
        <Button className="logo-btn" color="white" onClick={handleClickLogo}>
          {/* <img src={Logo} alt="Oko Adviser Logo" className="d-none d-md-block" /> */}
          <img src={OkoLogo} alt="Oko Logo" className="d-none d-md-block" width={78} />
          <img src={adviserLogo} alt="Adviser Logo" className="d-none d-md-block" width={95} />
        </Button>
      </div>

      {(props?.config?.items[0].id === 'Sign Up' ||
        props?.config?.items[0].id === 'Sign In' ||
        String(props?.location?.pathname).includes('/investing-profile/') ||
        String(props?.location?.pathname).includes('/profile/portfolios/add')) && (
        <div className="d-flex title-flex" style={{ paddingLeft: '110px' }}>
          <span className="text-white">{getTitle()}</span>
        </div>
      )}

      {props?.config.banner && <span>{props.config.banner}</span>}
      {props?.config.items && (
        <div className="header__center">
          <ul className="d-none d-md-block">
            {props?.config.items.map((item, idx) => (
              <li key={idx}>
                <NavLink
                  to={item.link ?? '#'}
                  activeClassName="active"
                  className={item.selected ? 'nav-link active' : 'nav-link'}
                >
                  {item.title}
                </NavLink>
              </li>
            ))}
          </ul>
          <ul className="mobile-only menu-mobile background-gradient">
            {props?.config.items.map((item, idx) => (
              <li key={idx}>
                <Button
                  color="white"
                  onClick={() => props?.history.push(item.link)}
                  className={item.selected ? 'active' : ''}
                >
                  {item.title}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="header__right">
        {profile.hasSubscription && (
          <div className="content-buttons icon-session">
            {/* {profile.featureFlags.xPlanIntegration && (
              <div className="custom-control custom-switch">
                <img src={iressLogo} className="profile-logo" />
                {isXplanActive && <img src={activeLogo} className="active-logo" />}
                {isXplanActive || window.location.pathname.endsWith('/account/settings') ? (
                  <>
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitches"
                      checked={defaultActiveCheck}
                      onChange={handleSwitchChange}
                      readOnly
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customSwitches"
                      style={{ marginLeft: '58px', marginTop: '2px' }}
                    ></label>
                  </>
                ) : (
                  <>
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitches"
                      checked={defaultCheck}
                      onChange={handleClickButton}
                      readOnly
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customSwitches"
                      style={{ marginLeft: '55px', marginTop: '2px' }}
                    ></label>
                  </>
                )}
              </div>
            )} */}
            <Nav>
              <NavItem className="profile-menu">
                <NavLink to="/account" activeClassName="active">
                  <img src={ProfileIcon} alt="profile-icon" style={{ transform: 'scale(1.2)' }} />
                  <span className="d-none d-md-block d-lg-block d-xl-block">
                    {profile?.profile?.firstName}
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        )}
      </div>
      {/* {showPopup && (
        <div className="overlay">
          <Container className="showup-modal modal-width">
            <div className="close-button" onClick={handleCloseButton}>
              <img src={closeIcon} width="20" />
            </div>
            <Row className="mt-10 mb-10 justify-content-center">
              <img src={arrowIcon} width="150" />
            </Row>
            <Row className="text-center justify-content-center">
              <p className="comment">
                Follow these steps to integrate with <span className="strong-text">Xplan</span>
              </p>
            </Row>
            <Row className="mt-20" style={{ marginLeft: '25px' }}>
              <p className="d-block comment">{`1. Hit "next" and follow the instruction to add your unique Xplan URL`}</p>
              <p
                className="d-block comment"
                style={{ margin: '0' }}
              >{`2. Log in to Xplan and head to your Account`}</p>
              <p
                className="d-block comment"
                style={{ margin: '0' }}
              >{`3. Head to Permissions and allow the connection to Oko Adviser`}</p>
            </Row>
            <Row className="justify-content-center">
              <Col sm={5} className="mb-80">
                <div className="text-center btn-blue">
                  <Button onClick={handleClickNext}>{`Next`}</Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )} */}
    </header>
  );
};

export default withRouter(Header);
