import React from 'react';

import PasswordMask from 'react-password-mask';
import { Link } from 'react-router-dom';
import { Col, Row, Form, Input, FormFeedback, FormText, FormGroup } from 'reactstrap';

import PropTypes from 'prop-types';

import eye from '../../../_assets/img/user/eye.svg';
import eye_slash from '../../../_assets/img/user/eye_slash.svg';
import { ButtonLoading } from '../../commons';

const ResetPasswordForm = ({
  onSubmit,
  onChangePassword,
  onChangeCode,
  onChangeEmail,
  password,
  passwordError,
  loading,
}) => (
  <Row className="justify-content-center">
    <Col sm={12}>
      <Form onSubmit={onSubmit}>
        <Row className="form-group">
          <h4 clas className="mb-20" sName="text-center"></h4>
        </Row>
        <FormGroup className="mb-30">
          <Row>
            <Col sm={{ size: 12 }}>
              <FormText className="label-form">Email</FormText>
            </Col>
          </Row>
          <Row className="mb-20">
            <Col sm={{ size: 12 }}>
              <Input type="text" name="email" id="email" onChange={onChangeEmail} />
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: 12 }}>
              <FormText className="label-form">Code</FormText>
            </Col>
          </Row>
          <Row className="mb-20">
            <Col sm={{ size: 12 }}>
              <Input type="text" name="code" id="code" onChange={onChangeCode} />
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: 12 }}>
              <FormText className="label-form">New Password</FormText>
            </Col>
          </Row>
          <Row className={passwordError ? 'invalid-input' : null}>
            <Col sm={{ size: 12 }}>
              <PasswordMask
                maxLength={30}
                id="password"
                name="password"
                buttonClassName="input-toggle"
                showButtonContent={<img alt="eye-slash" className="eye-password" src={eye_slash} />}
                hideButtonContent={<img alt="eye" className="eye-password" src={eye} />}
                value={password}
                onChange={onChangePassword}
              />
              {passwordError ? <FormFeedback>{passwordError}</FormFeedback> : null}
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: 12 }}>
              <FormText>{passwordError}</FormText>
            </Col>
          </Row>
        </FormGroup>
        <br />
        <Row className="justify-content-center">
          <ButtonLoading
            loading={loading}
            className="btn-main-shadowless btn-middle"
            label="Reset Password"
          />
        </Row>
        <br />
        <Row className="justify-content-center">
          <Link to="/">Sign in</Link>
        </Row>
      </Form>
    </Col>
  </Row>
);

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChangeCode: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
  resetStatus: PropTypes.string,
  password: PropTypes.string,
  passwordError: PropTypes.string,
};

ResetPasswordForm.defaultProps = {
  user: null,
  resetStatus: '',
  password: null,
  passwordError: null,
};

export default ResetPasswordForm;
