import React, { Fragment, useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Row, Col, Button, ListGroup, ListGroupItem } from 'reactstrap';

import { deleteApl, getApl } from '../../../service/AplService';
import DateUtilsService from '../../../service/DateUtils';
import { TipCard, SectionTitle, LoadingPage } from '../../commons';

const ReviewApl = (props) => {
  const [apl, setApl] = useState(null);
  const [loadingApl, setLoadingApl] = useState(true);
  const noApl = apl === null;
  const history = useHistory();

  const getAplList = () => {
    setLoadingApl(true);
    getApl()
      .then((response) => {
        setApl(response);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(setLoadingApl(false));
  };

  useEffect(() => {
    getAplList();
  }, []);

  const removeAplList = (aplId) => {
    setLoadingApl(true);
    deleteApl(aplId)
      .then((response) => {
        getAplList();
        history.push('/aplUpload');
      })
      .catch((err) => {
        toast.error(err.message);
        setLoadingApl(false);
      });
  };

  return (
    <div className="slim-content">
      {loadingApl ? (
        <LoadingPage />
      ) : (
        <Fragment>
          <SectionTitle title="APL" />
          <ListGroup>
            {!noApl && (
              <ListGroupItem>
                <div className="list-item-layout w-100">
                  <div className="list-item-description d-flex justify-content-start align-items-center flex-grow-1 p-1">
                    <Button
                      color="link"
                      className="review-apl-button"
                      onClick={() => {
                        history.push(`/aplUpload/review`);
                      }}
                    >
                      {apl.name} {DateUtilsService.formatDate(apl.dateUploaded)}
                    </Button>
                  </div>
                  <div className="list-item-info d-flex justify-content-center align-items-center p-1 flex-column justify-content-center">
                    <Button color="link" className="p-0" onClick={() => removeAplList(apl.id)}>
                      Remove
                    </Button>
                  </div>
                </div>
              </ListGroupItem>
            )}
          </ListGroup>
          <Row className="justify-content-center mb-40 mt-30">
            <Col sm={6}>
              <Button
                className="btn-modal btn-cta btn btn-secondary small-btn"
                style={{ maxWidth: '210px', width: '210px' }}
                onClick={() => {
                  history.push('/aplUpload');
                }}
              >
                {noApl ? 'Add an APL' : 'Re-upload APL'}
              </Button>
            </Col>
          </Row>
          {noApl ? (
            <TipCard message={`Please upload APL so you can review it anytime!`} />
          ) : (
            <TipCard message={`You can re-upload the APL file if it changes!`} />
          )}
        </Fragment>
      )}
    </div>
  );
};
export default ReviewApl;
