import { isEmpty } from 'lodash';
import React from 'react';

import Moment from 'react-moment';
import { Row, Col, Table } from 'reactstrap';

import { showTableData } from '../../_helpers/instrument';
import NoDataView from '../commons/Instrument/NoDataView';

/**
 * @param {Partial <import('interfaces/responses/instrument/IPriceHistoryDto').IPriceHistoryDto>} param
 */
const PriceHistoryTable = ({ high, low, current, highDate, lowDate, currentDate }) => {
  function getDateOrDash(date) {
    if (date) {
      return <Moment format="DD MMM YY" className="price-history-date" date={date} />;
    }
    return <>-</>;
  }

  return (
    <>
      <Row>
        <Col sm={12}>
          <h4>Price history over 5 years</h4>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div className="table-rounded">
            <Table responsive striped bordered className="text-center m-0">
              <thead>
                <tr>
                  <th>{showTableData(high)}</th>
                  <th>{showTableData(low)}</th>
                  <th>{showTableData(current)}</th>
                </tr>
              </thead>
              <tbody className="price-history">
                <tr>
                  <td>{getDateOrDash(highDate)}</td>
                  <td>{getDateOrDash(lowDate)}</td>
                  <td>{getDateOrDash(currentDate)}</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <Row className="text-center price-history">
            <Col sm={4} className="mt-2">
              <span className="text-company-primary">HIGH</span>
            </Col>
            <Col sm={4} className="mt-2 pl-0">
              <span className="text-company-primary">LOW</span>
            </Col>
            <Col sm={4} className="mt-2 pl-0 ">
              <span className="text-company-primary">CURRENT</span>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default PriceHistoryTable;
