import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import { BrowserTracing } from '@sentry/tracing';

import 'bootstrap/dist/css/bootstrap.css';

import App from './_components/app';
import Root from './containers/Root';
import './scss/index.scss';

const { REACT_APP_SENTRY_KEY } = process.env;

Sentry.init({
  dsn: REACT_APP_SENTRY_KEY,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  environment: 'production',
});

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<Root comp={App} />);
