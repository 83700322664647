import React from 'react';

import { Row, Col } from 'reactstrap';

import NoDataView from '../commons/Instrument/NoDataView';

const About = ({ about }) => {
  return (
    <>
      <Row>
        <Col sm={12}>
          <h4 className="mb-20">About {about.name}</h4>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          {about.description ? <p className="mb-4">{about.description}</p> : <NoDataView />}
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <p className="mb-0">For more Information:</p>
          <a href={about.bloombergWebsite} target="_blank" rel="noopener noreferrer">
            Bloomberg
          </a>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <a href={about.marketWatchWebsite} target="_blank" rel="noopener noreferrer">
            MarketWatch
          </a>
        </Col>
      </Row>
    </>
  );
};
export default About;
