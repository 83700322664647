import api, { apiSetup } from '../config/api';
import { IAdviserClientPortfolioBulkAddRequest } from '../interfaces/requests/portfolio/IAdviserClientPortfolioBulkAddRequest';
import { IAdviserClientsPortfolioBulkAddRequest } from '../interfaces/requests/portfolio/IAdviserClientsPortfolioBulkAddRequest';
import { IAdviserAllClientPortfolioResponse } from '../interfaces/responses/portfolio/IAdviserAllClientPortfolioResponse';
import { IAdviserClientPortfolioBulkResponse } from '../interfaces/responses/portfolio/IAdviserClientPortfolioBulkResponse';
import { IAdviserClientPortfolioResponse } from '../interfaces/responses/portfolio/IAdviserClientPortfolioResponse';
import { IPortfolioResponse } from '../interfaces/responses/portfolio/IPortfolioResponse';
import { IPortfolioSustainabilityScoresResponse } from '../interfaces/responses/portfolio/IPortfolioSustainabilityScoresResponse';

import { ApiEndpoints } from './ApiEndpoints';
import { BaseService } from './BaseService';

export abstract class PortfolioService extends BaseService {
  static getInstance() {
    return this;
  }

  public static getAllClientPortfolios(clientCoreId: string) {
    const url = 'api/portfolio/getAllClientPortfolios';
    return new Promise<IAdviserAllClientPortfolioResponse>((resolve, reject) => {
      apiSetup();
      api
        .post<IAdviserAllClientPortfolioResponse>(url, { clientCoreId })
        .then((res) => {
          if (res.ok && res.status === 200 && res.data !== undefined) {
            resolve(res.data);
          } else {
            //todo:- Show Error Message Here
            reject({ error: 'Something went wrong' });
          }
        })
        .catch((err) => {
          reject({ error: err.message });
        });
    });
  }

  public static deleteClientPortfolio(clientPortfolioId: string) {
    return this.callPost(ApiEndpoints.DeleteClientPortfolio, {
      clientPortfolioId,
    });
  }

  public static getClientPortfolio(portfolioId: string) {
    return this.callGet<IPortfolioResponse>(ApiEndpoints.GetClientPortfolio(portfolioId));
  }

  public static addClientPortfolioBulk(portfolio: IAdviserClientPortfolioBulkAddRequest) {
    return this.callPost<IAdviserClientPortfolioResponse>(
      ApiEndpoints.AddClientPortfolioBulk,
      portfolio,
    );
  }

  public static addClientsPortfolioBulk(bulkRequest: IAdviserClientsPortfolioBulkAddRequest) {
    return this.callPost<IAdviserClientPortfolioBulkResponse>(
      ApiEndpoints.AddClientsPortfolioBulk,
      bulkRequest,
    );
  }

  public static getSustainabilityScores(clientId: string) {
    return this.callGet<IPortfolioSustainabilityScoresResponse>(
      ApiEndpoints.GetSustainabilityScores(clientId),
    );
  }
}
