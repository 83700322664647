import { isEmpty } from 'lodash';
import React, { PureComponent } from 'react';

import { toast } from 'react-toastify';
import { Container, Row, Col, Button, ListGroup, ListGroupItem } from 'reactstrap';

import Printer from '_components/app/Printer';

import { getClientSelected } from '../../../../../service/ClientsService';
import { getActiveProfileByClientId } from '../../../../../service/ProfilingService';
import { LoadingPage, ButtonModal, StartQuestionnaireNow } from '../../../../commons';
import SectionTitle from '../../../../commons/SectionTitle';
import SendModal from '../../../SendModal.tsx';

import InfoModal from './InfoModal';
import TopicModal from './TopicModal';
import './styles.scss';

class Values extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      topic: null,
      topics: {},
      topicModal: false,
      clientAdviser: null,
      loading: false,
      isVersion1: false,
      lifestyleMatches: [],
      lifestyleConflicts: [],
      lifestyleNone: [],
      matches: [],
      conflicts: [],
      none: [],
      avoidCountries: [],
      soft: [],
      profileExists: false,
      profileDateCreated: null,
    };
  }

  async componentDidMount() {
    const ClientCoreId = this.props.match.params.id;
    this.setState(() => ({ loading: true }));

    await getActiveProfileByClientId(ClientCoreId)
      .then((response) => {
        if (response.isVersion1Profile === true) {
          this.setState({ isVersion1: true });
          this.setState({ topics: response.profileRunDetails, loading: false });
        } else {
          this.setState({
            lifestyleMatches: response.lifestyleMatches,
            lifestyleConflicts: response.lifestyleConflicts,
            lifestyleNone: response.lifestyleNone,
            matches: response.matches,
            conflicts: response.conflicts,
            none: response.none,
            avoidCountries: response.avoidCountries,
            soft: response.soft,
            loading: false,
            profileExists: response.profileExists,
            profileDateCreated: response.profileDateCreated.slice(0, 10),
          });
        }
      })
      .catch((err) => {
        this.setState({ showMessage: true, message: err.message, loading: false });
        toast.error(err.message);
      });
    this.setState(() => ({ clientAdviser: getClientSelected() }));
  }

  getSupportedTopics() {
    return this.state.topics.filter((topic) => topic.questionType === 'Preferences');
  }

  getOtherTopics() {
    return this.state.topics.filter((topic) => topic.questionType === 'Exclusions');
  }

  toggleTopicModal() {
    this.setState((prevState) => ({
      topicModal: !prevState.topicModal,
    }));
  }

  showTopicModal(topic) {
    this.setState((prevState) => ({
      topic,
      topicModal: true,
    }));
  }

  getStartUrl() {
    return `/clients/${this.props.match.params.id}/investing-profile/start`;
  }

  goToQuestionnaire() {
    const actualPath = `/clients/${this.props.match.params.id}/advice/outcomes`;
    this.props.history.push(
      `/clients/${this.props.match.params.id}/investing-profile/start`,
      actualPath,
    );
  }
  render() {
    const {
      clientAdviser,
      topics,
      loading,
      isVersion1,
      lifestyleMatches,
      lifestyleConflicts,
      lifestyleNone,
      matches,
      conflicts,
      none,
      avoidCountries,
      soft,
      profileExists,
      profileDateCreated,
    } = this.state;
    if (isVersion1) {
      return (
        <Printer>
          <div className="slim-content">
            <Container>
              {loading && <LoadingPage />}
              {!loading && !isEmpty(topics) && (
                <Container>
                  <div style={{ marginTop: 45 }}>
                    <SectionTitle title="Sustainability Profile" />
                  </div>
                  <Row>
                    <br />
                    <Col sm={12} style={{ marginTop: 40, marginBottom: 20, marginLeft: 0 }}>
                      <span style={{ fontSize: '20px', lineHeight: '26px' }}>
                        {clientAdviser && clientAdviser.clientFirstName} supports:
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row className="justify-content-center">
                        <ListGroup className="w-100">
                          {this.getSupportedTopics().map((topic, key) => (
                            <Row key={key}>
                              <Col sm={12}>
                                <ListGroupItem style={{ marginBottom: '10px', height: '100px' }}>
                                  <div
                                    className="list-item-layout w-100"
                                    style={{ paddingBottom: '10px', paddingTop: '10px' }}
                                  >
                                    <div className="list-item-img d-flex justify-content-center align-items-center p-1">
                                      <img src={topic.iconUrl} alt="" width="70" />
                                    </div>
                                    <div className="list-item-description d-flex justify-content-start align-items-center flex-grow-1 p-1">
                                      <span>{topic.commentTitle}</span>
                                    </div>
                                    <div
                                      className="list-item-info d-flex justify-content-center align-items-start p-1"
                                      style={{ marginTop: '-45px', marginRight: '-15px' }}
                                    >
                                      <ButtonModal
                                        component={InfoModal}
                                        className="link hide-button"
                                        params={topic}
                                      />
                                      <img
                                        src="https://cdn.okoadviser.com/portal/icons/global/i_icon@3x.png"
                                        alt="Info"
                                        width="22"
                                      />
                                    </div>
                                  </div>
                                </ListGroupItem>
                              </Col>
                            </Row>
                          ))}
                        </ListGroup>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <br />
                    <Col sm={12} style={{ marginTop: 40, marginBottom: 20, marginLeft: 0 }}>
                      <span style={{ fontSize: '20px', lineHeight: '26px' }}>
                        {clientAdviser && clientAdviser.clientFirstName} doesn't support:
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row className="justify-content-center">
                        <ListGroup className="w-100">
                          {this.getOtherTopics().map((topic, key) => (
                            <Row key={key}>
                              <Col sm={12}>
                                <ListGroupItem style={{ marginBottom: '10px', height: '100px' }}>
                                  <div
                                    className="list-item-layout w-100"
                                    style={{ paddingBottom: '10px', paddingTop: '10px' }}
                                  >
                                    <div className="list-item-img d-flex justify-content-center align-items-center p-1">
                                      <img src={topic.iconUrl} alt="" width="70" />
                                    </div>
                                    <div className="list-item-description d-flex justify-content-start align-items-center flex-grow-1 p-1">
                                      <span>{topic.commentTitle}</span>
                                    </div>
                                    <div
                                      className="list-item-info d-flex justify-content-center align-items-start p-1"
                                      style={{ marginTop: '-45px', marginRight: '-15px' }}
                                    >
                                      <ButtonModal
                                        component={InfoModal}
                                        className="link hide-button"
                                        params={topic}
                                      />
                                      <img
                                        src="https://cdn.okoadviser.com/portal/icons/global/i_icon@3x.png"
                                        alt="Info"
                                        width="22"
                                      />
                                    </div>
                                  </div>
                                </ListGroupItem>
                              </Col>
                            </Row>
                          ))}
                        </ListGroup>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 50 }}>
                    <Col style={{ textAlign: 'center' }}>
                      <Button
                        onClick={() => this.goToQuestionnaire()}
                        className="browser-button small-btn"
                        style={{ width: '170px', height: '38px' }}
                      >
                        Redo values profile
                      </Button>
                    </Col>
                  </Row>
                  <br />
                  <br />
                </Container>
              )}
              {this.state.topic && (
                <TopicModal
                  isOpen={this.state.topicModal}
                  toggle={this.toggleTopicModal.bind(this)}
                  topic={this.state.topic}
                />
              )}
              {!loading && isEmpty(topics) && (
                <StartQuestionnaireNow
                  clientId={this.props.match.params.id}
                  startInvestingProfile={() =>
                    this.props.history.push(
                      `/clients/${this.props.match.params.id}/investing-profile/start`,
                    )
                  }
                />
              )}
            </Container>
          </div>
        </Printer>
      );
    }
    if (!isVersion1) {
      return (
        <Printer>
          {loading && <LoadingPage />}
          {!loading && profileExists && (
            <Container style={{ maxWidth: '1200px' }}>
              <div className="mt-80 values-header">
                <h3 className="value-title">Sustainability Profile</h3>
                <p className="value-subtitle">
                  Here are the selections made by {clientAdviser && clientAdviser.clientFirstName}
                  {''} when their profile was last completed on {profileDateCreated}. <br />
                  - Habits and lifestyle are ordered by positive selection: ‘Thats me’, negative
                  selection: ‘Thats not me’ and ‘Neutral’. <br />
                  - Responsible Investing causes are shown in the order of priority as defined by
                  the client when they completed this profile. <br />
                  - Only those items selected are shown in this list.
                  <br />- Click Redo Profile to update these selections.
                </p>
              </div>
              <Row className="mt-50 category-wrapper">
                <p className="category-title">Habits and Lifestyle</p>
                <Row>
                  <Col>
                    <p className="category-subtitle">That's me</p>
                    {lifestyleMatches.map((item, key) => (
                      <div className="card-wrapper" key={key}>
                        <img src={item.imageFileName} width="60" />
                        <p className="card-text">{item.question}</p>
                      </div>
                    ))}
                  </Col>
                  <Col>
                    <p
                      className={`${
                        lifestyleConflicts.length === 0 ? 'disable' : 'category-subtitle'
                      }`}
                    >
                      That's not me
                    </p>
                    {lifestyleConflicts.map((item, key) => (
                      <div className="card-wrapper" key={key}>
                        <img src={item.imageFileName} width="60" />
                        <p className="card-text">{item.question}</p>
                      </div>
                    ))}
                  </Col>
                  <Col>
                    <p
                      className={`${lifestyleNone.length === 0 ? 'disable' : 'category-subtitle'}`}
                    >
                      Neutral
                    </p>
                    {lifestyleNone.map((item, key) => (
                      <div className="card-wrapper" key={key}>
                        <img src={item.imageFileName} width="60" />
                        <p className="card-text">{item.question}</p>
                      </div>
                    ))}
                  </Col>
                </Row>
              </Row>
              <Row className="mt-40 category-wrapper">
                <p className="category-title">Responsible Investing Causes</p>
                <Row>
                  <Col>
                    <p className="category-subtitle">Support</p>
                    {matches.map((item, key) => (
                      <div className="card-wrapper" key={key}>
                        <img src={item.imageFileName} width="60" />
                        <p className="card-text">{item.question}</p>
                      </div>
                    ))}
                  </Col>
                  <Col>
                    <p className="category-subtitle">Avoid</p>
                    {conflicts.map((item, key) => (
                      <div className="card-wrapper" key={key}>
                        <img src={item.imageFileName} width="60" />
                        <p className="card-text">{item.question}</p>
                      </div>
                    ))}
                  </Col>
                  <Col>
                    <p className={`${none.length === 0 ? 'disable' : 'category-subtitle'}`}>
                      Neutral
                    </p>
                    {none.map((item, key) => (
                      <div className="card-wrapper" key={key}>
                        <img src={item.imageFileName} width="60" />
                        <p className="card-text">{item.question}</p>
                      </div>
                    ))}
                  </Col>
                </Row>
              </Row>
              <Row className="mt-40 category-wrapper">
                <p className="category-title">Sovereign States</p>
                <Col xs={4}>
                  {avoidCountries.length ? (
                    <p className="category-subtitle">Avoid</p>
                  ) : (
                    <p style={{ fontSize: '17px' }} className="category-subtitle">
                      No selections were made
                    </p>
                  )}
                  {avoidCountries.map((item, key) => (
                    <div className="card-wrapper" key={key} style={{ padding: '15px' }}>
                      <img src={item.imageFileName} className="icon-style" width="50" />
                      <p className="card-state-text">{item.question}</p>
                    </div>
                  ))}
                </Col>
              </Row>
              <Row className="mt-50 category-wrapper">
                <p className="category-title">
                  Most interested in achieving with your investments?
                </p>
                {soft.length ? (
                  soft.map((item, key) => (
                    <p
                      className="card-text"
                      key={key}
                      style={{ fontSize: '17px', marginLeft: '15px' }}
                    >
                      - {item.question}
                    </p>
                  ))
                ) : (
                  <p style={{ marginLeft: '15px', fontSize: '17px' }} className="category-subtitle">
                    No selections were made
                  </p>
                )}
              </Row>
              <Row style={{ marginTop: 100 }}>
                <Col style={{ textAlign: 'center', marginBottom: 50 }}>
                  <ButtonModal
                    component={SendModal}
                    className="btn-main-shadowless confirm-button"
                    name="Redo profile"
                    params={this.props.match.params.id}
                  />
                </Col>
              </Row>
            </Container>
          )}
          {!loading && !profileExists && (
            <StartQuestionnaireNow
              clientId={this.props.match.params.id}
              startInvestingProfile={() =>
                this.props.history.push(
                  `/clients/${this.props.match.params.id}/investing-profile/start`,
                )
              }
            />
          )}
        </Printer>
      );
    }
  }
}

export default Values;
