import { omit } from 'lodash';

import { SelectedAnswers } from '../model/InvestingProfile';

export const getSelectedProfileAnswersOnPageLoad = (state: any) => {
  const selected: SelectedAnswers = {};
  if (state) {
    for (let i = 0; i < state.length; i++) {
      selected[state[i]] = 'selected';
    }
  }
  return selected;
};

export const selectUnselect = (selectedAnswers: SelectedAnswers, answer: string) => {
  if (selectedAnswers[answer] !== 'selected') {
    selectedAnswers[answer] = 'selected';
  } else {
    const filterSelected = omit(selectedAnswers, answer);
    return filterSelected;
  }
  return selectedAnswers;
};
