import { isEmpty, filter, map, parseInt, compact } from 'lodash';

import { async } from '@firebase/util';

import api, { apiSetup, isApiAuthenticated } from '../config/api';

import {
  setFiltersOption,
  getFiltersOption,
  setCommonFiltersOption,
  getCommonFiltersOption,
  setSelectedFilters,
  getSelectedFilters,
  getSortByOption,
  setSortByOption,
} from './LocalStorageService';

export function getPrimaryTypeOfAdviceProvided() {
  const getPrimaryTypeOfAdviceProvidedRequest = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/references/PrimaryTypeOfAdviceProvided')
      .then((res) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: 'something went wrong' });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(getPrimaryTypeOfAdviceProvidedRequest);
}

export function getNumberOfClients() {
  const getNumberOfClientsRequest = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/references/NumberOfClients')
      .then((res) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: 'something went wrong' });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(getNumberOfClientsRequest);
}

export function getPrimaryInvestmentSolution() {
  const getPrimaryInvestmentSolutionRequest = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/references/PrimaryInvestmentSolution')
      .then((res) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: 'something went wrong' });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(getPrimaryInvestmentSolutionRequest);
}

export async function getFaqs() {
  const getFaqsRequest = (resolve, reject) => {
    apiSetup();
    api
      .get('/api/faqs')
      .then((res) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: 'something went wrong' });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(getFaqsRequest);
}

function getFiltersFromApi() {
  const getFiltersFromApiRequest = (resolve, reject) => {
    apiSetup();
    api
      .get('v2/filters')
      .then((res) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: 'Unable to load filters' });
          }
        } else {
          reject({ message: 'Unable to load filters' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(getFiltersFromApiRequest);
}

export async function getFilters() {
  if (isApiAuthenticated()) {
    const filtersResponse = await getFiltersFromApi();
    if (filtersResponse && filtersResponse.filters) {
      return filtersResponse;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function getSelectedFiltersFromLocalStorage() {
  const filters = getSelectedFilters();
  if (filters === null) {
    return [];
  }
  return filters;
}

export function addSelectedFilter(filter) {
  const newFilters = getSelectedFiltersFromLocalStorage();
  newFilters.push(filter);
  setSelectedFilters(newFilters);
  return getSelectedFiltersFromLocalStorage();
}

export function removeSelectedFilter(filter) {
  const newFilters = getSelectedFiltersFromLocalStorage().filter((data) => data != filter);
  setSelectedFilters(newFilters);
  return getSelectedFiltersFromLocalStorage();
}

export function getAdviceActionStatus() {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/references/ActionStatus')
      .then((res) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: 'something went wrong' });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}
