import React from 'react';

import { Col, Input, Label, Row } from 'reactstrap';

import ValidatorService from '../../../../../service/validator';
import { TextFieldDateMask } from '../../../../commons';
import TextFieldTimeMask from '../../../../commons/TextFieldTimeMask';

interface DateTimeProps {
  handleChange: (e: any) => {};
  handleAmPmClick: (e: any) => {};
  meetingDate: string;
  meetingTime: string;
  isAm: boolean;
  isEditable: boolean;
}
export default function DateTime(props: DateTimeProps) {
  const { handleAmPmClick, handleChange, isAm, meetingDate, meetingTime, isEditable } = props;
  return (
    <Row>
      <Col xs={12} md={1} className="d-flex align-items-center justify-content-center">
        <div className="form-check p-0 d-flex align-items-center">On</div>
      </Col>
      <Col xs={12} md={4} className="d-flex align-items-center justify-content-center p-0">
        <div className="form-check p-0 d-flex align-items-center">
          <TextFieldDateMask
            type="number"
            control="meetingDate"
            placeholder="dd/mm/yyyy"
            id="meetingDate"
            onChange={handleChange}
            value={meetingDate}
            validateInput={() => ValidatorService.validateInputLongDate(meetingDate, 11, 9)}
            required
            disabled={!isEditable}
          />
          <Label className="form-check-label" for="meetingDate" />
        </div>
      </Col>
      <Col xs={12} md={1} className="d-flex align-items-center justify-content-center">
        <div className="form-check p-0 d-flex align-items-center">at</div>
      </Col>
      <Col xs={4} md={3} className="d-flex align-items-center justify-content-center p-0">
        <div className="form-check p-0 d-flex align-items-center">
          <TextFieldTimeMask
            type="time"
            control="meetingTime"
            placeholder="00:00"
            id="meetingTime"
            onChange={handleChange}
            value={meetingTime}
            validateInput={() => ValidatorService.validate12HourTimeFormat(meetingTime)}
            required
            disabled={!isEditable}
          />
          <Label className="form-check-label" for="meetingTime" />
        </div>
      </Col>
      <Col xs={4} md={2} className="d-flex align-items-center justify-content-center">
        <div className="form-check p-0 d-flex align-items-center radio-custom">
          <Label className="form-check-label" for="am">
            am
            <Input
              className="form-check-input"
              style={{ marginLeft: '-2.25rem' }}
              type="radio"
              name="ampm"
              id="am"
              checked={isAm}
              onChange={handleAmPmClick}
              disabled={!isEditable}
            />
            <span className="checkmark" />
          </Label>
        </div>
      </Col>
      <Col xs={4} md={1} className="d-flex align-items-center justify-content-center">
        <div className="form-check p-0 d-flex align-items-center radio-custom">
          <Label className="form-check-label" for="pm">
            pm
            <Input
              className="form-check-input"
              style={{ marginLeft: '-2.25rem' }}
              type="radio"
              name="ampm"
              id="pm"
              checked={!isAm}
              onChange={handleAmPmClick}
              disabled={!isEditable}
            />
            <span className="checkmark" />
          </Label>
        </div>
      </Col>
    </Row>
  );
}
