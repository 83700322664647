import { trim } from 'lodash';

const ClientRow = ({ rowNumber, data = [] }) => [
  { readOnly: true, value: rowNumber || '', width: 50 },
  { value: data[0] || '', width: 140 },
  { value: data[1] || '', width: 140 },
  { value: trim(data[2]) || '', width: 140 },
];

export default ClientRow;
