import React, { PureComponent, Fragment } from 'react';

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import countryList from 'react-select-country-list';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { Col, Row, Form, Container } from 'reactstrap';

import arrowNext from '../../../../_assets/img/icons/profile/ArrowNext@1.5x.png';
import { Adviser } from '../../../../model';
import { updateAdviserUser, updateCompany } from '../../../../service/AdvisorService';
import { getAdviserData, setAdviserData } from '../../../../service/LocalStorageService';
import ValidatorService from '../../../../service/validator';
import { TextField, Dropdown, ButtonLoading } from '../../../commons';

class BasicInformation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      stateOptions: countryList().getData(),
      companyName: '',
      addressLine1: '',
      suburb: '',
      stateProvince: '',
      postcode: '',
      country: 'Australia',
      afsl: '',
      carNo: '',
      numberOfAdvisers: 0,
      loadingButton: false,
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    // const { adviser } = this.state;
    this.setState(() => ({ loadingButton: true }));
    // if (!adviser.isValidCompanyAddress()) {
    //   this.setState(() => ({ loadingButton: false }));
    //   return toast.error('Please, check data');
    // }
    // const subscribedAdviser = { ...adviser, isSubscribedAdviser: true };
    const params = {
      companyName: this.state.companyName,
      companyStreetAddress: this.state.addressLine1,
      companySuburb: this.state.citySuburb,
      companyState: this.state.stateProvince,
      companyPostcode: this.state.postCode,
      country: this.state.country,
      financialLicenseNumber: this.state.afslNo,
      financialLicenseSubName: this.state.carNo,
      numberOfAdvisersInFirm: this.state.numberOfAdvisers,
    };
    updateCompany(params)
      .then(() => {
        // setAdviserData({ ...subscribedAdviser });
        // this.props.receiveSession({ ...subscribedAdviser });
        this.props.history.push('/account/chooseSubscription');
      })
      .catch((err) => {
        this.setState({ showMessage: true, message: err.message, loadingButton: false });
        toast.error(err.message);
      });
  }

  // handleSkip(e) {
  //   e.preventDefault();
  //   const adviserData = getAdviserData();
  //   const subscribedAdviser = { ...adviserData, isSubscribedAdviser: true };
  //   setAdviserData({ ...subscribedAdviser });
  //   // this.props.receiveSession({ ...subscribedAdviser });
  //   this.props.history.push('/clients');
  // }

  // handleChangeAddress({ target: { id, value } }) {
  //   this.setState((state) => ({
  //     adviser: new Adviser({
  //       ...state.adviser,
  //       companyAddress: new Adviser.CompanyAddress({
  //         ...state.adviser.companyAddress,
  //         [id]: value,
  //       }),
  //     }),
  //   }));
  // }

  handleChange({ target: { id, value } }) {
    this.setState((state) => ({ ...state, [id]: value }));
  }

  render() {
    const {
      stateOptions,
      loadingButton,
      companyName,
      mobileNo,
      addressLine1,
      suburb,
      stateProvince,
      postcode,
      country,
      afsl,
      carNo,
      numberOfAdvisers,
    } = this.state;
    return (
      <Container>
        <Form onSubmit={(e) => this.handleSubmit(e)}>
          {/*<Row className="mt-40 mb-30 justify-content-center">*/}
          {/*    <StepsBar step={'step2'} location={'register'}></StepsBar>*/}
          {/*</Row>*/}
          <Row className="mt-40 mb-40 justify-content-center">
            <h2 className="title-pages text-center d-block w-100">Share a few more details.</h2>
            <p className="text-center client-text">
              We’ll use these details to finish setting up your account and when generating your
              compliance and advice documents
            </p>
          </Row>
          <div className="row-steps">
            <TextField
              className="mb-30"
              onChange={(e) => this.handleChange(e)}
              control="companyName"
              value={companyName}
              label="Company name"
              validateInput={() => ValidatorService.validateInputTextOrNumber(companyName)}
              required
            />
            <div>
              <label>Phone Number </label>
              <br />
              <IntlTelInput
                containerClassName="intl-tel-input mb-30"
                inputClassName="form-control"
                value={mobileNo}
                onPhoneNumberChange={(e, text) =>
                  this.setState((state) => ({ ...state, mobileNo: text }))
                }
              />
            </div>
            <TextField
              className="mb-30"
              onChange={(e) => this.handleChange(e)}
              control="addressLine1"
              value={addressLine1}
              label="Company street address"
              validateInput={() => ValidatorService.validateInputTextWithComma(addressLine1)}
              required
            />
            <TextField
              className="mb-30"
              onChange={(e) => this.handleChange(e)}
              control="suburb"
              value={suburb}
              label="Suburb / City"
              validateInput={() => ValidatorService.validateInputTextOrNumber(suburb)}
              required
            />
            <Row>
              <Col md={6}>
                <TextField
                  className="mb-30"
                  style={{ position: 'relative', left: -15 }}
                  onChange={(e) => this.handleChange(e)}
                  control="stateProvince"
                  value={stateProvince}
                  label="State / Province / County"
                  validateInput={() => ValidatorService.validateInputTextOrNumber(stateProvince)}
                  required
                />
              </Col>
              <Col md={6}>
                <TextField
                  style={{ position: 'relative', right: -15 }}
                  className="mb-30"
                  onChange={(e) => this.handleChange(e)}
                  control="postcode"
                  value={postcode}
                  label="Postcode / Zip code"
                  validateInput={() => ValidatorService.validateInputNumber(postcode)}
                  required
                />
              </Col>
            </Row>
            {/* <Dropdown
              className="mb-30"
              control="country"
              label="Country"
              onChange={(e) => this.handleChange(e)}
              value={adviser.companyAddress.country}
              options={stateOptions}
              placeholder="Australia"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.label} */}
            {/* /> */}
            <TextField
              className="mb-30"
              control="country"
              label="Country"
              onChange={(e) => this.handleChange(e)}
              value={country}
              // options={stateOptions}
              // placeholder="Australia"
              // getOptionLabel={(option) => option.label}
              // getOptionValue={(option) => option.label}
            />
          </div>
          <Row className="mb-20 mt-40">
            <Col md={12} className="d-flex mb-20" style={{ justifyContent: 'space-evenly' }}>
              <Button className="btn-main-shadowless" onClick={(e) => this.handleSubmit(e)}>
                Next &nbsp;
                <img src={arrowNext} />
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}

export default BasicInformation;
