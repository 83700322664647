import { create } from 'apisauce';

const { REACT_APP_STRIPE_API_URL } = process.env;

const apiStripe = create({
  baseURL: REACT_APP_STRIPE_API_URL,
  timeout: 55000,
});

export const apiSetup = (dispatch) => {
  apiStripe.addMonitor((response) => {
    if (response.status === 401) {
      // dispatch(actions.sessionExpired());
      console.warn('Unhandled session expiration');
    }
  });

  apiStripe.addMonitor((response) => {
    if (response.problem === 'NETWORK_ERROR') {
      // dispatch(actions.noInternetConnection());
      console.warn('Unhandled request without connection');
    }
  });
};

export default apiStripe;
