import { isEmpty } from 'lodash';
import React, { Component, Fragment } from 'react';

import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Row } from 'reactstrap';

import $ from 'jquery';

import { getClient } from '../../service/ClientsService';

import './Sidebar.scss';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adviserClient: null,
    };
  }

  handleClick(item) {
    $('body').toggleClass('menu-active');
    this.props.builder.selectSidebar(item.id);
    this.props.history.push(item.link);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      getClient({ ClientCoreId: this.props.match.params.id }).then((res) => {
        this.setState(() => ({ adviserClient: res.adviserClient }));
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.match.params.id && this.props.match.params.id !== prevProps.match.params.id) {
      this.setState(() => ({ adviserClient: null }));
      getClient({ ClientCoreId: this.props.match.params.id }).then((res) => {
        this.setState(() => ({ adviserClient: res.adviserClient }));
      });
    }
  }

  closeMenuBeforeNavigate(link) {
    $('body').toggleClass('menu-active');
    this.props.history.push(link);
  }

  render() {
    const items = this.props.builder.getSidebar();
    const { backButton } = this.props.builder.layout.sidebar;
    const { adviserClient } = this.state;
    return (
      <Container>
        {backButton && (
          <Fragment>
            <Row className="sidebar-back-link mt-30">
              <Link to="/clients">{backButton.title}</Link>
            </Row>
            <Row className="sidebar-client mt-20">Client:</Row>
            <Row className="sidebar-client">
              {adviserClient && `${adviserClient.clientFirstName} ${adviserClient.clientLastName}`}
            </Row>
          </Fragment>
        )}
        <Row className="d-sm-block d-md-none menu-header-mobile">
          <ul>
            {this.props.config.items.map((item, idx) => (
              <li
                key={idx}
                onClick={() => this.closeMenuBeforeNavigate(item.link)}
                className={item.selected ? 'active' : ''}
              >
                {item.title}
              </li>
            ))}
          </ul>
        </Row>
        <Row>
          <ul>
            {items.map((item) => (
              <li key={item.id}>
                <Button
                  color="white"
                  onClick={() => this.handleClick(item, $(this))}
                  className={item.selected ? 'active' : ''}
                  active={!!item.selected}
                >
                  <div className="circle-back-icon">
                    <img className="sidebar-icon" src={item.icon} alt="" />
                  </div>
                  <div className="label">{item.title}</div>
                </Button>
                {((items) =>
                  !isEmpty(items) && (
                    <ul className="sidebar-list">
                      {items.map((item, idx) => (
                        <li key={idx} className={item.selected ? 'active' : ''}>
                          <Button
                            onClick={() => this.handleClick(item, $(this))}
                            className={item.selected ? 'active' : ''}
                            active={item.selected}
                          >
                            {item.icon ? (
                              <div className="circle-back-icon">
                                <img className="sidebar-icon" src={item.icon} alt="" />
                              </div>
                            ) : null}

                            <div className="label-item">{item.title}</div>
                          </Button>
                        </li>
                      ))}
                    </ul>
                  ))(this.props.builder.getSidebar(item.id))}
              </li>
            ))}
          </ul>
        </Row>
      </Container>
    );
  }
}

export default withRouter(Sidebar);
