/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'reactstrap';

import './ButtonLoading.scss';
import LoadingSpinner from './LoadingSpinner';

interface ButtonLoadingInterface {
  loading?: boolean;
  className: string;
  label?: string;
  color?: string;
  small?: string;
  disabled?: boolean;
  type?: string;
  onClick?: Function | null;
  style?: {};
  children?: React.ReactNode;
}

const ButtonLoading = ({
  onClick,
  loading = false,
  className = '',
  label = '',
  color = '',
  small = '',
  disabled = false,
  type = 'submit',
  style = {},
  children,
  ...props
}: ButtonLoadingInterface) => (
  <Button
    type={type}
    {...{ className, color }}
    disabled={disabled}
    {...props}
    className={`${className} d-flex align-items-center justify-content-center`}
    onClick={onClick}
  >
    {!loading ? label : <LoadingSpinner />}
  </Button>
);

export default ButtonLoading;
