import ExposureChart from '_components/clients/ClientDetails/Results/ExposureChart';
import { IResultsSummaryResponse } from '_components/clients/ClientDetails/Results/Results';

export default function ExposureBreakdown({
  resultsSummary,
}: {
  resultsSummary: IResultsSummaryResponse;
}) {
  return (
    <div className="box-advice" style={{ marginBottom: '20px' }}>
      <h5>Exposure Breakdown</h5>
      <p>
        The Exposure Breakdown shows the breakdown of each instrument and the associated underlying
        holdings based on each of your client’s sustainability profile selections. Giving you a line
        by line detail of how your client is exposure to each area. This section is based on your
        client’s sustainability profile selections and does not include exposure to unselected
        profile options and is not weighted to the asset allocation of the portfolio.
      </p>
      <div className="dasshed-box">
        <ExposureChart resultsSummary={resultsSummary} />
      </div>
    </div>
  );
}
